import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { SearchContainer, InputContainer, SearchButtonsContainer } from './styles'
import ModalCancel from '../../Components/Modal/Cancel'
import { cpfMask } from '../../utils'

export default function Search(props) {
  const [defaultFormValues, setDefaultFormValues] = useState({
    cpf: '',
  })
  const { handleSubmit } = useForm({ defaultFormValues })
  const [modalCancel, setModalCancel] = useState(false)

  return (
    <>
      <ModalCancel
        id='modalcancelalert'
        appear={modalCancel}
        text={'Deseja cancelar a pesquisa e retornar para página principal?'}
        actionButton1={() => setModalCancel(false)}
        actionButton2={() => props.history.push('/pesquisarcidadao')}
      />

      <form onSubmit={handleSubmit(() => props.onSubmitSearch(defaultFormValues))}>
        <SearchContainer>
          <InputContainer>
            <div className='br-input'>
              <label htmlFor='cpf'> CPF para mudar o perfil </label>
              <input
                id='cpf'
                name='cpf'
                placeholder='Digite somente números'
                type='text'
                maxLength='14'
                value={cpfMask(defaultFormValues.cpf)}
                onChange={(event) => {
                  const { value } = event.target
                  setDefaultFormValues({ ...defaultFormValues, cpf: value.replace(/\D/g, '') })
                  props.setErroMsg(undefined)
                }}
              />
            </div>
          </InputContainer>
        </SearchContainer>

        <SearchButtonsContainer>
          <button className='br-button secundary' type='button' onClick={() => setModalCancel(true)}>
            Cancelar
          </button>
          <button
            className='br-button secondary'
            type='button'
            onClick={() => {
              setDefaultFormValues({ ...defaultFormValues, cpf: '' })
              props.setSearchResult(undefined)
              document.getElementById('cpf').value = ''
              props.setErroMsg(undefined)
            }}>
            Limpar Filtro
          </button>
          {!props.buttonNextLoading ? (
            <button className='br-button primary' type='submit' disabled={!defaultFormValues.cpf}>
              Pesquisar
            </button>
          ) : (
            <button className='br-button primary loading' type='button'>
              Pesquisar
            </button>
          )}
        </SearchButtonsContainer>
      </form>
    </>
  )
}
