import React, { useState, useEffect } from 'react';

import ModalWarn from '../../Components/Modal/Warn/index'
import UnitElement from '../../Components/UnitElement'
import { estados } from '../../constants'

export default function EditUnit(props) {
    const [ unit, setUnit ] = useState({
        organCode: '',
        unitCode: '',
        unitName: '',
        state: '',
        street: '',
        number: '',
        city: '',
        neighborhood: '',
        addressComplement: ''
    });
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
    
    useEffect(() => {   
        if (props.location.state !== undefined) {
            setUnit({
                organCode: props.location.state.result_search.CODIGO_ORGAO,
                unitCode: props.location.state.result_search.CODIGO_UNIDADE,
                unitName: props.location.state.result_search.NOME_UNIDADE,
                state: estados.filter(option => option.value === props.location.state.result_search.ESTADO)[0],
                street: props.location.state.result_search.LOGRADOURO,
                number: props.location.state.result_search.NUMERO,
                city: props.location.state.result_search.CIDADE,
                neighborhood: props.location.state.result_search.BAIRRO,
                addressComplement: props.location.state.result_search.COMPLEMENTO,
                zipcode: props.location.state.result_search.CEP

            });
        }
    }, [props.location.state]);

    const [ loadingEdit, setLoadingEdit ] = useState(false);
    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

    const onSubmitUnitData = async (data) => {
        
        setLoadingEdit(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                codigo_orgao: data.organCode,
                codigo_unidade: data.unitCode,
                nome_unidade: data.unitName,                
                cep: data.zipcode,
                estado: data.state,
                logradouro: data.street,
                numero: data.number,
                complemento: data.addressComplement,
                bairro: data.neighborhood,
                cidade: data.city
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/editaUnidadeOrgao`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    const unit_edited = {
                        message: data_request.message
                    }
                    setLoadingEdit(false);
                    
                    //console.log(unit_aEditd);
                    props.history.push('gerenciarUnidades', unit_edited);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/editaUnidadeOrgao BackendBalcao');
                    }

                    setLoadingEdit(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingEdit(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingEdit(false);
                console.log('Error request to /gestao/editaUnidadeOrgao');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    const pesquisaCEP = async (cep) => {
        cep = cep.replace(/\D/g, '')

        setLoadingEdit(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                cep: cep
            }),
        };
         
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/pesquisaCEP`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    setUnit( {...unit, zipcode: data.zipcode, state: estados.filter(option => option.value === data.state)[0], street: data.street, addressComplement: data.addressComplement, neighborhood: data.neighborhood, city: data.city})
                    setLoadingEdit(false);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/cadastraUnidadeOrgao BackendBalcao');
                    }

                    setLoadingEdit(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingEdit(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingEdit(false);
                console.log('Error request to /gestao/pesquisaCEP');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <UnitElement
                pageTitle='Editar unidade'
                onSubmitData={onSubmitUnitData}
                pesquisaCEP={pesquisaCEP}
                unit={unit}
                setUnit={setUnit}
                stateOptions={estados}
                loadingAdd={loadingEdit}
                setLoading={setLoadingEdit}
                modalError ={modalError}
                setModalError={setModalError}
                messageError={messageError}
                buttonCancelRoute='/gerenciarUnidades'
                history={props.history}
            />
        </>
    );
}