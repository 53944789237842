import React, { useState, useEffect } from 'react';

import { removeInputMask } from '../../utils';

import Management from '../../Components/Management'

import ModalWarn from '../../Components/Modal/Warn/index'

export default function AgencyManagement(props) {

    const [ loading, setLoading] = useState(false);

    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [ pageOptions, setPageOptions ] = useState([]);
    const [ page, setPage ] = useState({
        label: "1", value: "1"
    });

    const [ organOptions, setOrganOptions ] = useState([]);

    const [ modalError, setModalError ] = useState(false);

    const [ agencyManagerList, setAgencyManagerList ] = useState([ ]);

    const [ totalFilter, setTotalFilter ] = useState("0");

    const [ erroMsg, setErroMsg ] = useState('');

    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        async function loadOrganOptions() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_usuario: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : ""
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaOrgaoGestorOrgao`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        var organList = [];
                        var obj = {};

                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO
                            }

                            organList.push(obj);
                        });

                        setOrganOptions(organList);
                    });
                } else {
                    console.log('Error request to /gestao/consultaOrgaoGestorOrgao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        loadOrganOptions();
    }, [props.location.state]);

    const onSubmitSearch = async (data) => {
        setAgencyManagerList([]);
        setErroMsg('');
        setLoading(true);
        data.cpf = removeInputMask(data.cpf);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_orgao: data.organ,
                cpf_usuario: removeInputMask(data.cpf),
                nome_usuario: data.name,
                pagina_atual: "1",
                qtd_registros: rangePages.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaGestoresOrgao`, requestOptions).then(function(response) {
            if (response.ok) {
                response.json().then(function(resp) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    const maxPageOption = Math.ceil(Number(resp.total) / Number(rangePages.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }
                    setPageOptions(list);
                    setPage({label: "1", value: "1"});
                    setAgencyManagerList(resp.gestoresOrgao);
                    setTotalFilter(resp.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoading(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                setModalError(true);
                console.log('Request error in gestao/listaGestoresOrgao BackendBalcao');
            }
            setLoading(false);
        }).catch(function(err) {
            setLoading(false);
            console.error('Failed retrieving information', err);
        });
    };

    const onChangePaginationConfig = async (data, ranPag, pag, isRanPag) => {
        setErroMsg('');
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_orgao: data.organ,
                cpf_usuario: data.cpf,
                nome_usuario: data.name,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaGestoresOrgao`, requestOptions
        ).then(function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }

                    setPageOptions(list);
                    if(isRanPag){
                        setPage({label: "1", value: "1"});
                    }
                    setAgencyManagerList(data.gestoresOrgao);
                    setTotalFilter(data.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoading(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                setModalError(true);
                console.log('Request error in gestao/listaGestoresOrgao BackendBalcao');
            }
            setLoading(false);
        }).catch(function(err) {
            setLoading(false);
            console.error('Failed retrieving information', err);
        });
    };

    return(
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <Management
                pageTitle='Gestão de gestores de órgão'
                buttonRegistrationTitle='+ Cadastrar gestor de órgão'
                buttonRegistrationRoute='/consultaGestorOrgao'
                fieldNamePlaceholder='Digite o nome do gestor de órgão'
                tableResultListTitle='Lista de gestores de órgão cadastrados'
                buttonDetailRoute='/detalharGestorOrgao'
                buttonEditRoute='/editarGestorOrgao'
                buttonCancelRoute='/cancelarGestorOrgao'
                buttonNextLoading={loading}
                onSubmitSearch={onSubmitSearch}
                onChangePaginationConfig={onChangePaginationConfig}
                organOptions={organOptions}
                searchResult={agencyManagerList}
                setSearchResult={setAgencyManagerList}
                history={props.history}
                totalFilter={totalFilter}
                pageOptions={pageOptions}
                setPage={setPage}
                page={page}
                erroMsg={erroMsg}
                setErroMsg={setErroMsg}
                rangePages={rangePages}
                setRangePages={setRangePages}
                modalError={modalError}
                setModalError={setModalError}
            />
        </>
    );
}