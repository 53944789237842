import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import ModalCancel from '../../Components/Modal/Cancel'
import ModalWarn from '../../Components/Modal/Warn'
import { ContentContainer, GlobalContainer, Title, TitleContainer, ContentContainer3, ContentContainer4, ContentContainerTable, SelectContainer, InputContainer } from './styles'
import { ContentContainer1, ContentContainer2 } from '../Initial/styles'
import { ContentContainerButtons } from '../AttendantSchedule/styles'

export default function AdminEditAgencyScheduleManagement(props) {
  const { handleSubmit } = useForm()


  useEffect(() => {
    props.setSchedule({
        diasSemana: props.location.state.result_search.DIAS_SEMANA,
        horaInicio: props.location.state.result_search.HORA_INICIO,
        horaFim: props.location.state.result_search.HORA_FIM,
        organCode: props.location.state.result_search.CODIGO_ORGAO
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state])

  const [isModalCancelVisible, setModalCancelVisible] = useState(false)

  const dias = [
    { label: 'Seg à Sex', value: 'Seg à Sex' },
    { label: 'Seg a Sáb', value: 'Seg a Sáb' },
    { label: 'Seg a Dom', value: 'Seg a Dom' },
    { label: 'Sábado', value: 'Sábado' },
    { label: 'Domingo', value: 'Domingo' },
    { label: 'Final de Semana', value: 'Final de Semana' },
  ]

  return (
    <>
      <ModalCancel
        id='modalcancelalert'
        appear={isModalCancelVisible}
        text={'Tem certeza que deseja cancelar a edição?'}
        actionButton1={() => setModalCancelVisible(false)}
        actionButton2={() => props.history.push(props.buttonCancelRoute, props.location.state)}
      />

      <ModalWarn id='modalconfirmalert' appear={props.modal} text={props.responseMessage} textButton2='Ok' actionButton2={() => props.setModal(false)} />

      <GlobalContainer>
        <ContentContainer4>
          <TitleContainer>
            <Title> Editar horário de funcionamento do {props.location.state.result_search.NOME_ORGAO} </Title>
          </TitleContainer>
        </ContentContainer4>

        <form onSubmit={handleSubmit(props.onSubmitEdit)}>
            <ContentContainer3>
              <ContentContainerTable>
                <div className='br-table' data-selection='data-selection'>
                  <table>
                    <thead>
                      <tr>
                        <th scope='col0' style={{ color: '#1351B4' }}></th>

                        <th scope='col3' style={{ color: '#1351B4' }}>
                          Dias da semana
                        </th>
                        <th scope='col3' style={{ color: '#1351B4' }}>
                          Horário Inicial
                        </th>
                        <th scope='col4' style={{ color: '#1351B4' }}>
                          Horário Final
                        </th>

                        <th scope='col99' style={{ color: '#1351B4' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> </td>

                        <td>
                        <SelectContainer>
                          <select
                            defaultValue={props.location.state.result_search.DIAS_SEMANA}
                            onChange={(e) => props.setSchedule({ ...props.schedule, diasSemana: e.target.value })}
                            style={{'border-radius':'6px'}}
                            >
                            {dias.map((d) => (
                              <option key={d.value} value={d.value}>
                                {d.label}
                              </option>
                            ))}
                          </select>
                        </SelectContainer>
                        </td>
                        <td>
                        <InputContainer>
                          <input
                            style={{borderRadius:'6px',textAlign: 'center', fontFamily: 'inherit', fontSize: '100%', fontWeight: 'var(--font-weight-medium)', color: 'var(--color-secondary-07)', border: '1px solid #888', height: 'var(--input-size)'}}
                            type='time'
                            defaultValue={props.location.state.result_search.HORA_INICIO}
                            onChange={(e) => props.setSchedule({ ...props.schedule, horaInicio: e.target.value })}
                          />
                        </InputContainer>
                        </td>
                        <td>
                        <InputContainer>
                          <input
                            style={{borderRadius:'6px',textAlign: 'center', fontFamily: 'inherit', fontSize: '100%', fontWeight: 'var(--font-weight-medium)', color: 'var(--color-secondary-07)', border: '1px solid #888', height: 'var(--input-size)'}}
                            type='time'
                            defaultValue={props.location.state.result_search.HORA_FIM}
                            onChange={(e) => props.setSchedule({ ...props.schedule, horaFim: e.target.value })}
                          />
                        </InputContainer>
                        </td>

                        <td> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ContentContainerTable>
            </ContentContainer3>
          <ContentContainer>
            <ContentContainer1></ContentContainer1>

            <ContentContainer2>
              <ContentContainerButtons>
                <button className='br-button' type='button' onClick={() => setModalCancelVisible(true)}>
                  Voltar
                </button>

                {!props.loadingEdit ? (
                  <button className='br-button primary' type='submit'>
                    Confirmar
                  </button>
                ) : (
                  <button className='br-button primary loading' type='button'>
                    Confirmar
                  </button>
                )}
              </ContentContainerButtons>
            </ContentContainer2>
          </ContentContainer>
        </form>
      </GlobalContainer>
    </>
  )
}
