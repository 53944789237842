import React, { useState } from 'react';
import Select, { components } from 'react-select';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import DropLeftIcon from '../../assets/drop_left_icon.png';
import DropRightIcon from '../../assets/drop_right_icon.png';

import {
    GlobalContainer,
    ContentContainer,
    ContentContainerBlock8020,
    ContentContainerBlock6040,
    ContentContainer1,
    ContentContainer2,
    ContentContainer11,
    ContentContainer12,
    ContentContainer13, Text,
    ContentContainer4,
    Title,
    TitleContainer,
    ContentContainerButtons,
    PaginationContainer,
    SelectPagesContainer
} from './styles';
import ModalWarn from '../Modal/Warn/index';
import { cpfMask, formatDate, formatarCEP } from '../../utils';

export default function ShowUnitElement(props) {
    const [rangePagesOptions] = useState([
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
    ]);
    const SelecPageStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: 0,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 15,
        }),
    }
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen 
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon"/>
                }
            </components.DropdownIndicator>
        );
    }; 
    const IndicatorSeparator = ({ innerProps }) => {
        return <span style={{}} {...innerProps} />;
    };

    // ================================================================================

    return (
        <>
                <>
                    <ModalWarn
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text={props.messageError}
                        textButton2='Ok'
                        actionButton2={() => props.setModalError(false)}
                    />

                    <GlobalContainer>
                        <ContentContainer>
                            <ContentContainer1>
                                <TitleContainer>
                                    <Title> {props.pageTitle} </Title>
                                </TitleContainer>
                                <ContentContainer11>
                                    <label htmlFor="unitName">Unidade</label>
                                    <Text> {props.unit.unitName} </Text>
                                </ContentContainer11>
                                
                                <label>Endereço </label>
                                <ContentContainer12>
                                    <ContentContainer13>
                                        <ContentContainer11>
                                            <label htmlFor="unitName">CEP</label>
                                            <Text> {props.unit.zipcode ? formatarCEP(props.unit.zipcode) : "-"} </Text>                                            
                                        </ContentContainer11>
                                        <ContentContainerBlock8020>
                                            <ContentContainer11>
                                                <label htmlFor="unitName">Logradouro (Rua/Avenida)</label>
                                                <Text> {props.unit.street ? props.unit.street : "-"} </Text>   
                                            </ContentContainer11> 
                                            <ContentContainer11>
                                                <label htmlFor="unitName">Número</label>
                                                <Text> {props.unit.number ? props.unit.number : "-"} </Text>
                                            </ContentContainer11>
                                        </ContentContainerBlock8020>   
                                        <ContentContainerBlock6040>
                                            <ContentContainer11>
                                                <label htmlFor="unitName">Complemento</label>
                                                <Text> {props.unit.addressComplement ? props.unit.addressComplement : "-"} </Text>   
                                            </ContentContainer11> 
                                            <ContentContainer11>
                                                <label htmlFor="unitName">Bairro</label>
                                                <Text> {props.unit.neighborhood ? props.unit.neighborhood : "-"} </Text>
                                            </ContentContainer11>
                                        </ContentContainerBlock6040>  
                                        <ContentContainerBlock6040>
                                            <ContentContainer11>
                                                <label htmlFor="unitName">Cidade</label>
                                                <Text> {props.unit.city ? props.unit.city : "-"} </Text>   
                                            </ContentContainer11> 
                                            <ContentContainer11>
                                                <label htmlFor="unitName">Estado</label>
                                                <Text> {props.unit.state.label ? props.unit.state.label : "-"} </Text>
                                            </ContentContainer11>
                                        </ContentContainerBlock6040>                                           
                   
                                    </ContentContainer13>
                                </ContentContainer12>
                                <ContentContainer4>
                                        {!!(!(props.searchResult && props.searchResult.length)) && <>
                                            <label style={{'color': 'red'}}>Não há gestores ou atendentes vinculados a esta unidade.</label>
                                        </>}
                                        {!!(props.searchResult && props.searchResult.length) && <>
                                            <label>{props.tableResultListTitle}</label>
                                            <div className="br-table" data-search="data-search" data-selection="data-selection">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col0" style={{ 'color': '#1351B4' }}></th>
                                                            <th scope="col1" style={{ 'color': '#1351B4' }}> Nome completo </th>
                                                            <th scope="col2" style={{ 'color': '#1351B4' }}> CPF </th>
                                                            <th scope="col3" style={{ 'color': '#1351B4' }}> Perfil </th>
                                                            <th scope="col4" style={{ 'color': '#1351B4' }}> Data de Cadastro </th>
                                                            <th scope="col5" style={{ 'color': '#1351B4' }}> Data de Expiração </th>
                                                            <th scope="col99" style={{ 'color': '#1351B4' }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.searchResult.map(result => (
                                                            <tr key={result.ID_USUARIO_ORGAO}>
                                                                <td> </td>
                                                                <td> {result.NOME_USUARIO} </td>
                                                                <td> { cpfMask(result.CPF_USUARIO) }</td>
                                                                <td> {result.PERFIL} </td>
                                                                <td> { formatDate(result.DATA_INCLUSAO) } </td>
                                                                <td> {result.DATA_CANCELAMENTO === null ? "-" : formatDate(result.DATA_CANCELAMENTO) } </td>
                                                                <td> </td>
                                                            </tr>
                                                        )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <PaginationContainer>
                                                <nav className="br-pagination" aria-label="Paginação de resultados" data-total="50" data-current="1" data-per-page="20">
                                                    <div className="pagination-per-page">
                                                        <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Exibir </label>
                                                        <SelectPagesContainer>
                                                            <Select
                                                                options={rangePagesOptions}
                                                                defaultValue={{ value: "10", label: "10" }}
                                                                value={props.rangePages}
                                                                onChange={value => {
                                                                    props.setRangePages(value)
                                                                    props.onChangePaginationConfig(value, props.page, true)
                                                                }}
                                                                styles={SelecPageStyles}
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    borderRadius: 6,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#CCCCCC',
                                                                        primary: '#1351B4'
                                                                    },
                                                                })}
                                                                components={
                                                                    {
                                                                        DropdownIndicator,
                                                                        IndicatorSeparator
                                                                    }
                                                                }
                                                            />
                                                        </SelectPagesContainer>
                                                    </div>

                                                    <span className="br-divider d-none d-sm-block mx-3"></span>

                                                    <div className="pagination-information d-none d-sm-flex">
                                                        <span className="current"> {1 + ((Number(props.page.value) - 1) * Number(props.rangePages.value))} </span>&ndash;<span className="per-page"> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className="total"> {props.totalFilter} </span>&nbsp;itens
                                                    </div>
                                                    <div className="pagination-go-to-page d-none d-sm-flex ml-auto">
                                                        <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Página </label>
                                                        <SelectPagesContainer>
                                                            <Select
                                                                options={props.pageOptions}
                                                                defaultValue={{ value: "1", label: "1" }}
                                                                value={props.page}
                                                                onChange={value => {
                                                                    props.setPage(value)
                                                                    props.onChangePaginationConfig(props.rangePages, value, false)
                                                                }}
                                                                styles={SelecPageStyles}
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    borderRadius: 6,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#CCCCCC',
                                                                        primary: '#1351B4'
                                                                    },
                                                                })}
                                                                components={
                                                                    {
                                                                        DropdownIndicator,
                                                                        IndicatorSeparator
                                                                    }
                                                                }
                                                            />
                                                        </SelectPagesContainer>
                                                    </div>

                                                    <span className="br-divider d-none d-sm-block mx-3"></span>

                                                    <div className="pagination-arrows ml-auto ml-sm-0">
                                                        <button className="br-button circle" type="button" aria-label="Voltar página"
                                                            style={{
                                                                'opacity': Number(props.page.value) > 1 ? '' : '0.4',
                                                                'pointerEvents': Number(props.page.value) > 1 ? '' : 'none'
                                                            }}
                                                            onClick={() => {
                                                                const new_page = {
                                                                    value: String(Number(props.page.value) - 1),
                                                                    label: String(Number(props.page.value) - 1)
                                                                }
                                                                props.setPage(new_page)
                                                                props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                            }}
                                                        >
                                                            <i className="fas fa-angle-left" aria-hidden="true">
                                                                <img
                                                                    src={DropLeftIcon}
                                                                    alt="leftIcon"
                                                                    style={{ "marginBottom": "6px" }}
                                                                />
                                                            </i>
                                                        </button>
                                                        <button className="br-button circle" type="button" aria-label="Avançar página"
                                                            style={{
                                                                'opacity': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : '0.4',
                                                                'pointerEvents': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : 'none'
                                                            }}
                                                            onClick={() => {
                                                                const new_page = {
                                                                    value: String(Number(props.page.value) + 1),
                                                                    label: String(Number(props.page.value) + 1)
                                                                }
                                                                props.setPage(new_page)
                                                                props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                            }}
                                                        >
                                                            <i className="fas fa-angle-right" aria-hidden="true">
                                                                <img
                                                                    src={DropRightIcon}
                                                                    alt="rightIcon"
                                                                    style={{ "marginBottom": "6px" }}
                                                                />
                                                            </i>
                                                        </button>
                                                    </div>
                                                </nav>
                                            </PaginationContainer>
                                        </>}
                                </ContentContainer4>
                            </ContentContainer1>
                        </ContentContainer>

                        <ContentContainer>
                            <ContentContainer1>

                            </ContentContainer1>

                            <ContentContainer2>
                                <ContentContainerButtons>
                                    <button className="br-button" type="button"
                                        onClick={() => props.history.push(props.buttonCancelRoute)}>
                                        Voltar
                                    </button>
                                </ContentContainerButtons>
                            </ContentContainer2>
                        </ContentContainer>
                    </GlobalContainer>
                </>            
        </>
    );
}