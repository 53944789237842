import styled from 'styled-components';

export const Title = styled.h1 `
    width: 790px;

    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
        margin-right: 30px;
    }
`;

export const SubTitle = styled.label `
    width: 790px;

    font-size: 20px;
    font-weight: bold;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
        margin-right: 30px;
    }
`;

export const Text = styled.label `
    font-size: 14px;
    font-weight: normal;
    color: #333333;
`;

export const Text2 = styled.label `
    font-size: 14px;
    font-weight: normal;
    color: #155bcb;
`;

export const TextDesc = styled.label `
    width: 790px;

    font-size: 14px;
    font-weight: normal;
    color: #333333;

    @media (max-width: 1000px) {
        width: 380px;
        margin-right: 30px;
    }
`;

export const GlobalContainer = styled.div `

    margin-top: 80px;

    @media (max-width: 1000px) {
        div {
            display: grid;
        }
    }

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const TitleContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
    justify-content: center;
`;

export const SubTitleContainer = styled.div `
    padding: 0px 0px 20px;
    display: flex;
    justify-content: center;
`;

export const DescContainer = styled.div `
    padding: 0px 0px 20px;
    display: flex;
    justify-content: center;
`;

export const ContentContainer = styled.div `
    display: flex;
    justify-content: center;
    max-width: 790;
    margin: 0px auto;
`;

export const ContentContainer1 = styled.div `
    width: 380px;
    margin-right: 30px;
    padding: 0px 0px 15px;
`;

export const ContentContainer11 = styled.div `
    display: grid;
    margin-bottom: 20px;
`;

export const ContentContainer2 = styled.div `
    width: 380px;
`;

export const ContentContainer21 = styled.div `
    display: grid;
    margin-bottom: 20px;
`;

export const FileContent = styled.div `
    width: 790px;
    padding: 0px 0px 30px;

    @media (max-width: 1000px) {
        width: 380px;
        margin-right: 30px;
    }
`;

export const ContentContainerButtons = styled.div `
    padding: 0px 0px 20px;
    text-align: right;

    button:nth-child(2){ /*-pegando o segundo button desta div-*/
        margin-left: 10px;
    }
`;

export const Line = styled.div `
    display: flex;
`;

export const Col1 = styled.div `
    width: 215px;
`;

export const Col2 = styled.div `
    width: 420px;
`;

export const Col3 = styled.div `
    width: 95px;
`;

export const Col4 = styled.div `
    width: 60px;

    .trash_icon {
        cursor: pointer;
    }
`;

export const FileInputDiv = styled.label `
    display: grid;

    input[type='file'] {
        display: none;
    };

    .title_input_file {
        
    };

    .content_input_file {
        pointer-events: ${ props => !props.selectedType ? 'none' : ''};
        opacity: ${ props => !props.selectedType ? 0.4 : 1};

        display: flex;

        max-width: 550px;
        width: 100%;
        height: 38px;
        margin-top: 6px;

        border: 1px dashed #155BCB;
        border-color: #1351b4;
        border-radius: 6px;

        //background: #FFFFFF;

        padding-left: 20px;
        padding-right: 16px;
        padding-top: 8px;

        cursor: pointer;

        font-family: Rawline;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #155BCB;

        img {
            margin-right: 17px;
            margin-bottom: 8px;
        }
    }

    label.content_input_file:hover {
        background: #C5D4EB;
    }
`;