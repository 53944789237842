import React from 'react'

//import '../../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

function MessageError(props) {
  return (
    <div id='messageError' style={{ color: '#842029', backgroundColor: '#f8d7da', borderColor: '#f5c2c7' }} className='br-message' role='alert'>
      <div className='icon' aria-label='informações incorretas'>
        <i className='fas fa-check-circle fa-lg' aria-hidden='true'></i>
      </div>
      <div className='content'>
        <span>
          <ul>
            {Array.isArray(props.text)? props.text.map((msg) => (
              <li key={msg.field}>{msg.message}</li>
            ))
            : <li>{props.text}</li>
          }
          </ul>
        </span>
      </div>
    </div>
  )
}

export default MessageError
