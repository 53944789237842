import React, { useEffect, useState } from 'react';

import CancelOperator from '../../Components/CancelOperator';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function CancelRegionalManager(props) {
    const [ regionalManager, setRegionalManager ] = useState({
        cpf: '',
        name: '',
        organ: '',
        dt_inc: '',
        dt_canc_exp: ''
    });
    const [ attendantList, setAttendantList ] = useState([]);
    const [ loadRegionalManager, setLoadRegionalManager ] = useState(false);
    const [ pageOptions, setPageOptions ] = useState([]);
    const [ page, setPage ] = useState({
        label: "1", value: "1"
    });
    const [ totalFilter, setTotalFilter ] = useState("0");
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [ erroMsg, setErroMsg ] = useState([]);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {

        async function loadAssociatedAttendants() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_usuario_cadastrador: props.location.state.result_search.CPF_USUARIO,
                    pagina_atual: 1,
                    qtd_registros: 10
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaAtendentesAssociadosAoGestor`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        const maxPageOption = Math.ceil(Number(data.total) / Number(rangePages.value))
                        let list = []
                        let obj = {}
                        for(var i = 1; i <= maxPageOption; i++) {
                            obj = {
                                value: String(i),
                                label: String(i)
                            }

                            list.push(obj);
                        }

                        setPageOptions(list);
                        setPage({label: "1", value: "1"});
                        setAttendantList(data.atendentes);
                        setTotalFilter(data.total);
                    });
                } else {
                    console.log('Error request to /gestao/listaAtendentesAssociadosAoGestor');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        if (!loadRegionalManager && props.location.state !== undefined) {
            setRegionalManager({
                cpf: props.location.state.result_search.CPF_USUARIO,
                name: props.location.state.result_search.NOME_USUARIO,
                organ: {
                    value: props.location.state.result_search.CODIGO_ORGAO,
                    label: props.location.state.result_search.NOME_ORGAO
                },
                unidade: {
                    value: props.location.state.result_search.CODIGO_UNIDADE,
                    label: props.location.state.result_search.NOME_UNIDADE
                },
                dt_inc: props.location.state.result_search.DATA_INCLUSAO,
                dt_canc_exp: props.location.state.result_search.DATA_CANCELAMENTO
            });

            setLoadRegionalManager(true);
            loadAssociatedAttendants();
        }
    }, [props, loadRegionalManager, rangePages]);

    const [ isModalConfirmVisible, setModalConfirmVisible ] = useState(false);
    const [ loadingCancel, setLoadingCancel ] = useState(false);
    const [ canceled, setCanceled ] = useState(false);
    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

    const onChangePaginationConfig = async (ranPag, pag, isRanPag) => {
        setErroMsg(undefined);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_usuario_cadastrador: props.location.state.result_search.CPF_USUARIO,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaAtendentesAssociadosAoGestor`, requestOptions).then(function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }
                        list.push(obj);
                    }
                    setPageOptions(list);
                    if(isRanPag){
                        setPage({label: "1", value: "1"});
                    }
                    setAttendantList(data.atendentes);
                    setTotalFilter(data.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message)
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingCancel(false);
                  setModalInvalidSessionError(true)
                })
            } else {
                console.log('Request error in gestao/listaAtendentesAssociadosAoGestor BackendBalcao');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    };

    const onSubmitCancel = async () => {

        setLoadingCancel(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",
                id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                cpf_gestor_regional: regionalManager.cpf,
                codigo_orgao: regionalManager.organ.value
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/cancelaGestorRegional`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function() {                    
                    setCanceled(true);
                    setLoadingCancel(false);
                    setModalConfirmVisible(false)
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/cancelaGestorRegional BackendBalcao');
                    }

                    setLoadingCancel(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingCancel(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingCancel(false);
                console.log('Error request to /gestao/cancelaGestorRegional');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <CancelOperator
                pageTitle='Cancelar gestor regional'
                confirmationMsg='Tem certeza que deseja cancelar este gestor regional?'
                successMsg='Gestor regional cancelado com sucesso.'
                operator={regionalManager}
                setOperator={setRegionalManager}
                loadOperator={loadRegionalManager}
                isModalConfirmVisible={isModalConfirmVisible}
                setModalConfirmVisible={setModalConfirmVisible}
                loadingCancel={loadingCancel}
                setLoadingCancel={setLoadingCancel}
                canceled={canceled}
                setCanceled={setCanceled}
                modalError={modalError}
                setModalError={setModalError}
                messageError={messageError}
                setMessageError={setMessageError}
                onSubmitCancel={onSubmitCancel}
                history={props.history}
                location={props.location}
                buttonBackRoute='/gerenciarGestoresRegionais'
                tableResultListTitle='Lista de atendentes cadastrados pelo gestor regional'
                messageAssociatedWarning='Existem atendentes vinculados a este gestor regional. Caso deseje realizar o cancelamento, é necessário substituir este gestor por outro.'
                buttonReplaceRoute='/consultaGestorRegionalSubstituto'
                onChangePaginationConfig={onChangePaginationConfig}
                totalFilter={totalFilter}
                searchResult={attendantList}
                pageOptions={pageOptions}
                setPage={setPage}
                page={page}
                erroMsg={erroMsg}
                rangePages={rangePages}
                setRangePages={setRangePages}
            />
        </>
    );
}