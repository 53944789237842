/* 

Importante instalar a extencao Styled Components para que o arquivo
consiga ser entendido!

componentizacao de estilo GLOBAL

*/

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle `
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html, body, #root {
        min-height: 100%;
    }

    body {
        //background: ... ;
        -webkit-font-smoothing: antialiased !important;
    }
`;