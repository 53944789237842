import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { GlobalContainer,
         ContentContainer,
         ContentContainer1,
         ContentContainer11,
         ContentContainer2,
         ContentContainer21,
         Title,
         ContentContainer22,
         NumberText,
         ContentContainer23,
         Text,
         ContentContainer24,
         ContentContainerButtons, 
         ContentContainerDown } from './styles';
import MessageSuccess from '../../Components/Messages/Success/index';
import ModalWarn from '../../Components/Modal/Warn/index';

//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

import passwImg from '../../assets/password_img.png';

import { docDefinition } from '../../styles/receiptFormat';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { cpfMask } from '../../utils';

export default function ProvisionalPassword(props) {
    const [loadState, setLoadState] = useState(false);

    const [ loadingSMS, setLoadingSMS ] = useState(false);
    const [ modalSMS, setModalSMS ] = useState(false);
    const [ SMSSend, setSMSSend ] = useState(false);

    const [ loadingEmail, setLoadingEmail ] = useState(false);
    const [ modalEmail, setModalEmail ] = useState(false);
    const [ emailSend, setEmailSend ] = useState(false);

    const [state, setState] = useState({
        id_attendance: '',
        person: '',
        name: '',
        cpf: '',
        email: '',
        cell: '',
        password: '',
        ident_dig: '',
    });

    useEffect(() => {
        props.handleScrollAtivated();

        if (!loadState && props.location.state !== undefined) {
            setState({
                id_attendance: props.location.state.id_attendance,
                person: props.location.state.citizen_person,
                name: props.location.state.citizen_name,
                cpf: props.location.state.citizen_cpf,
                email: props.location.state.citizen_email,
                cell: props.location.state.citizen_cell,
                password: props.location.state.password,
                ident_dig: props.location.state.ident_dig,
            });

            setLoadState(true);
        }
    }, [props.location.state, props, loadState]);

    const { person, name, cpf, email, cell, password } = state;

    const sendSMS = async () => {

        setLoadingSMS(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                id_atendimento:  state.id_attendance,
                celular_cidadao: `55${state.cell}`,
                senha_provisoria: state.password,
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/atendimento/envia/sms`,
                    requestOptions
        ).then(function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    if (data.message){
                        setSMSSend(true);
                        setLoadingSMS(false);
                        setModalSMS(true);

                    } else {
                        setSMSSend(false);
                        setLoadingSMS(false);
                        setModalSMS(true);
                        console.log('Request error in atendimento/envia/sms BackendBalcao');
                    }
                });
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                setSMSSend(false);
                setLoadingSMS(false);
                setModalSMS(true);
                console.log('Request error in atendimento/envia/sms BackendBalcao');
            }
        }).catch(function(err) {
            setLoadingSMS(false);
            setModalSMS(true);
            console.error('Failed retrieving information', err);
        });

    }

    const sendEmail = async () => {
        
        setLoadingEmail(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                id_atendimento:  state.id_attendance,
                email_cidadao: state.email,
                senha_provisoria: state.password,
                cpf_cidadao : state.cpf,
                nome_cidadao : state.name,
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/atendimento/envia/email`,
                    requestOptions
        ).then(function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    if (data.message){
                        setEmailSend(true);
                        setLoadingEmail(false);
                        setModalEmail(true);

                    } else {
                        setEmailSend(false);
                        setLoadingEmail(false);
                        setModalEmail(true);
                        console.log('Request error in atendimento/envia/email BackendBalcao');
                    }
                });
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                setEmailSend(false);
                setLoadingEmail(false);
                setModalEmail(true);
                console.log('Request error in atendimento/envia/email BackendBalcao');
            }
        }).catch(function(err) {
            setLoadingEmail(false);
            setModalEmail(true);
            console.error('Failed retrieving information', err);
        });

    }

    const generateReceiptPDF = () => {
        //console.log(state)

        var date = new Date();

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        var doc = docDefinition(name, cpf, password, date);

        pdfMake.createPdf(doc).download(`iden_gov_${("0" + date.getDate()).slice(-2)}${("0" + (date.getMonth() + 1)).slice(-2)}${date.getFullYear()}${("0" + date.getHours()).slice(-2)}${("0" + date.getMinutes()).slice(-2)}${("0" + date.getSeconds()).slice(-2)}`);
    }

    return (
        <>
            {!loadState ?
                <div style={{'marginTop': '75px'}}><h1> Error on the page! </h1></div>
            :
                <>
                    <ModalWarn
                        id="modalconfirmalert"
                        appear={modalSMS}
                        text = {
                            SMSSend ?
                                `O SMS foi enviado para (${state.cell.slice(0,2)})${
                                    state.cell.slice(2).length === 8 ? 
                                        state.cell.slice(2,6)+'-'+state.cell.slice(6)
                                    : 
                                        state.cell.slice(2,7)+'-'+state.cell.slice(7)
                                } com sucesso.`
                            :
                                'Houve algum problema no servidor. Aguarde um momento e tente novamente.'
                        }
                        textButton2='Ok'
                        actionButton2={() => setModalSMS(false)}
                    />

                    <ModalWarn
                        id="modalconfirmalert"
                        appear={modalEmail}
                        text = {
                            emailSend ?
                                `O email foi enviado para ${state.email} com sucesso.`
                            :
                                'Houve algum problema no servidor. Aguarde um momento e tente novamente.'
                        }
                        textButton2='Ok'
                        actionButton2={() => setModalEmail(false)}
                    />
                    
                    <GlobalContainer>
                        <MessageSuccess text={`Conta gov.br ${state.ident_dig ? 'atualizada' : 'criada'} com sucesso.`}/>

                        <ContentContainer>
                            <ContentContainer1>
                                <ContentContainer11>
                                    <img
                                        className="content_container_1_img"
                                        src={passwImg}
                                        alt="senhaanimacaoimg"
                                    />
                                </ContentContainer11>
                            </ContentContainer1>

                            <ContentContainer2>
                                <ContentContainer21>
                                    <Title> 
                                        {person === 'cidadao' ? 'Senha Provisória da Conta gov.br' : 'Senha Provisória da Conta gov.br'} 
                                    </Title>
                                </ContentContainer21>

                                <ContentContainer22>
                                    <NumberText> {password} </NumberText>
                                </ContentContainer22>

                                <ContentContainer23>
                                    <Text>
                                        Senha {person === 'cidadao' ? 'provisória' : 'provisória'} gerada para {name}, CPF { cpfMask(cpf) }.
                                    </Text>
                                </ContentContainer23>

                                <ContentContainer24>
                                    <Text>
                                        O {person === 'cidadao' ? 'cidadão deve' : 'procurador ou titular do CPF deverá'} acessar o site <strong>acesso.gov.br</strong> informando CPF {person === 'cidadao' ? 'e a senha provisória. ' : 'do cidadão e a senha provisória. '} 
                                        A senha é pessoal e intransferível.
                                    </Text>
                                </ContentContainer24>
                            </ContentContainer2>
                        </ContentContainer>

                        <ContentContainerDown>
                            <ContentContainerButtons>
                                <Link to="/pesquisarcidadao" className="br-button">
                                    Retornar à Página Inicial
                                </Link>
                              
                                {cell !== '' ?
                                        !loadingSMS ?
                                            <button className="br-button secondary" onClick={sendSMS}>
                                                Enviar Senha por SMS
                                            </button>
                                        :
                                            <button className="br-button loading" type="button">
                                                Enviar Senha por SMS
                                            </button>
                                    :
                                        ''
                                }

                                {email !== '' ?
                                        !loadingEmail ?
                                            <button className="br-button secondary" onClick={sendEmail}>
                                                Enviar Senha por E-mail
                                            </button>
                                        :
                                            <button className="br-button loading" type="button">
                                                Enviar Senha por E-mail
                                            </button>
                                    :
                                        ''
                                }

                                <button className="br-button primary" onClick={generateReceiptPDF}>
                                    Imprimir
                                </button>
                            </ContentContainerButtons>
                        </ContentContainerDown>
                    </GlobalContainer>
                </>
            }
        </>
    );
}