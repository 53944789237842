import React, { useEffect } from 'react';

import { Route } from 'react-router-dom';

import { isAuthenticated } from '../credentials/auth';

export default function PrivateRoute({ component: Component, ...rest }) {
    useEffect(() => {
        rest.handleShowInfo(true);
        rest.handleScrollAtivated();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Route {...rest} render={props => (
            isAuthenticated() ? 
                (<Component 
                    {...props}
                    {...rest}
                />)
            :
                (<div style={{'marginTop': '75px'}}><h1> Unauthorized! </h1></div>)
        )}/>
    );
}