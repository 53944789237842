import styled from 'styled-components';

export const Title = styled.h1 `
    width: 430px;

    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
        margin-right: 30px;
    }
`;

export const NumberText = styled.label `
    font-size: 30px;
    color: #333333;
`;

export const Text = styled.label `
    font-size: 14px;
    font-weight: normal;
    color: #333333;
`;

export const GlobalContainer = styled.div `

    margin-top: 80px;

    @media (max-width: 1000px) {
        div {
            display: grid;
            margin: 0px auto;
        }
    }

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const ContentContainer = styled.div `
    display: flex;
    justify-content: center;
    max-width: 890;
    margin: 30px auto 130px;
`;

export const ContentContainerDown = styled.div `
    display: flex;
    justify-content: center;
    max-width: 890;
    margin: 30px auto 0px;
`;

export const ContentContainer1 = styled.div `
    width: 430px;
    margin-right: 30px;
    padding: 0px 0px 15px;
`;

export const ContentContainer11 = styled.div `
    margin-bottom: 20px;
`;

export const ContentContainer2 = styled.div `
    width: 430px;
`;

export const ContentContainer21 = styled.div `
    margin-bottom: 20px;
`;

export const ContentContainer22 = styled.div `
    text-align: center;
    margin-bottom: 30px;
`;

export const ContentContainer23 = styled.div `
    margin-bottom: 20px;
`;

export const ContentContainer24 = styled.div `
    
`;

export const ContentContainerButtons = styled.div `
    width: 890px;
    text-align: right;

    padding: 0px 0px 20px;

    button:nth-child(2){ /*-pegando o segundo button desta div-*/
        margin-left: 10px;
    }

    button:nth-child(3){ 
        margin-left: 10px;
    }

    button:nth-child(4){ 
        margin-left: 30px;
    }
`;