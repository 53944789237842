import styled from 'styled-components';

export const Title = styled.h1 `
    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
    }
`;

export const Text = styled.label `
    font-size: 14px;
    font-weight: normal;
    color: #333333;
`;

export const GlobalContainer = styled.div `

    margin-top: 75px;

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;

    //--------------------
    //border: 2px solid;
    //border-color: pink;
`;

export const ContentContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
    justify-content: center;
    max-width: 960;
    margin: 0px auto;

    //--------------------
    //border: 2px solid;
    //border-color: black;
`;

export const ContentContainer1 = styled.div `
    width: 380px;
    text-align: center;

    //--------------------
    //border: 2px solid;
    //border-color: blue;
`;

export const ContentContainer2 = styled.div `
    width: 580px;
    padding: 20px 20px 0px 0px;
    //--------------------
    //border: 2px solid;
    //border-color: red;
`;

export const ContentContainer21 = styled.div `
    margin-bottom: 30px;
`;

export const ContentContainer22 = styled.div `
    margin-bottom: 30px;
`;

export const ContentContainer23 = styled.div `
    
`;