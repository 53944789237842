import React, { useEffect, useState } from 'react';

import ShowOperator from '../../Components/ShowOperator';

export default function ShowAgencyManager(props) {
    const [ agencyManager, setAgencyManager ] = useState({
        from: '',
        id_usuario_orgao: '',
        cpf: '',
        name: '',
        organ: '',
        dt_inc: '',
        dt_canc_exp: ''
    });

    const [ loadAgencyManager, setLoadAgencyManager ] = useState(false);
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    useEffect(() => {
        if (!loadAgencyManager && props.location.state !== undefined) {
            setAgencyManager({
                from: props.location.state.from,
                id_usuario_orgao: props.location.state.id_usuario_orgao,
                cpf: props.location.state.cpf,
                name: props.location.state.name,
                organ: props.location.state.organ,
                unidade: props.location.state.unidade,
                dt_inc: props.location.state.dt_inc,
                dt_canc_exp: props.location.state.dt_canc_exp
            });
            setLoadAgencyManager(true);
        }
    }, [props.location.state, loadAgencyManager]);

    return (
        <ShowOperator
            pageTitle={agencyManager.from === "addAgencyManager" ? 'Gestor de órgão cadastrado': 'Gestor de órgão atualizado'}
            operator={agencyManager} 
            setOperator={setAgencyManager}
            loadOperator={loadAgencyManager}
            fromSuccessMsg={agencyManager.from === "addAgencyManager" ? 'Gestor de órgão cadastrado com sucesso.' : 'Gestor de órgão atualizado com sucesso.'}
            buttonBackRoute='/gerenciarGestoresOrgao'
            buttonEditRoute='/editarGestorOrgao'
            buttonRegistrationTitle='+ Cadastrar gestor de órgão'
            buttonRegistrationRoute='/consultaGestorOrgao'
            history={props.history}
            rangePages={rangePages}
            setRangePages={setRangePages}
        />
    );
}