import React, { useState } from 'react';
import ProfileManager from './ProfileManager';

export default function ProfileManagement(props) {
    const [ loading, setLoading] = useState(false);
    const [ erroMsg, setErroMsg ] = useState('');
    const [ modalError, setModalError ] = useState(false);
    const [ profile, setProfile ] = useState({})
    const [ fromSuccessMsg, setFromSuccessMsg ] = useState(undefined);

    const onSubmitSearch = async (data) => {
        setLoading(true);
        setErroMsg('');
        const user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': user ? user.token : ""
            }),
            body: JSON.stringify({
                cpf_funcionario: data.cpf,
                cpf_admin: user.cpf_atendente,
                pagina_atual: "1",
            }),
        };

        await fetch(`${process.env.REACT_APP_API_URL}/atendimento/buscarfuncionario_val`, requestOptions).then(function(response) {
            setLoading(false);
            if (response.ok) {
                response.json().then(function(resp) {
                    setProfile(resp)
                })
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else {
                setModalError(true);
                console.log('Request error in /buscarfuncionario_val BackendBalcao');
            }
            setLoading(false);
        })
    }

    const onSubmitProfileChange = async (data) => {
        setLoading(true);
        setErroMsg('');
        const user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': user ? user.token : ""
            }),
            body: JSON.stringify({
                cpf_funcionario: data.cpf,
                cpf_admin: user.cpf_atendente,
                perfil: data.profile,
                pagina_atual: "1",
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/atendimento/mudarperfil_val`, requestOptions).then(function(response) {
            setLoading(false);
            if (response.ok) {
                response.json().then(function(resp) {
                    setFromSuccessMsg('Mudança de perfil efetuada com sucesso!');
                    setProfile({})
                })
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else {
                setModalError(true);
                console.log('Request error in gestao/gerenciarperfil BackendBalcao');
            }
            setLoading(false);
        })
    }

    return(
       <ProfileManager
            pageTitle='Gerenciador de Perfil (Validação)'
            buttonNextLoading={loading}
            onSubmitSearch={onSubmitSearch}
            onSubmitProfileChange={onSubmitProfileChange}
            searchResult={profile}
            setSearchResult={setProfile}
            history={props.history}
            erroMsg={erroMsg}
            setErroMsg={setErroMsg}
            modalError={modalError}
            setModalError={setModalError}
            fromSuccessMsg={fromSuccessMsg}
       />
    );
}