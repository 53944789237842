import React, { useEffect, useState } from 'react';
import ShowOperator from '../../Components/ShowOperator';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function RegionalManagerDetails(props) {
    const [ regionalManager, setRegionalManager ] = useState({
        cpf: '',
        name: '',
        organ: {value: '', label: ''},
        unidade: {value: '', label: ''},
        dt_inc: '',
        dt_canc_exp: ''
    });
    const [ attendantList, setAttendantList ] = useState([]);
    const [ loadRegionalManager, setLoadRegionalManager ] = useState(false);
    const [ pageOptions, setPageOptions ] = useState([]);
    const [ page, setPage ] = useState({
        label: "1", value: "1"
    });
    const [ totalFilter, setTotalFilter ] = useState("0");
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [ erroMsg, setErroMsg ] = useState([]);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        async function loadRegionalManagerDetails() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                }),
            };
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/detalhaGestorRegional`, requestOptions).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
    
                        var organList = [];
                        var obj = {};

                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO,
                                dt_inc: organ.DATA_INCLUSAO,
                                dt_canc_exp: organ.DATA_CANCELAMENTO
                            }

                            organList.push(obj);
                        });
                        
                        const organ={
                            value: data[0].CODIGO_ORGAO,
                            label: data[0].NOME_ORGAO
                        }

                        const unidade={
                            value: data[0].CODIGO_UNIDADE,
                            label: data[0].NOME_UNIDADE
                        }
                        setRegionalManager({id_usuario_orgao: data[0].ID_USUARIO_ORGAO, cpf: props.location.state.result_search.CPF_USUARIO, name: props.location.state.result_search.NOME_USUARIO, organ: organ, unidade: unidade, dt_inc: data[0].DATA_INCLUSAO, dt_canc_exp: data[0].DATA_CANCELAMENTO});
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            // inserir a mensagem: data.message no aviso de erro
                            console.log(data.message);
                        } else {
                            // inserir mensagem genérica
                            console.log('Response error in gestao/detalhaGestorRegional BackendBalcao');
                        }
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setModalInvalidSessionError(true)
                    })
                } else {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    //setLoading(false);
                    //setModalError(true);
                    console.log('Request error in gestao/detalhaGestorRegional BackendBalcao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        async function loadAssociatedAttendants() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_usuario_cadastrador: props.location.state.result_search.CPF_USUARIO,
                    pagina_atual: 1,
                    qtd_registros: 10
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaAtendentesAssociadosAoGestor`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        const maxPageOption = Math.ceil(Number(data.total) / Number(rangePages.value))
                        let list = []
                        let obj = {}
                        for(var i = 1; i <= maxPageOption; i++) {
                            obj = {
                                value: String(i),
                                label: String(i)
                            }

                            list.push(obj);
                        }

                        setPageOptions(list);
                        setPage({label: "1", value: "1"});
                        setAttendantList(data.atendentes);
                        setTotalFilter(data.total);
                    });
                } else {
                    console.log('Error request to /gestao/listaAtendentesAssociadosAoGestor');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        if (!loadRegionalManager && props.location.state !== undefined) {
            loadRegionalManagerDetails();
            loadAssociatedAttendants();
            setLoadRegionalManager(true);
        }
    }, [props, regionalManager, loadRegionalManager, rangePages]);

    const onChangePaginationConfig = async (ranPag, pag, isRanPag) => {
        setErroMsg(undefined);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_usuario_cadastrador: props.location.state.result_search.CPF_USUARIO,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaAtendentesAssociadosAoGestor`,
                    requestOptions
        ).then(function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }

                    setPageOptions(list);
                    if(isRanPag){
                        setPage({label: "1", value: "1"});
                    }
                    setAttendantList(data.atendentes);
                    setTotalFilter(data.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message)
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                    //setLoading(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setModalInvalidSessionError(true)
                })
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                //setLoading(false);
                console.log('Request error in gestao/listaAtendentesAssociadosAoGestor BackendBalcao');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    };

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <ShowOperator
                pageTitle='Detalhar gestor regional'
                operator={regionalManager} 
                setOperator={setRegionalManager}
                loadOperator={loadRegionalManager}
                buttonBackRoute='/gerenciarGestoresRegionais'
                buttonEditRoute='/editarGestorRegional'
                history={props.history}
                tableResultListTitle='Lista de atendentes cadastrados pelo gestor regional'
                onChangePaginationConfig={onChangePaginationConfig}
                totalFilter={totalFilter}
                searchResult={attendantList}
                pageOptions={pageOptions}
                setPage={setPage}
                page={page}
                erroMsg={erroMsg}
                rangePages={rangePages}
                setRangePages={setRangePages}
            />
        </>
    );
}