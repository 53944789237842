import React, { useState } from 'react'

import { removeInputMask } from '../../utils'

import AttendantScheduleManagement from './AttendantScheduleManagement'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function AttendantSchedule(props) {
  const [loading, setLoading] = useState(false)
  const [rangePages, setRangePages] = useState({
    label: '10',
    value: '10',
  })
  const [pageOptions, setPageOptions] = useState([
    /*
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        */
  ])
  const [page, setPage] = useState({
    label: '1',
    value: '1',
  })

  const [modalError, setModalError] = useState(false)
  const [attendantList, setAttendantList] = useState([])
  const [totalFilter, setTotalFilter] = useState('0')
  const [erroMsg, setErroMsg] = useState('')
  const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
  const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
  const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

  const undoException = async (data) => {
    const user = JSON.parse(localStorage.getItem('user'))

    const requestOptions = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: user ? user.token : '',
      }),

      body: JSON.stringify({
        cpf_gestor: user.cpf_atendente,
        codigo_orgao: user.codigo_orgao,
        codigo_unidade: data.CODIGO_UNIDADE,
        id_usuario_orgao: data.ID_USUARIO_ORGAO,
        cpf_usuario_atendente: data.CPF_USUARIO
      }),
    }

    await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/apagaHorarioAtendente`, requestOptions)
      .then(function (response) {
        if (response.ok) {
          response.json().then(function () {
            onSubmitSearch(data)
          })
        } else {
          response.json().then(function (data) {
            // setErroMsg(data.messages)
            console.log('Error request to /gestao/horario/apagaHorarioAtendente', data)
          })
        }
      })
      .catch(function (err) {
        console.error('Failed retrieving information', err)
      })
  }

  const onSubmitSearch = async (data) => {
    const user = JSON.parse(localStorage.getItem('user'))
    setLoading(true)
    setAttendantList([])
    setErroMsg('')
    data.cpf = removeInputMask(data.cpf)
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: user ? user.token : '',
      }),
      body: JSON.stringify({
        cpf_gestor: user.cpf_atendente,
        codigo_orgao: user.codigo_orgao,
        codigo_unidade: user.codigo_unidade,
        cpf_usuario: removeInputMask(data.cpf),
        nome_usuario: data.name,
        apenas_horarios_excecao: data.exception,
        pagina_atual: '1',
        qtd_registros: rangePages.value,
      }),
    }
    await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/listaHorarioAtendentes`, requestOptions)
      .then(function (response) {
        setLoading(false)
        if (response.ok) {
          response.json().then(function (resp) {
            // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

            const maxPageOption = Math.ceil(Number(resp.total) / Number(rangePages.value))
            let list = []
            let obj = {}
            for (var i = 1; i <= maxPageOption; i++) {
              obj = {
                value: String(i),
                label: String(i),
              }

              list.push(obj)
            }

            setPageOptions(list)
            setPage({ label: '1', value: '1' })
            setAttendantList(resp.atendentes)
            setTotalFilter(resp.total)
          })
        } else if (response.status === 422) {
          response.json().then(function (data) {
            if (!!data.errorCode && !!data.message) {
              setErroMsg(data.message)
            } else {
              setErroMsg('Houve algum problema no servidor.')
            }
            //setLoading(false);
          })
        } else if (response.status === 403) {
          response.json().then(function (data) {
            setModalInvalidSessionErrorTitle(data.title ? data.title : null)
            setModalInvalidSessionErrorMsg(data.message ? data.message : null)
            setLoading(false)
            setModalInvalidSessionError(true)
          })
        } else {
          // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
          //setLoading(false);
          setModalError(true)
          console.log('Request error in gestao/listaGestoresRegionais BackendBalcao')
        }
      })
      .catch(function (err) {
        setLoading(false)
        console.error('Failed retrieving information', err)
      })
  }

  const onChangePaginationConfig = async (data, ranPag, pag, isRanPag) => {
    setErroMsg('')
    setLoading(true)
    const user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: user ? user.token : '',
      }),
      body: JSON.stringify({
        cpf_gestor: user.cpf_atendente,
        codigo_orgao: data.organ,
        codigo_unidade: data.unidade,
        cpf_usuario: removeInputMask(data.cpf),
        nome_usuario: data.name,
        pagina_atual: isRanPag ? '1' : pag.value,
        qtd_registros: ranPag.value,
        apenas_horarios_excecao: data.exception,
      }),
    }
    await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/listaHorarioAtendentes`, requestOptions)
      .then(function (response) {
        setLoading(false)
        if (response.ok) {
          response.json().then(function (data) {
            // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

            const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
            let list = []
            let obj = {}
            for (var i = 1; i <= maxPageOption; i++) {
              obj = {
                value: String(i),
                label: String(i),
              }

              list.push(obj)
            }

            setPageOptions(list)
            if (isRanPag) {
              setPage({ label: '1', value: '1' })
            }
            setAttendantList(data.atendentes)
            setTotalFilter(data.total)
          })
        } else if (response.status === 422) {
          response.json().then(function (data) {
            if (!!data.errorCode && !!data.message) {
              setErroMsg(data.message)
            } else {
              setErroMsg('Houve algum problema no servidor.')
            }
            //setLoading(false);
          })
        } else if (response.status === 403) {
          response.json().then(function (data) {
            setModalInvalidSessionErrorTitle(data.title ? data.title : null)
            setModalInvalidSessionErrorMsg(data.message ? data.message : null)
            setLoading(false)
            setModalInvalidSessionError(true)
          })
        } else {
          // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
          //setLoading(false);
          setModalError(true)
          console.log('Request error in gestao/listaGestoresRegionais BackendBalcao')
        }
      })
      .catch(function (err) {
        setLoading(false)
        console.error('Failed retrieving information', err)
      })
  }

  return (
    <>
      <ModalWarn
          id='modalWarnalertInvalidSession'
          appear={modalInvalidSessionError}
          title = { modalInvalidSessionErrorTitle }
          text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
          textButton2='Ir para tela inicial'
          actionButton2={() => { 
              document.getElementById("btnLogout").click();
          }}
      />

      <AttendantScheduleManagement
        pageTitle='Gestão de horários de exceção para atendentes'
        buttonRegistrationRoute='/consultaGestorRegional'
        fieldNamePlaceholder='Digite o nome do atendente'
        tableResultListTitle='Lista de atendentes cadastrados'
        buttonDetailRoute='/detalharHorarioOrgao'
        buttonEditRoute='/editarHorarioOrgao'
        buttonCancelRoute='/cancelarGestorRegional'
        buttonUndoException={undoException}
        buttonNextLoading={loading}
        onSubmitSearch={onSubmitSearch}
        onChangePaginationConfig={onChangePaginationConfig}
        searchResult={attendantList}
        setSearchResult={setAttendantList}
        history={props.history}
        totalFilter={totalFilter}
        pageOptions={pageOptions}
        setPage={setPage}
        page={page}
        erroMsg={erroMsg}
        setErroMsg={setErroMsg}
        rangePages={rangePages}
        setRangePages={setRangePages}
        modalError={modalError}
        setModalError={setModalError}
      />
    </>
  )
}
