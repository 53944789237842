import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputContainer } from '../../Management/styles';
import { ModalCenterFont } from './styles';
import FailIcon from './../../../assets/fail_icon.png'
import { ContentContainerBlock2, SelectContainer } from '../../AddOperator/styles';
import ModalWarn from '../../../Components/Modal/Warn/index'

function ModalFormManterOrgao(props) {

    const [loading, setLoading] = useState(false)
    const [messageError, setMessageError] = useState("")
    const { handleSubmit } = useForm();
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
    const modalStyle = {
        maxWidth: 'fit-content'
    }

    const inputContainerStryle = {
        marginBottom: '25px',
        display: 'block',
        // fontSize: 'var(--font-size-scale-up-01)',
        fontWeight: 'var(--font-weight-medium)',
        height: '2.5em',
        paddingLeft: '0',
        paddingRight: 'var(--spacing-scale-5x)',
        width: '35em'
    }

    const SelectStyle = {
        backgroundColor: 'var(--bg-color)',
        borderRadius: '6px',
        color: 'var(--color-secondary-07)',
        display: 'block',
        // fontSize: 'var(--font-size-scale-up-01)',
        fontWeight: 'var(--font-weight-medium)',
        height: '3em',
        width: '32.1em',
        paddingLeft: 'var(--spacing-scale-2x)',
        paddingRight: 'var(--spacing-scale-5x)',
    }

    const [defaultFormValues, setDefaultFormValues] = useState({
        nome_orgao: "",
        codigo_orgao: "",
        valida_serv_publico: ""
    });

    const onSubmit = async (data) => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': user ? user.token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: user.cpf_atendente,
                codigo_orgao: data.codigo_orgao,
                nome_orgao: data.nome_orgao,
                valida_serv_publico: data.valida_serv_publico
            })
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/cadastraOrgao`, requestOptions).then(
            function (response) {
                if (response.ok) {
                    props.actionButton1()
                    window.location.reload(); // rever esse refresh de página para atualizar apenas o componente unidade.
                } else if (response.status === 422) {
                    response.json().then(function (err) {
                        if (!!err.errorCode && !!err.message) {
                            // inserir a mensagem: data.message no aviso de erro
                            setMessageError(err.message);
                        } else {
                            // inserir mensagem genérica
                            setMessageError('Houve algum problema no servidor.');
                        }
                        console.log('Response error in /gestao/cadastraGestorRegional BackendBalcao');
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setLoading(false)
                      setModalInvalidSessionError(true)
                    })
                }
            });
        setLoading(false)
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <form id="formOrgao" onSubmit={handleSubmit(() => onSubmit(defaultFormValues))}>
                <ModalCenterFont appear={props.appear}>
                    <div className="br-modal is-medium" style={modalStyle} id="modal">
                        <div className="br-card">
                            <div className="container-fluid p-4">
                                <div className="br-modal-header">
                                    <div className="br-modal-title">
                                        <strong>Cadastrar órgão</strong>
                                    </div>
                                </div>

                                <div className="br-modal-body">
                                    {messageError !== "" &&
                                        <span className="feedback danger" style={{ marginBottom: '20px' }} role="alert">
                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                <img src={FailIcon} style={{ paddingBottom: '5px' }} alt="Erro:" />
                                            </i>
                                            {messageError}
                                        </span>
                                    }
                                    <br />
                                    <ContentContainerBlock2>
                                        <InputContainer>
                                            <div className="br-input" style={inputContainerStryle}>
                                                <input
                                                    required
                                                    id="nome"
                                                    name="nome"
                                                    placeholder="Digite o nome do órgão"
                                                    type="text"
                                                    defaultValue=""
                                                    onChange={(ev) => setDefaultFormValues({ ...defaultFormValues, nome_orgao: ev.target.value })}
                                                />
                                            </div>

                                        </InputContainer>
                                        <InputContainer>
                                            <div className="br-input" style={inputContainerStryle}>
                                                <input
                                                    required
                                                    id="codigo"
                                                    name="codigo"
                                                    placeholder="Digite o código do órgão"
                                                    type="text"
                                                    defaultValue=""
                                                    onChange={(ev) => setDefaultFormValues({ ...defaultFormValues, codigo_orgao: ev.target.value })}
                                                />
                                            </div>
                                        </InputContainer>
                                        <SelectContainer>
                                            <select required onChange={ev => setDefaultFormValues({ ...defaultFormValues, valida_serv_publico: ev.target.value })} style={SelectStyle}>
                                                <option value="">Validar servidor público?</option>
                                                <option value="S">Sim</option>
                                                <option value="N">Não</option>
                                            </select>
                                        </SelectContainer>
                                    </ContentContainerBlock2>
                                </div>

                                <div className="br-modal-footer justify-content-end">
                                    <button className="br-button secondary small m-2" type="button" onClick={props.actionButton1}>
                                        {props.textButton1}
                                    </button>
                                    {loading ?
                                        <button className="br-button secundary small m-2" type="button">
                                            <div className="loading">{props.textButton2}</div>
                                        </button>
                                        :
                                        <button className="br-button primary small m-2" type="submit" onClick={props.actionButton2}>
                                            {props.textButton2}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalCenterFont>
            </form>
        </>
    );
}

export default ModalFormManterOrgao;