import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';

import {
    GlobalContainer,
    ContentContainer,
    ContentContainerBlock,
    ContentContainer1,
    ContentContainer2,
    ContentContainer11,
    ContentContainer12,
    ContentContainer13,
    ContentContainer14,
    Title,
    Text,
    TitleContainer,
    ContentContainerButtons,
    SelectContainer,
    ContentContainerButtonsWithMarginLeft
} from './styles';
import Select, { components } from 'react-select';
import MessageAlert from '../Messages/AlertManager/index';
import ModalWarn from '../Modal/Warn/index';
import ModalCancel from '../Modal/Cancel/index';

import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import FailIcon from '../../assets/fail_icon.png';
import { cpfMask } from '../../utils';
import { profile } from '../../constants';
import ModalFormManterOrgao from '../Modal/Form/ModalFormManterOrgao';

export default function Add(props) {
    const [defaultFormValues, setDefaultFormValues] = useState({
        cpf: "",
        name: "",
        organ: "",
        unidade: ""
    });

    const { handleSubmit, errors, control } = useForm({ defaultFormValues });
    const [isModalCadastrarOrgaoVisible, setModalCadastrarOrgaoVisible] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'));
    const [organ, setOrgan] = useState(
        {
            value: user ? user.codigo_orgao : "",
            label: user ? user.orgao : "Selecione o órgão"
        }
    );
    const [unidade, setUnidade] = useState(
        {
            value: user ? user.codigo_unidade : "",
            label: user ? user.unidade : "Selecione a região"
        }
    );

    useEffect(() => {
        setDefaultFormValues({ ...defaultFormValues, organ: organ, unidade: unidade });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ESTILIZACAO PARA O SELECT ======================================================
    const SelecStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: state.isFocused ? 2.5 : 1,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 6,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: 13.5,
            fontWeight: 355,
            marginLeft: 7,
            fontStyle: 'italic',
        }),
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon" />
                }
            </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => <span style={{}} {...innerProps} />;

    const [isModalCancelVisible, setModalCancelVisible] = useState(false);

    const perfil = JSON.parse(localStorage.getItem("user")).perfil;

    // ================================================================================

    return (
        <>
            {!props.loadOperator ?
                <div style={{ 'marginTop': '75px' }}><h1> Error on the page! </h1></div>
                :
                <>
                    <ModalFormManterOrgao
                        id="modalcadastrarorgao"
                        appear={isModalCadastrarOrgaoVisible}
                        actionButton1={() => setModalCadastrarOrgaoVisible(false)}
                        textButton1="Cancelar"
                        textButton2="Cadastrar"
                    />

                    <ModalCancel
                        id="modalcancelalert"
                        appear={isModalCancelVisible}
                        text={"Tem certeza que deseja cancelar este cadastro?"}
                        actionButton1={() => setModalCancelVisible(false)}
                        actionButton2={() => props.history.push(props.buttonCancelRoute)}
                    />

                    <ModalWarn
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text={props.messageError}
                        textButton2='Ok'
                        actionButton2={() => props.setModalError(false)}
                    />

                    <GlobalContainer>
                        {props.operator.ident_dig === "false" && <MessageAlert cpf={props.operator.cpf} />}

                        <TitleContainer>
                            <Title> {props.pageTitle} </Title>
                        </TitleContainer>

                        <form onSubmit={handleSubmit(() => props.onSubmitData(defaultFormValues))}>
                            <ContentContainer>
                                <ContentContainer1>
                                    <ContentContainer11>
                                        <label> CPF </label>
                                        <Text> {cpfMask(props.operator.cpf)} </Text>
                                    </ContentContainer11>

                                    <ContentContainer12>
                                        <label> Nome </label>
                                        <Text> {props.operator.name} </Text>
                                    </ContentContainer12>

                                        <ContentContainerBlock>
                                            <ContentContainer13>
                                                {perfil !== profile.gestorSistema && 
                                                    <>
                                                        <label> Órgão </label>
                                                        <p> {organ.label} </p>
                                                    </>
                                                }
                                                {perfil === profile.gestorSistema &&
                                                    <SelectContainer>
                                                        <label> Órgão </label>
                                                            <Controller
                                                                name="organ"
                                                                onChange={(e) => setOrgan(e.value)}
                                                                control={control}
                                                                defaultValue={organ.value === "" ? "" : organ}
                                                                value={organ.value === "" ? "" :organ}
                                                                render={
                                                                    (e) => (
                                                                        <Select
                                                                            onChange={(value) => {
                                                                                e.onChange(value);
                                                                                setOrgan({ value: value.value, label: value.label });
                                                                                setDefaultFormValues({ ...defaultFormValues, organ: {value: value.value, label: value.label} });
                                                                                if (props.loadUnidade) props.loadUnidade(value.value);
                                                                            }}
                                                                            options={props.organOptions}
                                                                            defaultValue={organ.value === "" ? "" : organ}
                                                                            value={organ.value === "" ? "" :organ}
                                                                            placeholder="Selecione o órgão"
                                                                            styles={SelecStyles}
                                                                            theme={theme => ({
                                                                                ...theme,
                                                                                borderRadius: 6,
                                                                                colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#CCCCCC',
                                                                                primary: '#1351B4'
                                                                                },
                                                                            })}
                                                                            components={
                                                                                { DropdownIndicator,
                                                                                IndicatorSeparator }
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                                rules={{
                                                                    required: { value: true, message: "Selecione um órgão." }
                                                                    //validate: () => organ.value !== "" ? true : "Selecione um órgão."
                                                                }}
                                                            />  
                                                    </SelectContainer>
                                                }
                                                {errors?.organ && 
                                                <span className="feedback danger" role="alert" id="cpfoperator">
                                                    <i className="fas fa-times-circle" aria-hidden="true">
                                                        <img
                                                            src={FailIcon}
                                                            style={{ paddingBottom: '5px' }}
                                                            alt="failicon"
                                                        />
                                                    </i>
                                                    {errors.organ.message}
                                                </span>}
                                        </ContentContainer13>
                                        {perfil === profile.gestorSistema &&
                                            <ContentContainerButtonsWithMarginLeft>
                                                <button className="br-button primary" type="button"
                                                    onClick={() => setModalCadastrarOrgaoVisible(true)}>
                                                    Cadastrar orgão
                                                </button>
                                            </ContentContainerButtonsWithMarginLeft>
                                        }
                                    </ContentContainerBlock>

                                    <ContentContainerBlock>
                                        <ContentContainer14>
                                            {perfil === profile.gestorRegional &&
                                                <>
                                                    <label> Unidade do Órgão </label>
                                                    <p> {unidade.label} </p>
                                                </>
                                            }
                                            {perfil === profile.gestorOrgao &&
                                                <SelectContainer>
                                                    <label> Unidade do Órgão </label>
                                                    <Controller
                                                        name="unidade"
                                                        onChange={(e) => setUnidade(e.value)}
                                                        control={control}
                                                        render={
                                                            (e) => (
                                                                <Select
                                                                    onChange={(value) => {
                                                                        e.onChange(value);
                                                                        setUnidade({ value: value.value, label: value.label })
                                                                        setDefaultFormValues({ ...defaultFormValues, unidade: { value: value.value, label: value.label } });
                                                                    }}
                                                                    options={props.unidadeOptions}
                                                                    placeholder="Selecione uma unidade"
                                                                    styles={SelecStyles}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        borderRadius: 6,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#CCCCCC',
                                                                            primary: '#1351B4'
                                                                        },
                                                                    })}
                                                                    components={
                                                                        {
                                                                            DropdownIndicator,
                                                                            IndicatorSeparator
                                                                        }
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        rules={{
                                                            required: { value: true, message: "Selecione uma unidade." }
                                                            //validate: () => organ.value !== "" ? true : "Selecione um órgão."
                                                        }}
                                                    />
                                                </SelectContainer>
                                            }
                                            {errors?.unidade &&
                                                <span className="feedback danger" role="alert" id="cpfoperator">
                                                    <i className="fas fa-times-circle" aria-hidden="true">
                                                        <img
                                                            src={FailIcon}
                                                            style={{ paddingBottom: '5px' }}
                                                            alt="failicon"
                                                        />
                                                    </i>
                                                    {errors.unidade.message}
                                                </span>}
                                        </ContentContainer14>
                                    </ContentContainerBlock>


                                    <ContentContainer14>
                                        <div className="br-input">
                                            <label htmlFor="dt_canc_exp">Data de Expiração</label>
                                            <input
                                                id="dateandtime-input"
                                                name="dt_canc_exp"
                                                type="date"
                                                data-input="data-input"
                                                placeholder="dd/mm/aaaa"
                                                defaultValue={props.operator.dt_canc_exp}
                                                onChange={e => props.setOperator({ ...props.operator, dt_canc_exp: e.target.value })}
                                                style={{
                                                    "padding": "0px 7px 0px",
                                                    "fontSize": "14px",
                                                    "fontWeight": "normal",
                                                    "color": "#333333"
                                                }}
                                            />
                                        </div>
                                    </ContentContainer14>
                                </ContentContainer1>
                            </ContentContainer>

                            <ContentContainer>
                                <ContentContainer1>

                                </ContentContainer1>

                                <ContentContainer2>
                                    <ContentContainerButtons>
                                        <button className="br-button" type="button"
                                            onClick={() => setModalCancelVisible(true)}>
                                            Voltar
                                        </button>

                                        {!props.loadingAdd ?
                                            <button className="br-button primary" type="submit">
                                                Confirmar
                                            </button>
                                            :
                                            <button className="br-button primary loading" type="button">
                                                Confirmar
                                            </button>
                                        }
                                    </ContentContainerButtons>
                                </ContentContainer2>
                            </ContentContainer>
                        </form>
                    </GlobalContainer>
                </>
            }
        </>
    );
}