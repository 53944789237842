import React  from 'react';

import { Text, Text2, HeaderUp } from './styles';
//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

//import jwt from 'jwt-decode';

import UserIcon from '../../assets/user_icon.png';

class Header extends React.Component {
    constructor(props) {
        super(props);
        const userPictureContainsData = /^data:/.test(this.props.handleUser.picture)
        const userPictureContainsBase64 = /.?base64,?/.test(this.props.handleUser.picture)

        this.state = { 
            name: props.handleUser.name,
            picture: (userPictureContainsData && userPictureContainsBase64)? this.props.handleUser.picture : " ",
            organ: props.handleUser.organ,
            perfil: props.handleUser.perfil
        };
    };

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.handleUser !== prevProps.handleUser) {
            const userPictureContainsData = /^data:/.test(this.props.handleUser.picture)
            const userPictureContainsBase64 = /.?base64,?/.test(this.props.handleUser.picture)

            this.setState({
            name: this.props.handleUser.name,
            picture: (userPictureContainsData && userPictureContainsBase64)? this.props.handleUser.picture : " ",
            organ: this.props.handleUser.organ,
            perfil: this.props.handleUser.perfil
          });
        }
    };
    
    render() {
        return (
            <HeaderUp showInfo={this.props.showInfo}>
                <div className="br-header">
                    <div className="container-lg">
                        <div className="header-top">
                            <div className="header-logo">
                                <a href="https://www.gov.br/pt-br" rel="noreferrer" target="_blank">
                                    <img 
                                        src="https://upload.wikimedia.org/wikipedia/commons/1/11/Gov.br_logo.svg" 
                                        alt="govlogo" 
                                    />
                                </a>
                            </div>
                            <div className="header-actions" id='UserInfo'>

                                <div style={{ 'marginRight': '10px', 'display': 'grid' }}>
                                    <Text2>
                                        {this.state.perfil === "S" && 'Administrador,'}
                                        {this.state.perfil === "O" && 'Gestor(a) de Órgão,'}
                                        {this.state.perfil === "R" && 'Gestor(a) Regional,'}
                                        {this.state.perfil === "A" && 'Atendente,'}
                                    </Text2>
                                    <Text>
                                        {this.state.name}
                                    </Text>
                                    <Text2>
                                        {this.state.organ}
                                    </Text2>
                                </div>

                                <div className="header-avatar">
                                    <span className="br-avatar" title="Foto do usuário">
                                        <span className="image">
                                            <img 
                                                src={
                                                    this.state.picture === " " ?
                                                        UserIcon
                                                    :
                                                    this.state.picture
                                                } 
                                                alt="userPicture"
                                            />
                                        </span>
                                    </span>
                                </div>

                                <div className="header-login">
                                    <div className="header-sign-in">
                                        <button id="btnLogout" className="br-button sign-in" 
                                                type="button"
                                                onClick={() => {      
                                                    fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                                                        method: 'GET',
                                                        headers: {
                                                          authorization: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
                                                          Accept: 'application/json',
                                                        },
                                                      });                                              
                                                    const { REACT_APP_LOGIN_UNICO_URL,REACT_APP_REDIRECT_URI_LOGOUT} = process.env;//, REACT_APP_RESPONSE_TYPE, REACT_APP_CLIENT_ID, REACT_APP_SCOPE, REACT_APP_REDIRECT_URI, REACT_APP_NONCE, REACT_APP_STATE } 
                                                    // const urlLogin = `${REACT_APP_LOGIN_UNICO_URL}/authorize?response_type=${REACT_APP_RESPONSE_TYPE}&client_id=${REACT_APP_CLIENT_ID}&scope=${REACT_APP_SCOPE}&redirect_uri=${REACT_APP_REDIRECT_URI}&nonce=${REACT_APP_NONCE}&state=${REACT_APP_STATE}`;
                                                    localStorage.clear();                                                                                                        
                                                    const possibleURLs = ["https://sso.staging.acesso.gov.br", "https://sso.acesso.gov.br"];
                                                    if (possibleURLs.includes(REACT_APP_LOGIN_UNICO_URL)) {
                                                        window.location.href = `${REACT_APP_LOGIN_UNICO_URL}/logout?post_logout_redirect_uri=${REACT_APP_REDIRECT_URI_LOGOUT}`}        
                                                        else { alert("Error: URL não está contida na lista de possívels URLs do sistema. Contacte o suporte")}                                                      
                                                    }}>
                                            Sair
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HeaderUp>
        );
    }
}

export default Header;