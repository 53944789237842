import govLogo from '../assets/govbr_receipt_logo.png';

export const docDefinition = (name, cpf, password, date) => {
    
    const firstName = name.split(' ')[0].toUpperCase();
    const lastTwoLetterName = name.slice(-2).toUpperCase();

    const firstTwoNumbersCPF = cpf.slice(0,2);

    return {
        pageSize: 'A7',
        pageMargins: [ 20, 10, 20, 10 ],
        content: [
            {
                image: govLogo,
                fit: [70, 70]
            },
            '\n',
            {
                text: 'Senha Provisória da Conta gov.br',
                style: 'subheader'
            },
            '_______________________________',
            '\n',
            {
                text: `${password}`,
                style: 'password'
            },
            '\n',
            {
                text: ['Acesse o site ', {text: 'acesso.gov.br ', bold: true}, 'e informe seu CPF e a senha provisória. Em seguida, crie sua senha definitiva.\n\nSua senha é pessoal e intransferível.'],
                style: 'text'
            },
            '_______________________________',
            '\n',
            {
                text: `${firstName}*** **${lastTwoLetterName}`,
                style: 'name'
            },
            {
                text: `CPF ${firstTwoNumbersCPF}X.XXX.XXX-XX`,
                style: 'cpf'
            },
            '\n',
            {
                text: `Emissão: ${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}   ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}h`,
                style: 'emition'
            },
        ],
        
        styles: {
            header: {
                fontSize: 20,
                bold: true
            },
            subheader: {
                fontSize: 13,
                bold: false,
                alignment: 'center'
            },
            password: {
                fontSize: 18,
                bold: true,
                alignment: 'center'
            },
            text: {
                fontSize: 10,
                bold: false
            },
            name: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
            },
            cpf: {
                fontSize: 10,
                bold: false,
                alignment: 'center'
            },
            emition: {
                fontSize: 10,
                bold: false,
                alignment: 'center'
            }
        }  
    }
};