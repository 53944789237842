import React from 'react'
import { GlobalContainer, ContentContainer, TitleContainer, Title, Subtitle, Line } from './styles'
import FilterIcon from '../../assets/filter_icon.png'
import AlertIcon from '../../assets/alert_icon2.png'
import ModalWarn from '../../Components/Modal/Warn'
import Search from './Search'
import Profile from './Profile'
import MessageSuccess from '../../Components/Messages/Success'

export default function ProfileManager(props) {
  return (
    <>
      <ModalWarn
        id='modalconfirmalert'
        appear={props.modalError}
        text={'Houve algum problema no servidor. Aguarde um momento e tente novamente.'}
        textButton2='Ok'
        actionButton2={() => props.setModalError(false)}
      />

      <GlobalContainer>
        {props.fromSuccessMsg && <MessageSuccess text={props.fromSuccessMsg} />}
        <ContentContainer>
          <TitleContainer>
            <Title>{props.pageTitle}</Title>
          </TitleContainer>

          <Subtitle>
            <img src={FilterIcon} alt='filterIcon' style={{ marginBottom: '7px', marginRight: '7px' }} />
            <label> Filtro </label>
            <Line />
          </Subtitle>

          <Search onSubmitSearch={props.onSubmitSearch} setErroMsg={props.setErroMsg} searchResult={props.searchResult} setSearchResult={props.setSearchResult} history={props.history} buttonNextLoading={props.buttonNextLoading} />

          <Subtitle>
            <Line />
          </Subtitle>

         <Profile searchResult={props.searchResult} onSubmitProfileChange={props.onSubmitProfileChange} buttonNextLoading={props.buttonNextLoading} />

          {props.erroMsg && (
            <span className='feedback warning' role='alert' id='listresult'>
              <i className='fas fa-times-circle' aria-hidden='true'>
                <img src={AlertIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
              </i>
              <label id='listresultlabel'>{props.erroMsg}</label>
            </span>
          )}
        </ContentContainer>
      </GlobalContainer>
    </>
  )
}
