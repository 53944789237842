import React, { useState, useEffect } from 'react';

import  { Redirect } from 'react-router-dom';

import { ContainerLoad } from './styles';

export default function RedirectLogin(props) {
    const [auth, setAuth] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect (() => {
       /* async function fetchPicture(url, access_token) {
            const requestOptions = {
                method: 'GET',
                headers: new Headers({
                'Authorization': `Bearer ${access_token}`
                }),
            };
            
            await fetch(`${url}/userinfo/picture`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.blob().then(function(data) {
    
                        // gambearra feita para receber o base64 da requisicao
                        // nao inseri so o base64, pois ele vem sem o cabecalho(data:image/jpeg)
                        // como vem sem o cabecalho, nao sei se vem jpeg ou png ...
                        var urlCreator = window.URL || window.webkitURL;
                        const img = urlCreator.createObjectURL(data);

                        //console.log(img);

                        props.handlePicture(img);
                        localStorage.setItem('user_picture', img);

                        props.handleScrollAtivated();

                        setAuth(true);
                    });
                } else {
                    localStorage.setItem('user_picture', ' ');

                    setAuth(true);
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });      

        }*/

        async function fetchData() {
            
            const code = new URLSearchParams(props.location.search).get('code');
            const user = JSON.parse(localStorage.getItem('user'));
            const lstoken = user ? user.token : null
            //const state = new URLSearchParams(props.location.search).get('state')
            const {  REACT_APP_API_URL } = process.env;
    
            // console.log('REACT_APP_API_URL',REACT_APP_API_URL)
            
          /*  await fetch(`${REACT_APP_LOGIN_UNICO_URL}/token?grant_type=authorization_code&code=${code}&redirect_uri=${REACT_APP_REDIRECT_URI}`, requestOptions)
            .then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        // RESGATEI O ACCESS E ID TOKEN !!!!
                        const id_token = data.id_token;
                        const access_token = data.access_token;
                        */
                        const requestOptions = {
                            method: 'GET'  ,        
                            headers: new Headers({
                               // 'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                                 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                                 'Content-Type': 'application/json',
                                 'Accept': 'application/json',
                                // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                                // 'Access-Control-Allow-Credentials':'true','GET', 'POST', 'OPTIONS',
                                 //'Authorization': 'Basic ' + base64.encode(username + ":" + password)
   
                                }),
                        };
                       await   fetch(`${REACT_APP_API_URL}/login/${code}/${lstoken}`,requestOptions)
                        .then(function(response) {
                            if (response.ok) {
                                response.json().then(function(data) {
                                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
                                    const user = data;
                                    localStorage.setItem('user', JSON.stringify(user));
                                   
                                    props.handleName(user.nome_atendente);
                                    props.handleOrgan(user.orgao);
                                    props.handlePerfil(user.perfil);
                                    //console.log('Foto :::',user.foto)
                                    const img = user.foto;
                                    props.handlePicture(img);
                                    localStorage.setItem('user_picture', img);
                                    props.handleScrollAtivated();
                                    setAuth(true);
                                });
                            } else if (response.status === 422) {
                                console.log('Entrei no IF')
                                response.json().then(function(data) {
                                    // ALGO COM A CONTA DO USUARIO ESTA EM DESACORDO !!!!
                                    
                                    if (!!data.errorCode && !!data.message) {
                                        setErrorMessage(data.message);
                                    } else {
                                        setErrorMessage("Houve algum erro no servidor da aplicação.");
                                    }

                                    setAuth(false);
                                });
                            }
                            else {
                                console.log('Entrei no ELSE')
                                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                                console.log('Request error in login BackendBalcao');
                               
                                setErrorMessage("Houve algum erro no servidor da aplicação.");
                                setAuth(false);
                            }
                        }).catch(function(err) {
                            console.error('Erro na chamada do método fetch logn ', err);
                        });
                    }
     /* }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }*/

        if (firstLoad) {
            setFirstLoad(false);

            fetchData();
        }

    }, [props.location.search, props, firstLoad]);

    return (
        <>
            {
                auth !== null ?
                        auth === true ?
                                <Redirect to='/pesquisarcidadao'/> 
                            : 
                                <Redirect to={{
                                    pathname: '/loginerror',
                                    state: { message: errorMessage }
                                }}/>
                    :
                        <ContainerLoad>
                            <div className="loading medium" id="load"></div>
                        </ContainerLoad>
            }
        </>
    );
}