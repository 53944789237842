import React, { useEffect, useState } from 'react';
import ShowOperator from '../../Components/ShowOperator';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function AttendantDetails(props) {
    const [ attendant, setAttendant ] = useState({
        cpf: '',
        name: '',
        organ: {value: '', label: ''},
        unidade: {value: '', label: ''},
        dt_inc: '',
        dt_canc_exp: ''
    });

    const [ loadAttendant, setLoadAttendant ] = useState(false);
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)


    useEffect(() => {
        async function loadAttendantDetails() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                }),
            };
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/detalhaatendente`,
                        requestOptions
            ).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
    
                        var organList = [];
                        var obj = {};

                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO,
                                dt_inc: organ.DATA_INCLUSAO,
                                dt_canc_exp: organ.DATA_CANCELAMENTO
                            }

                            organList.push(obj);
                        });
                        
                        const organ={
                            value: data[0].CODIGO_ORGAO,
                            label: data[0].NOME_ORGAO
                        }

                        const unidade={
                            value: data[0].CODIGO_UNIDADE,
                            label: data[0].NOME_UNIDADE
                        }
                        setAttendant({
                            id_usuario_orgao: data[0].ID_USUARIO_ORGAO,
                            cpf: props.location.state.result_search.CPF_USUARIO,
                            name: props.location.state.result_search.NOME_USUARIO,
                            cpf_cadastrador: props.location.state.result_search.CPF_USUARIO_CADASTRADOR,
                            nome_cadastrador: props.location.state.result_search.NOME_CADASTRADOR,
                            organ: organ,
                            unidade: unidade,
                            dt_inc: data[0].DATA_INCLUSAO,
                            dt_canc_exp: data[0].DATA_CANCELAMENTO
                        });
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            // inserir a mensagem: data.message no aviso de erro
                            console.log(data.message);
                        } else {
                            // inserir mensagem genérica
                            console.log('Response error in gestao/detalhaatendente BackendBalcao');
                        }
                        //setLoading(false);
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setModalInvalidSessionError(true)
                    })
                } else {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    //setLoading(false);
                    //setModalError(true);
                    console.log('Request error in gestao/detalhaatendente BackendBalcao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        if (!loadAttendant && props.location.state !== undefined) {
            loadAttendantDetails();
            setLoadAttendant(true);
        }
    }, [props, attendant, loadAttendant]);

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />

            <ShowOperator
                pageTitle='Detalhar Atendente'
                operator={attendant} 
                setOperator={setAttendant}
                loadOperator={loadAttendant}
                buttonBackRoute='/gerenciaratendentes'
                buttonEditRoute='/editaratendente'
                history={props.history}
                rangePages={rangePages}
                setRangePages={setRangePages}
            />
        </>
    );
}