import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import {
    GlobalContainer,
    ContentContainer,
    ContentContainerBlock,
    ContentContainer1,
    ContentContainer2,
    ContentContainer11,
    ContentContainer12,
    ContentContainer13,
    ContentContainer14,
    ContentContainer15,
    Title,
    TitleContainer,
    ContentContainerButtons,
    SelectContainer,
    Mandatory
} from './styles';
import Select, { components } from 'react-select';
import ModalWarn from '../Modal/Warn/index';
import ModalCancel from '../Modal/Cancel/index';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import FailIcon from '../../assets/fail_icon.png';
import { formatarCEP } from '../../utils';

export default function ShowUnitElement(props) {
    const [defaultFormValues, setDefaultFormValues] = useState({
        organCode: "",
        unitCode: "",
        unitName: "",
        zipcode: "",
        state: "",
        street: "",
        number: "" ,   
        addressComplement: "",
        neighborhood: "",
        city: ""
    });

    const { handleSubmit } = useForm({ defaultFormValues });
    const [showErroUnitName, setShowErroUnitName] = useState(false);
    const [showErroZipcode, setShowErroZipcode] = useState(false);
    const [showInvalidErroZipcode, setShowInvalidErroZipcode] = useState(false);
    const [showErroStreet, setShowErroStreet] = useState(false);
    const [showErroState, setShowErroState] = useState(false);
    const [showErroNeighborhood, setShowErroNeighborhood] = useState(false);
    const [showErroCity, setShowErroCity] = useState(false);

    useEffect(() => {
            setDefaultFormValues(props.unit);         
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.unit])

    // ESTILIZACAO PARA O SELECT ======================================================
    const SelecStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: state.isFocused ? 2.5 : 1,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 6,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: 13.5,
            fontWeight: 355,
            marginLeft: 7,
            fontStyle: 'italic',
        }),
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon" />
                }
            </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => <span style={{}} {...innerProps} />;

    const [isModalCancelVisible, setModalCancelVisible] = useState(false);

    const isZipCodeValid = (zipCode) => {
        const normalizedZipCode = zipCode && zipCode.replace(/\D/g, '')
        if (!normalizedZipCode || !/^[0-9]{8}$/.test(normalizedZipCode)) {
            return false
        }
        return true
    }

    const isAllFieldsValidated = () => {
        if (!defaultFormValues?.unitName?.trim()) {
            setShowErroUnitName(true);
            return false;
        }
        if (!(defaultFormValues?.zipcode && defaultFormValues?.zipcode?.trim())) {
            setShowErroZipcode(true);
            return false;
        }
        if (!isZipCodeValid(defaultFormValues?.zipcode)) {
            setShowInvalidErroZipcode(true);
            return false;
        }
        if (!(defaultFormValues?.state)) {
            setShowErroState(true);
            return false;
        }
        if (!(defaultFormValues?.street && defaultFormValues?.street?.trim())) {
            setShowErroStreet(true);
            return false;
        }
        if (!(defaultFormValues?.neighborhood && defaultFormValues?.neighborhood?.trim())) {
            setShowErroNeighborhood(true);
            return false;
        }
        if (!(defaultFormValues?.city && defaultFormValues?.city?.trim())) {
            setShowErroCity(true);
            return false;
        }
                        
        return true;
    }
    // ================================================================================

    return (
        <>
                <>
                    <ModalCancel
                        id="modalcancelalert"
                        appear={isModalCancelVisible}
                        text={"Tem certeza que deseja cancelar a operação?"}
                        actionButton1={() => setModalCancelVisible(false)}
                        actionButton2={() => props.history.push(props.buttonCancelRoute)}
                    />

                    <ModalWarn
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text={props.messageError}
                        textButton2='Ok'
                        actionButton2={() => props.setModalError(false)}
                    />

                    <GlobalContainer>
                        <form onSubmit={handleSubmit(() => { if (isAllFieldsValidated()) props.onSubmitData(defaultFormValues); } ) }>
                            <ContentContainer>
                                <ContentContainer1>
                                    <TitleContainer>
                                        <Title> {props.pageTitle} </Title>
                                    </TitleContainer>
                                    <ContentContainer11>
                                        <div className="br-input">
                                            <label htmlFor="unitName">Nome da unidade<Mandatory>*</Mandatory></label>
                                            <input
                                                id="unitName-input"
                                                name="unitName"
                                                type="text"
                                                data-input="data-input"
                                                placeholder="Informe nome que facilite a identificação da unidade pelo cidadão"
                                                defaultValue={props.unit.unitName}
                                                onInput={e => { setDefaultFormValues({ ...defaultFormValues, unitName: e.target.value }); props.setUnit({ ...props.unit, unitName: e.target.value}); setShowErroUnitName(false); }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                style={{
                                                    "padding": "0px 7px 0px",
                                                    "fontSize": "14px",
                                                    "fontWeight": "normal",
                                                    "color": "#333333"
                                                }}
                                            />
                                            {showErroUnitName &&
                                                <span className="feedback danger" role="alert">
                                                    <i className="fas fa-times-circle" aria-hidden="true">
                                                        <img
                                                            src={FailIcon}
                                                            style={{ paddingBottom: '5px' }}
                                                            alt="failicon"
                                                        />
                                                    </i>
                                                    Este campo é obrigatório.
                                                </span>}
                                        </div>
                                    </ContentContainer11>
                                    
                                    <label>Endereço <span style={{"fontSize": "80%", "fontWeight": "normal"}}>(Informe o endereço completo para facilitar a identificação da unidade pelo cidadão)</span></label>
                                    <ContentContainer12>
                                        <ContentContainer13>
                                            <ContentContainer14>
                                                <div className="br-input">
                                                    <div>
                                                        <label htmlFor="zipcode">CEP<Mandatory>*</Mandatory></label>
                                                        <a style={{"paddingLeft": "10px", "fontSize": "70%", "fontWeight": "700"}} href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank" rel="noopener noreferrer">Não sei meu CEP</a>
                                                    </div>                                                    
                                                    <input
                                                        id="zipcode-input"
                                                        name="zipcode"
                                                        type="text"
                                                        data-input="data-input"
                                                        placeholder="Digite o CEP"
                                                        defaultValue={formatarCEP(props.unit.zipcode)}
                                                        value={formatarCEP(defaultFormValues.zipcode)}
                                                        onInput={e => { setDefaultFormValues({ ...defaultFormValues, zipcode: e.target.value }); setShowErroZipcode(false); setShowInvalidErroZipcode(false); if (isZipCodeValid(e.target.value)) { props.pesquisaCEP(e.target.value)} }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                        style={{
                                                            "padding": "0px 7px 0px",
                                                            "fontSize": "14px",
                                                            "fontWeight": "normal",
                                                            "color": "#333333"
                                                        }}
                                                    />
                                                    {showErroZipcode &&
                                                        <span className="feedback danger" role="alert">
                                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                                <img
                                                                    src={FailIcon}
                                                                    style={{ paddingBottom: '5px' }}
                                                                    alt="failicon"
                                                                />
                                                            </i>
                                                            Este campo é obrigatório.
                                                        </span>}
                                                    {showInvalidErroZipcode &&
                                                        <span className="feedback danger" role="alert">
                                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                                <img
                                                                    src={FailIcon}
                                                                    style={{ paddingBottom: '5px' }}
                                                                    alt="failicon"
                                                                />
                                                            </i>
                                                            CEP Inválido
                                                        </span>}
                                                </div>
                                            </ContentContainer14>
                                            <ContentContainer15>
                                                <ContentContainerBlock>
                                                    <div className="br-input" style={{"width": "80%"}}>
                                                        <label htmlFor="street">Logradouro (Rua/Avenida)<Mandatory>*</Mandatory></label>
                                                        <input
                                                            id="street-input"
                                                            name="street"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Rua/Avenida"
                                                            defaultValue={props.unit.street}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, street: e.target.value }); setShowErroStreet(false)}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                        {showErroStreet &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                Este campo é obrigatório.
                                                            </span>}
                                                    </div>
                                                    <div className="br-input" style={{"paddingLeft": "20px","width": "20%"}}>
                                                        <label htmlFor="number">Número</label>
                                                        <input
                                                            id="number-input"
                                                            name="number"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Número"
                                                            defaultValue={props.unit.number}
                                                            onInput={e => {setDefaultFormValues({ ...defaultFormValues, number: e.target.value });}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                    </div>
                                                </ContentContainerBlock>                                                
                                            </ContentContainer15>
                                            <ContentContainer15>
                                                <ContentContainerBlock>
                                                    <div className="br-input" style={{"width": "70%"}}>
                                                        <label htmlFor="addressComplement">Complemento</label>
                                                        <input
                                                            id="addressComplement-input"
                                                            name="addressComplement"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Complemento"
                                                            defaultValue={props.unit.addressComplement}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, addressComplement: e.target.value }); }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="br-input" style={{"paddingLeft": "20px","width": "40%"}}>
                                                        <label htmlFor="neighborhood">Bairro<Mandatory>*</Mandatory></label>
                                                        <input
                                                            id="neighborhood-input"
                                                            name="neighborhood"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Bairro"
                                                            defaultValue={props.unit.neighborhood}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, neighborhood: e.target.value }); setShowErroNeighborhood(false);}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                        {showErroNeighborhood &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                Este campo é obrigatório.
                                                            </span>}
                                                    </div>
                                                </ContentContainerBlock>                                                
                                            </ContentContainer15>
                                            <ContentContainer15>
                                                <ContentContainerBlock>
                                                    <div className="br-input" style={{"width": "70%", "marginRight": "20px"}}>
                                                        <label htmlFor="city">Cidade<Mandatory>*</Mandatory></label>
                                                        <input
                                                            id="city-input"
                                                            name="city"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Cidade"
                                                            defaultValue={props.unit.city}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, city: e.target.value }); setShowErroCity(false)}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                        {showErroCity &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                Este campo é obrigatório.
                                                            </span>}
                                                    </div>
                                                    <SelectContainer>
                                                        <label>Estado<Mandatory>*</Mandatory></label>
                                                        <Select
                                                            isDisabled={true}
                                                            onInput={(value) => {
                                                                setDefaultFormValues({ ...defaultFormValues, state: { value: value.value, label: value.label } }); //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                                if (value) {
                                                                    setShowErroState(false); //Limpa erro
                                                                }
                                                            }}
                                                            options={props.stateOptions}
                                                            value = {defaultFormValues.state}
                                                            placeholder="Selecione o estado"
                                                            styles={SelecStyles}
                                                            theme={theme => ({
                                                                ...theme,
                                                                borderRadius: 6,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#CCCCCC',
                                                                    primary: '#1351B4'
                                                                },
                                                            })}
                                                            components={
                                                                {
                                                                    DropdownIndicator,
                                                                    IndicatorSeparator
                                                                }
                                                            }
                                                        />
                                                    </SelectContainer>
                                                    
                                                    {showErroState &&
                                                        <span className="feedback danger" role="alert" id="cpfoperator">
                                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                                <img
                                                                    src={FailIcon}
                                                                    style={{ paddingBottom: '5px' }}
                                                                    alt="failicon"
                                                                />
                                                            </i>
                                                            Este campo é obrigatório.
                                                        </span>}
                                                </ContentContainerBlock>                                                
                                            </ContentContainer15>                     
                                        </ContentContainer13>
                                    </ContentContainer12>
                                    <span style={{"fontSize": "80%"}}>São obrigatórios os campos marcados com<Mandatory>*</Mandatory></span>
                                </ContentContainer1>
                            </ContentContainer>

                            <ContentContainer>
                                <ContentContainer1>

                                </ContentContainer1>

                                <ContentContainer2>
                                    <ContentContainerButtons>
                                        <button className="br-button" type="button"
                                            onClick={() => setModalCancelVisible(true)}>
                                            Voltar
                                        </button>

                                        {!props.loadingAdd ?
                                            <button className="br-button primary" type="submit">
                                                Confirmar
                                            </button>
                                            :
                                            <button className="br-button primary loading" type="button">
                                                Confirmar
                                            </button>
                                        }
                                    </ContentContainerButtons>
                                </ContentContainer2>
                            </ContentContainer>
                        </form>
                    </GlobalContainer>
                </>            
        </>
    );
}