import React, { useState } from 'react';
import { useLocation } from "react-router-dom";

import { GlobalContainer,
    ContentContainer,
    ContentContainer1,
    ContentContainer11,
    ContentContainer2,
    ContentContainer3,
    ContentContainer4,
    ContentContainerTable,
    ContentContainerDown,
    PaginationContainer,
    SelectPagesContainer,
    Subtitle,
    Title,
    Text,
    TitleContainer,
    ContentContainerButtons } from './styles';

import ModalSuccess from '../Messages/Success/index';
import { cpfMask, formatDate } from '../../utils';
import Select, { components } from 'react-select';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import DropLeftIcon from '../../assets/drop_left_icon.png';
import DropRightIcon from '../../assets/drop_right_icon.png';
import { profile } from '../../constants';

export default function ShowOperator(props) {

    let location = useLocation();

    const perfil = JSON.parse(localStorage.getItem("user")).perfil;
    const [rangePagesOptions] = useState([
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
    ]);
    const SelecPageStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: 0,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 15,
        }),
    }
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen 
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon"/>
                }
            </components.DropdownIndicator>
        );
    };    
    const IndicatorSeparator = ({ innerProps }) => {
        return <span style={{}} {...innerProps} />;
    };

    return (
        <>
            {!props.loadOperator ?
                <div style={{'marginTop': '75px'}}><h1> Error on the page! </h1></div>
            :
                <>
                    <GlobalContainer>
                        {props.fromSuccessMsg &&
                            <ModalSuccess text={props.fromSuccessMsg}/>
                        }
                        <TitleContainer>
                            <Title> {props.pageTitle} </Title>
                        </TitleContainer>

                        <ContentContainer>
                            <ContentContainer1>
                                <ContentContainer11>
                                    <label> CPF </label>
                                    <Text> 
                                        { cpfMask(props.operator.cpf) }
                                    </Text>
                                </ContentContainer11>

                                <ContentContainer11>
                                    <label> Nome </label>
                                    <Text> 
                                        {props.operator.name}
                                    </Text>
                                </ContentContainer11>

                                {props.operator.cpf_cadastrador && 
                                    <>
                                        <ContentContainer11>
                                            <label> CPF do gestor regional </label>
                                            <Text> 
                                                { cpfMask(props.operator.cpf_cadastrador)}
                                            </Text>
                                        </ContentContainer11>
                                                                    

                                        <ContentContainer11>
                                            <label> Nome do gestor regional </label>
                                            <Text> 
                                                {props.operator.nome_cadastrador}
                                            </Text>
                                        </ContentContainer11>
                                    </>
                                }
                            </ContentContainer1>

                            <ContentContainer2>

                            </ContentContainer2>
                        </ContentContainer>

                        <ContentContainer3>
                            <ContentContainerTable>
                                <div className="br-table" data-search="data-search" data-selection="data-selection">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col0" style={{'color': '#1351B4'}}></th>
                                                <th scope="col1" style={{'color': '#1351B4'}}> Órgão </th>
                                                {(perfil !== profile.gestorSistema) &&
                                                <th scope="col2" style={{'color': '#1351B4'}}> Unidade do Órgão </th>
                                                }                                                
                                                <th scope="col3" style={{'color': '#1351B4'}}> Data de Cadastro </th>
                                                <th scope="col4" style={{'color': '#1351B4'}}> Data de Expiração </th>
                                                <th scope="col99" style={{'color': '#1351B4'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> </td>
                                                <td> {props.operator.organ.label} </td>
                                                {(perfil !== profile.gestorSistema) &&
                                                <td> {props.operator.unidade ? props.operator.unidade.label : '-'} </td>
                                                }
                                                <td> { formatDate(props.operator.dt_inc) } </td>
                                                <td> {props.operator.dt_canc_exp === null ? '-' : formatDate(props.operator.dt_canc_exp) } </td>                                                
                                                <td> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ContentContainerTable>
                        </ContentContainer3>


                        {!!(props.searchResult && props.searchResult.length) && <>
                            <ContentContainer4>
                                <Subtitle>
                                    <label>{props.tableResultListTitle}</label>
                                </Subtitle>
                                <div className="br-table" data-search="data-search" data-selection="data-selection">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col0" style={{ 'color': '#1351B4' }}></th>
                                                <th scope="col1" style={{ 'color': '#1351B4' }}> Nome completo </th>
                                                <th scope="col2" style={{ 'color': '#1351B4' }}> CPF </th>
                                                <th scope="col3" style={{ 'color': '#1351B4' }}> Órgão </th>
                                                <th scope="col3" style={{ 'color': '#1351B4' }}> Unidade do Órgão </th>
                                                <th scope="col4" style={{ 'color': '#1351B4' }}> Data de Cadastro </th>
                                                <th scope="col5" style={{ 'color': '#1351B4' }}> Data de Expiração </th>
                                                <th scope="col99" style={{ 'color': '#1351B4' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.searchResult.map(result => (
                                                <tr key={result.ID_USUARIO_ORGAO}>
                                                    <td> </td>
                                                    <td> {result.NOME_USUARIO} </td>
                                                    <td> { cpfMask(result.CPF_USUARIO) }</td>
                                                    <td> {result.NOME_ORGAO} </td>
                                                    <td> {result.NOME_UNIDADE} </td>
                                                    <td> { formatDate(result.DATA_INCLUSAO) } </td>
                                                    <td> {result.DATA_CANCELAMENTO === null ? "-" : formatDate(result.DATA_CANCELAMENTO) } </td>
                                                    <td> </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                <PaginationContainer>
                                    <nav className="br-pagination" aria-label="Paginação de resultados" data-total="50" data-current="1" data-per-page="20">
                                        <div className="pagination-per-page">
                                            <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Exibir </label>
                                            <SelectPagesContainer>
                                                <Select
                                                    options={rangePagesOptions}
                                                    defaultValue={{ value: "10", label: "10" }}
                                                    value={props.rangePages}
                                                    onChange={value => {
                                                        props.setRangePages(value)
                                                        props.onChangePaginationConfig(value, props.page, true)
                                                    }}
                                                    styles={SelecPageStyles}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 6,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#CCCCCC',
                                                            primary: '#1351B4'
                                                        },
                                                    })}
                                                    components={
                                                        {
                                                            DropdownIndicator,
                                                            IndicatorSeparator
                                                        }
                                                    }
                                                />
                                            </SelectPagesContainer>
                                        </div>

                                        <span className="br-divider d-none d-sm-block mx-3"></span>

                                        <div className="pagination-information d-none d-sm-flex">
                                            <span className="current"> {1 + ((Number(props.page.value) - 1) * Number(props.rangePages.value))} </span>&ndash;<span className="per-page"> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className="total"> {props.totalFilter} </span>&nbsp;itens
                                        </div>
                                        <div className="pagination-go-to-page d-none d-sm-flex ml-auto">
                                            <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Página </label>
                                            <SelectPagesContainer>
                                                <Select
                                                    options={props.pageOptions}
                                                    defaultValue={{ value: "1", label: "1" }}
                                                    value={props.page}
                                                    onChange={value => {
                                                        props.setPage(value)
                                                        props.onChangePaginationConfig(props.rangePages, value, false)
                                                    }}
                                                    styles={SelecPageStyles}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 6,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#CCCCCC',
                                                            primary: '#1351B4'
                                                        },
                                                    })}
                                                    components={
                                                        {
                                                            DropdownIndicator,
                                                            IndicatorSeparator
                                                        }
                                                    }
                                                />
                                            </SelectPagesContainer>
                                        </div>

                                        <span className="br-divider d-none d-sm-block mx-3"></span>

                                        <div className="pagination-arrows ml-auto ml-sm-0">
                                            <button className="br-button circle" type="button" aria-label="Voltar página"
                                                style={{
                                                    'opacity': Number(props.page.value) > 1 ? '' : '0.4',
                                                    'pointerEvents': Number(props.page.value) > 1 ? '' : 'none'
                                                }}
                                                onClick={() => {
                                                    const new_page = {
                                                        value: String(Number(props.page.value) - 1),
                                                        label: String(Number(props.page.value) - 1)
                                                    }
                                                    props.setPage(new_page)
                                                    props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                }}
                                            >
                                                <i className="fas fa-angle-left" aria-hidden="true">
                                                    <img
                                                        src={DropLeftIcon}
                                                        alt="leftIcon"
                                                        style={{ "marginBottom": "6px" }}
                                                    />
                                                </i>
                                            </button>
                                            <button className="br-button circle" type="button" aria-label="Avançar página"
                                                style={{
                                                    'opacity': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : '0.4',
                                                    'pointerEvents': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : 'none'
                                                }}
                                                onClick={() => {
                                                    const new_page = {
                                                        value: String(Number(props.page.value) + 1),
                                                        label: String(Number(props.page.value) + 1)
                                                    }
                                                    props.setPage(new_page)
                                                    props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                }}
                                            >
                                                <i className="fas fa-angle-right" aria-hidden="true">
                                                    <img
                                                        src={DropRightIcon}
                                                        alt="rightIcon"
                                                        style={{ "marginBottom": "6px" }}
                                                    />
                                                </i>
                                            </button>
                                        </div>
                                    </nav>
                                </PaginationContainer>
                            </ContentContainer4>
                        </>}

                        <ContentContainerDown>
                            <ContentContainerButtons>
                                <button className="br-button" type="button"
                                        onClick={() => props.history.push(props.buttonBackRoute)}>
                                    Voltar
                                </button>

                                {((perfil === profile.gestorOrgao && location.pathname === '/detalharGestorRegional')
                                    || (perfil === profile.gestorRegional && location.pathname === '/detalharatendente')) &&
                                    <button className="br-button secondary" type="button"
                                            onClick={() => {
                                                const data = {
                                                    result_search: {
                                                        CPF_USUARIO: props.operator.cpf,
                                                        ID_USUARIO_ORGAO: props.operator.id_usuario_orgao,
                                                        NOME_USUARIO: props.operator.name,
                                                        CODIGO_ORGAO: props.operator.organ.value,
                                                        NOME_ORGAO: props.operator.organ.label,
                                                        CODIGO_UNIDADE: (props.operator.unidade ? props.operator.unidade.value : undefined),
                                                        NOME_UNIDADE: (props.operator.unidade ? props.operator.unidade.label : undefined),
                                                        DATA_CANCELAMENTO: props.operator.dt_canc_exp
                                                    },
                                                    fields_search: {
                                                        cpf: '',
                                                        name: '',
                                                        organ: { 
                                                            value: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).codigo_orgao : "", 
                                                            label: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).orgao : "Selecione o órgão"
                                                        }
                                                    },
                                                    pagination: {
                                                        range_page: {label: "10", value: "10"},
                                                        page: {label: "1", value: "1"},
                                                        page_options: [],
                                                        total: "0"
                                                    },
                                                    list: []
                                                }
                                                props.history.push(props.buttonEditRoute, data);
                                            }}>
                                        Editar
                                    </button>
                                }

                                {props.buttonRegistrationTitle &&
                                    <button className="br-button primary" type="button"
                                            onClick={() => props.history.push(props.buttonRegistrationRoute)}>
                                        {props.buttonRegistrationTitle}
                                    </button>
                                }
                            </ContentContainerButtons>
                        </ContentContainerDown>
                    </GlobalContainer>
                </>
            }
        </>
    );
}