import styled from 'styled-components';

export const FooterDown = styled.div `
    position: ${ props => props.scroll ? 'relative' : 'fixed'};
    width: 100%;
    bottom: 0px;

    //@media (max-width: 1000px) {
    //    position: relative;
    //}

    @media print {
        div {
            display: none;
        }        
    }
`;

export const FooterDiv = styled.div `
    background-color: #071D41;

    padding: 15px;

    #divInt {
        display: flex;
        justify-content: center;
        max-width: 1450px;
        margin: 0px auto;

        justify-content: right;
    }

    #img1 {
        width: 100px;
        height: 50px;

        margin-left: auto;
        margin-right: 20px;
    }

    #img2 {
        width: 150px;
        height: 50px;

        padding: 5px;

        margin-right: 50px;
    }
`;