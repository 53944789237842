import styled from 'styled-components';

export const Title = styled.h1 `
    width: 790px;

    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
        margin-right: 30px;
    }
`;

export const Text = styled.label `
    font-size: 14px;
    font-weight: normal;
    color: #333333;
    @media (max-width: 1000px) {
        width: 410px;
    }
`;

export const GlobalContainer = styled.div `

    margin-top: 80px;
    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const TitleContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
    justify-content: center;
`;

export const ContentContainer = styled.div `
    display: flex;
    justify-content: center;
    max-width: 790;
    margin: 0px auto;
`;

export const ContentContainerBlock2 = styled.div `
    display: flex;
    flex-direction: column;
`;


export const ContentContainer1 = styled.div `
    width: 790px;
    padding: 0px 0px 15px;
`;

export const ContentContainer11 = styled.div `
    display: grid;
    margin-bottom: 20px;
`;

export const ContentContainer12 = styled.div `
    display: grid;
    margin-bottom: 20px;
`;

export const ContentContainer2 = styled.div `
    display: flex;
    justify-content: center;
    max-width: 790;
    margin: 0px auto;
`;

export const ContentContainerButtons = styled.div `
    padding: 20px 0px 20px;
    text-align: right;

    button:nth-child(2){ /*-pegando o segundo button desta div-*/
        margin-left: 10px;
    }
`;

export const ContentContainerTable = styled.div `
    width: 790px;
    margin-bottom: 20px;

    @media (max-width: 1000px) {
        width: 410px;
    }
`;

export const Textarea = styled.textarea `
    width: 790px;
    height: 100px;
    @media (max-width: 1000px) {
        width: 410px;
    }
`;

export const ContainerLoad = styled.div `
    
    position: absolute;
    z-index: 9999;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    #load {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -44px;
        margin-left: -22px;
    }

`;