import React, { useState } from 'react';

import { GlobalContainer,
    ContentContainerBlock,
    ContentContainer,
    ContentContainer1,
    ContentContainer11,
    ContentContainer12,
    ContentContainer13,
    ContentContainer14,
    ContentContainer2,
    Title,
    Text,
    TitleContainer,
    ContentContainerButtons,
    SelectContainer,
    AlertContainer,
    ContentContainerButtonsWithMarginLeft } from './styles';
import Select, { components } from 'react-select';

import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import FailIcon from '../../assets/fail_icon.png';
import AlertIcon from '../../assets/alert_icon2.png';
import ModalCancel from '../Modal/Cancel/index';
import ModalWarn from '../Modal/Warn/index';

import { useForm, Controller } from 'react-hook-form';
import { cpfMask } from '../../utils';
import { profile } from '../../constants';
import ModalFormManterOrgao from '../Modal/Form/ModalFormManterOrgao';

export default function EditOperator(props) {

    const { handleSubmit, errors, control } = useForm();
    const [isModalCadastrarOrgaoVisible, setModalCadastrarOrgaoVisible] = useState(false);

    // ESTILIZACAO PARA O SELECT ======================================================
    const SelecStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: state.isFocused ? 2.5 : 1,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 6,
        }), 
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: 13.5,
            fontWeight: 355,
            marginLeft: 7,
            fontStyle: 'italic',
        }),
    }

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen ?
                <img
                    src={DropUpIcon}
                    alt="upicon"
                /> :
                <img
                    src={DropDownIcon}
                    alt="downicon"
                />
            }
          </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => {
        return <span style={{}} {...innerProps} />;
    };
     // ================================================================================

    const [ isModalCancelVisible, setModalCancelVisible ] = useState(false);
    
    const user = JSON.parse(localStorage.getItem('user'));
    const perfil = user.perfil;

    return (
        <>
            {!props.loadOperator ?
                <div style={{'marginTop': '75px'}}><h1> Error on the page! </h1></div>
            :
                <>
                    <ModalFormManterOrgao
                        id="modalcadastrarorgao"
                        appear={isModalCadastrarOrgaoVisible}
                        actionButton1={() => setModalCadastrarOrgaoVisible(false)}
                        textButton1="Cancelar"
                        textButton2="Cadastrar"
                    />

                    <ModalCancel
                        id="modalcancelalert"
                        appear={isModalCancelVisible}
                        text={"Tem certeza que deseja cancelar a edição?"}
                        actionButton1={() => setModalCancelVisible(false)}
                        actionButton2={() => props.history.push(props.buttonCancelRoute, props.location.state)}
                    />

                    <ModalWarn
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text = {props.messageError}
                        textButton2='Ok'
                        actionButton2={() => props.setModalError(false)}
                    />

                    <GlobalContainer>
                        <TitleContainer>
                            <Title> {props.pageTitle} </Title>
                        </TitleContainer>
                        
                        <form onSubmit={handleSubmit(props.onSubmitEdit)}>
                            <ContentContainer>
                                <ContentContainer1>
                                    <ContentContainer11>
                                        <label> CPF </label>
                                        <Text> { cpfMask(props.operator.cpf) } </Text>
                                    </ContentContainer11>

                                    <ContentContainer12>
                                        <label> Nome </label>
                                        <Text> {props.operator.name} </Text>
                                    </ContentContainer12>

                                    <ContentContainerBlock>
                                        <ContentContainer13>
                                            {((perfil !== profile.gestorSistema) || ((perfil === profile.gestorSistema) && (props.hasAssociated)) ) && 
                                                <>
                                                    <label> Órgão </label>
                                                    <p> {props.organ.label} </p>
                                                </>
                                            }
                                            {((perfil === profile.gestorSistema) && (!props.hasAssociated)) &&
                                                <SelectContainer>
                                                    <label> Órgão </label>
                                                    <Controller
                                                        name="organ"
                                                        onChange={(e) => props.setOrgan(e.value)}
                                                        control={control}
                                                        options={props.organOptions}
                                                        render={
                                                            (e) => (
                                                                <Select
                                                                    onChange={(value) => {
                                                                        e.onChange(value);
                                                                        props.setOrgan({ value: value.value, label: value.label });
                                                                    }}
                                                                    options={props.organOptions}
                                                                    defaultValue={(props.organ && props.organ.value === "") ? "" : props.organ} 
                                                                    value={(props.organ && props.organ.value === "") ? "" : props.organ}
                                                                    placeholder="Selecione o órgão"
                                                                    styles={SelecStyles}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        borderRadius: 6,
                                                                        colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#CCCCCC',
                                                                        primary: '#1351B4'
                                                                        },
                                                                    })}
                                                                    components={
                                                                        { DropdownIndicator,
                                                                        IndicatorSeparator }
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        rules={{
                                                            validate: () => props.organ.value !== "" ? true : "Selecione um órgão."
                                                        }}
                                                    />
                                                </SelectContainer>
                                            }
                                            {(props.hasAssociated && props.msgAgencyChangeForbiden) && 
                                                <AlertContainer>
                                                    <span className="feedback warning" role="alert" id="cpfoperator">
                                                        <i className="fas fa-exclamation-circle" aria-hidden="true">
                                                            <img
                                                                src={AlertIcon}
                                                                style = {{ paddingBottom: '5px' }}
                                                                alt="alerticon"
                                                            />
                                                        </i>
                                                        {props.msgAgencyChangeForbiden}
                                                    </span>
                                                </AlertContainer>
                                            }
                                            {errors?.organ && 
                                            <span className="feedback danger" role="alert">
                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                    <img
                                                        src={FailIcon}
                                                        style = {{ paddingBottom: '5px' }}
                                                        alt="failicon"
                                                    />
                                                </i>
                                                {errors.organ.message}
                                            </span>}
                                        </ContentContainer13>
                                        { (perfil === profile.gestorSistema && !props.hasAssociated) &&
                                            <ContentContainerButtonsWithMarginLeft>
                                                <button className="br-button primary" type="button"
                                                        onClick={() => setModalCadastrarOrgaoVisible(true)}>
                                                    Cadastrar órgãos
                                                </button>
                                            </ContentContainerButtonsWithMarginLeft>
                                        }
                                    </ContentContainerBlock>

                                    {(perfil !== profile.gestorSistema) &&
                                        <ContentContainerBlock>
                                            <ContentContainer14>
                                                {(perfil === profile.gestorRegional || props.hasAssociated) && 
                                                    <>
                                                        <label> Unidade do Órgão </label>
                                                        <p> {props.unidade.label} </p>
                                                    </>
                                                }
                                                {((perfil === profile.gestorOrgao) && (!props.hasAssociated)) &&
                                                    <SelectContainer>
                                                        <label> Unidade do Órgão </label>
                                                            <Controller
                                                                name="unidade"
                                                                onChange={(e) => props.setUnidade(e.value)}
                                                                control={control}
                                                                render={
                                                                    (e) => (
                                                                        <Select
                                                                            onChange={(value) => {
                                                                                e.onChange(value);
                                                                                props.setUnidade({value: value.value, label: value.label})
                                                                            }}
                                                                            options={props.unidadeOptions}
                                                                            placeholder="Selecione uma unidade"
                                                                            value={props.unidade}
                                                                            styles={SelecStyles}
                                                                            theme={theme => ({
                                                                                ...theme,
                                                                                borderRadius: 6,
                                                                                colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#CCCCCC',
                                                                                primary: '#1351B4'
                                                                                },
                                                                            })}
                                                                            components={
                                                                                { DropdownIndicator,
                                                                                IndicatorSeparator }
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                                rules={{
                                                                    validate: () => props.unidade.value ? true : "Selecione uma unidade."
                                                                }}
                                                            />  
                                                    </SelectContainer>
                                                }
                                                {(props.hasAssociated && props.msgUnidadeChangeForbiden) && 
                                                    <AlertContainer>
                                                        <span className="feedback warning" role="alert" id="cpfoperator">
                                                            <i className="fas fa-exclamation-circle" aria-hidden="true">
                                                                <img
                                                                    src={AlertIcon}
                                                                    style = {{ paddingBottom: '5px' }}
                                                                    alt="alerticon"
                                                                />
                                                            </i>
                                                            {props.msgUnidadeChangeForbiden}
                                                        </span>
                                                    </AlertContainer>
                                                }
                                                {errors?.unidade && 
                                                    <span className="feedback danger" role="alert" id="cpfoperator">
                                                        <i className="fas fa-times-circle" aria-hidden="true">
                                                            <img
                                                                src={FailIcon}
                                                                style = {{ paddingBottom: '5px' }}
                                                                alt="failicon"
                                                            />
                                                        </i>
                                                        {errors.unidade.message}
                                                    </span>}
                                            </ContentContainer14>
                                        </ContentContainerBlock>
                                    }
                                    <ContentContainer14>
                                        <div className="br-input">
                                            <label htmlFor="dt_canc_exp">Data de Expiração</label>
                                            <input
                                                id="dateandtime-input"
                                                name="dt_canc_exp" 
                                                type="date"
                                                data-input="data-input" 
                                                placeholder="dd/mm/aaaa"
                                                defaultValue={props.operator.dt_canc_exp}
                                                onChange={e => props.setOperator({...props.operator, dt_canc_exp: e.target.value})}
                                                style={{ 
                                                    "padding": "0px 7px 0px",
                                                    "fontSize": "14px",
                                                    "fontWeight": "normal",
                                                    "color": "#333333" 
                                                }}
                                            />
                                        </div>
                                    </ContentContainer14>    
                                </ContentContainer1>
                            </ContentContainer>

                            <ContentContainer>
                                <ContentContainer1>

                                </ContentContainer1>

                                <ContentContainer2>
                                    <ContentContainerButtons>
                                        <button className="br-button" type="button"
                                                onClick={() => setModalCancelVisible(true)}>
                                            Voltar
                                        </button>
                                        
                                        {!props.loadingEdit ? 
                                            <button className="br-button primary" type="submit">
                                                Confirmar
                                            </button>
                                        :
                                            <button className="br-button primary loading" type="button">
                                                Confirmar
                                            </button>
                                        }
                                    </ContentContainerButtons>
                                </ContentContainer2>
                            </ContentContainer>
                        </form>
                    </GlobalContainer>
                </>
            }
        </>
    );
}