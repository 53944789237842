import React, { useState, useEffect } from 'react';

import AddOperator from '../../Components/AddOperator'

import ModalWarn from '../../Components/Modal/Warn/index'

export default function AddAttendant(props) {

    const [ attendant, setAttendant ] = useState({
        cpf: '',
        name: '',
        dt_canc_exp: '',
        ident_dig: ''
    });
    const [ loadAttendant, setLoadAttendant ] = useState(false);

    const [ organOptions, setOrganOptions ] = useState([
        /*
        { value: "1", label: "INSS" },
        { value: "2", label: "Ministério do Trabalho" },
        { value: "3", label: "Receita Federal" },
        */
    ]);

    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(()=>{
        async function loadOrgans(){
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : ""
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaOrgaoGestorRegional`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        var organList = [];
                        var obj = {};
    
                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO
                            }
    
                            organList.push(obj);
                        });
    
                        setOrganOptions(organList);
                    });
                } else {
                    console.log('Error request to /gestao/consultaorgaogestor');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        if (!loadAttendant && props.location.state !== undefined) {
            setAttendant({
                cpf: props.location.state.cpf,
                name: props.location.state.name,
                dt_canc_exp: props.location.state.dt_canc_exp,
                ident_dig: props.location.state.ident_dig
            });

            loadOrgans();
            setLoadAttendant(true);
        }
    }, [props.location.state, loadAttendant]);

    const [ loadingAdd, setLoadingAdd ] = useState(false);    

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

     // ================================================================================

    const onSubmitAttendantData = async (data) => {

        //console.log(new_attendant);
        setLoadingAdd(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",
                cpf_usuario_atendente: attendant.cpf,
                nome_usuario: attendant.name,
                codigo_orgao: data.organ.value,
                codigo_unidade: data.unidade.value,
                data_cancelamento: attendant.dt_canc_exp
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/cadastraatendente`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    const attendant_added = {
                        from: "addAttendant",
                        id_usuario_orgao: data_request.objAtendente.id_usuario_orgao,
                        cpf: data_request.objAtendente.cpf_usuario_atendente,
                        name: data_request.objAtendente.nome_usuario,
                        organ: data.organ,
                        unidade: data.unidade,
                        dt_inc: data_request.objAtendente.data_inclusao,
                        dt_canc_exp: data_request.objAtendente.data_cancelamento
                    }
                    setLoadingAdd(false);
                    
                    //console.log(attendant_added);
                    props.history.push('mostraratendente', attendant_added);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/cadastraatendente BackendBalcao');
                    }

                    setLoadingAdd(false);
                });
            } 
            else 
            if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingAdd(false);
                  setModalInvalidSessionError(true)
                })
            }
            else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingAdd(false);
                console.log('Error request to /gestao/cadastraatendente');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <AddOperator
                pageTitle='Cadastrar Atendente'
                onSubmitData={onSubmitAttendantData}
                loadOperator={loadAttendant}
                operator={attendant}
                setOperator={setAttendant}
                organOptions={organOptions}
                loadingAdd={loadingAdd}
                setLoadingAdd={setLoadingAdd}
                modalError ={modalError}
                setModalError={setModalError}
                messageError={messageError}
                buttonCancelRoute='/consultaratendente'
                history={props.history}
            />
        </>
    );
}