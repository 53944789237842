import React from 'react';

import { ModalCenterFont } from './styles'
//import '../../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

function ModalCancel(props) {

    return(
        <ModalCenterFont appear={props.appear}>
            <div className="br-modal is-medium" id="modal">
                <div className="br-card">
                    <div className="container-fluid p-4">
                        <div className="br-modal-body"></div>
                        <p>
                            {props.text}
                        </p>
                        <div className="br-modal-footer justify-content-end">
                            <button className="br-button secondary small m-2" type="button" onClick={props.actionButton1}>
                                Não
                            </button>

                            <button className="br-button primary small m-2" type="button" onClick={props.actionButton2}>
                                Sim
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalCenterFont>
    );
}

export default ModalCancel;