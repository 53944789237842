import React from 'react';

//import '../../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';
import iconImg from '../../../assets/success_icon.png';
import iconCloseSuccessImg from '../../../assets/close_success_icon.png';

function MessageSuccess(props) {
    return(
        <div id="messageSuccess" className="br-message success" role="alert">
            <div className="icon" aria-label="informações corretas">
                <i className="fas fa-check-circle fa-lg" aria-hidden="true"></i>
                <img 
                    src={iconImg}
                    alt="alertIcon"
                />
            </div>
            <div className="content">
                <span>
                    {props.text}
                </span>
            </div>
            <div className="close">
                <button className="br-button circle small" type="button" aria-label="Fechar"
                        onClick={ () => {document.getElementById("messageSuccess").style.display = "none"} }
                >
                    <i className="fas fa-times" aria-hidden="true">
                        <img 
                            src={iconCloseSuccessImg}
                            alt="alertIcon"
                        />
                    </i>
                </button>
            </div>
        </div>
    );
}

export default MessageSuccess;