import React from 'react';
import { useForm } from 'react-hook-form';

import { GlobalContainer,
    ContentContainer,
    ContentContainer1,
    ContentContainer11,
    ContentContainer2,
    ContentContainerDown,
    Subtitle,
    Title,
    Text,
    TitleContainer,
    ContentContainerButtons } from './styles';

import ModalSuccess from '../Messages/Success/index';
import { cpfMask } from '../../utils';
import ModalWarn from '../Modal/Warn/index';

export default function ReplaceOperator(props) {
    const { handleSubmit } = useForm();

    return (
        <>
            {!props.loadOperator ?
                <div style={{'marginTop': '75px'}}><h1> Error on the page! </h1></div>
            :
                <>
                    <ModalWarn
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text = {props.messageError}
                        textButton2='Ok'
                        actionButton2={() => props.setModalError(false)}
                    />
                    <GlobalContainer>
                        <form onSubmit={handleSubmit(props.onSubmitReplace)}>
                            {props.successMsg &&
                                <ModalSuccess text={props.successMsg}/>
                            }
                            <TitleContainer>
                                <Title> {props.pageTitle} </Title>
                            </TitleContainer>

                            <ContentContainer>
                                <ContentContainer1>
                                    <Subtitle>{props.titleReplaced}</Subtitle>                               
                                    <ContentContainer11>
                                        <label> CPF </label>
                                        <Text> 
                                            { cpfMask(props.replacedOperator.cpf) }
                                        </Text>
                                    </ContentContainer11>
                                    <ContentContainer11>
                                        <label> Nome completo </label>
                                        <Text> 
                                            {props.replacedOperator.name}
                                        </Text>
                                    </ContentContainer11>
                                    <ContentContainer11>
                                        <label> Órgão </label>
                                        <Text> 
                                            {props.replacedOperator.organ.label}
                                        </Text>
                                    </ContentContainer11>
                                    {props.replacedOperator.unidade &&
                                    <ContentContainer11>
                                        <label> Unidade do órgão </label>
                                        <Text> 
                                            {props.replacedOperator.unidade.label}
                                        </Text>
                                    </ContentContainer11>
                                    }
                                    
                                </ContentContainer1>

                                <ContentContainer2>
                                    <Subtitle>{props.titleReplacer}</Subtitle>
                                    <ContentContainer11>
                                        <label> CPF </label>
                                        <Text> 
                                            { cpfMask(props.replacerOperator.cpf) }
                                        </Text>
                                    </ContentContainer11>
                                    <ContentContainer11>
                                        <label> Nome </label>
                                        <Text> 
                                            {props.replacerOperator.name}
                                        </Text>
                                    </ContentContainer11>
                                    <ContentContainer11>
                                        <label> Órgão </label>
                                        <Text> 
                                            {props.replacerOperator.organ.label}
                                        </Text>
                                    </ContentContainer11>
                                    {props.replacerOperator.unidade &&
                                    <ContentContainer11>
                                        <label> Unidade do órgão </label>
                                        <Text> 
                                            {props.replacerOperator.unidade.label}
                                        </Text>
                                    </ContentContainer11>
                                    }
                                </ContentContainer2>
                            </ContentContainer>
                            
                            <ContentContainerDown>
                                <ContentContainerButtons>
                                    {
                                        !props.replaced && <>
                                            <button className="br-button" type="button"
                                                onClick={() => {
                                                    // const data = {
                                                    //     result_search: {
                                                    //         ID_USUARIO_ORGAO: props.replacedOperator.id_usuario_orgao,
                                                    //         CPF_USUARIO: props.replacedOperator.cpf,
                                                    //         NOME_USUARIO: props.replacedOperator.name,                                                        
                                                    //         CODIGO_ORGAO: props.replacedOperator.organ.value,
                                                    //         NOME_ORGAO: props.replacedOperator.organ.label,
                                                    //         CODIGO_UNIDADE: props.replacedOperator.unidade ? props.replacedOperator.unidade.value : undefined,
                                                    //         NOME_UNIDADE: props.replacedOperator.unidade ? props.replacedOperator.unidade.label : undefined,
                                                    //         DATA_INCLUSAO: props.replacedOperator.dt_inc,
                                                    //         DATA_CANCELAMENTO: props.replacedOperator.dt_canc_exp
                                                    //     }
                                                    // }
                                                    // props.history.push(props.buttonBackRoute, data)
                                                    props.history.goBack()
                                                    }
                                                }>
                                                Voltar
                                            </button>
                                            <button className="br-button primary" type="submit">
                                                Confirmar
                                            </button>
                                        </>
                                    }
                                    {
                                        props.replaced && <button className="br-button primary" type="button"
                                                                onClick={() => props.history.push(props.buttonStartRoute)}>
                                                            Início
                                                        </button>
                                    }
                                    
                                </ContentContainerButtons>
                            </ContentContainerDown>
                        </form>
                    </GlobalContainer>
                </>
            }
        </>
    );
}