import styled from 'styled-components';

export const ModalCenterFont = styled.div `
    display: ${props => props.appear ? 'block': 'none'};
    
    position: fixed;
    z-index: 9999;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .6);

    #modal {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -108px;
        margin-left: -250px;
    }

`;