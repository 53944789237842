export const isManagerAuthenticated = () => {
    // averiguo se tem a variavel no storage
    if (!!localStorage.getItem('user') && !!localStorage.getItem('user_picture')) {
        const user = JSON.parse(localStorage.getItem('user'));

        // averiguo se e gestor
        if (user.perfil === "R" || user.perfil === "O" || user.perfil === "S") {

            // averiguo se o token e valido ( como fazer isso se a key no frontend nao e recomendada )
            
            if (user.expiresIn) {
                // averiguo se o token ainda e valido
                if (user.expiresIn > Date.now()) {
                    return true;
                }
            }
        }
    }

    // tentou acessar sem o token, com um invalido, sessao expirou ou nao e gestor.
    // Limpo o storage e barro a rota
    localStorage.clear();
    return false;
};