import styled from 'styled-components';

export const Text = styled.label `
    font-size: 14px;
    font-style: italic;
`;

export const Text2 = styled.label `
    font-size: 12px;
    font-style: italic;
    color: #555555;
`;

export const HeaderUp = styled.div `
    z-index: 10;
    position: fixed;
    top: 0px;
    width: 100%;

    #UserInfo {
        display: ${ props => props.showInfo ? '' : 'none'};
    }
    
    @media print {
        div {
            display: none;
        }        
    }
`;