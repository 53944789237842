import styled from 'styled-components';

export const ReportParameterContainer = styled.div `
    @media print {
        div {
            display: none !important;
        }        
    }
`;

export const Subtitle = styled.div `
    padding: 0px 0px 0px;
    margin-bottom: 15px;

    img { margin-bottom: 7px; margin-right: 7px}}
    
    label {
       font-size: 1.5em;
       color: #0c326f;
    }
`;

export const Line = styled.div `
    margin-top: 5px;
    border-bottom: 1px solid #cccccc;
`;

export const SearchContainer = styled.div `
    padding: 0px 0px 20px;
    display: flex;

    //border: 1px solid;
    //border-color: blue;
`;

export const InputContainer = styled.div `
    display: grid;
    margin-right: 30px;

    input {
        padding: 0px 7px 0px;
        font-size: 14px;
        font-weight: normal;
        color: #333333;
    }

    //border: 1px solid;
    //border-color: red;
`;

export const SearchButtonsContainer = styled.div `
    width: 100%;
    padding: 20px 0 0;
    text-align: right;

    button:nth-child(2){ 
        margin-left: 10px;
    }

    button:nth-child(3){ 
        margin-left: 10px;
    }
`;