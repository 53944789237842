import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 24px;
  color: #0c326f;

  @media (max-width: 1000px) {
    font-size: 18px;
    width: 380px;
  }
`

export const GlobalContainer = styled.div`
  margin-top: 80px;

  @media (max-width: 1000px) {
    div {
      display: grid;
      margin: 0px auto;
    }
  }

  //127px(altura do footer) ou 80px(altura do footer2)
  padding: 0px 0px 80px;
`

export const ContentContainer = styled.div`
  margin: 0px auto;
  max-width: 1000px;
`

export const ContentContainer1 = styled.div`
  width: 380px;
  margin-right: 30px;
  padding: 0px 0px 15px;
`

export const ContentContainer3 = styled.div`
  display: flex;
  justify-content: center;
  max-width: 790;
  margin: 0px auto;
`

export const ContentContainer4 = styled.div`
  margin: 20px auto;
  max-width: 790px;
`

export const ContentContainer11 = styled.div`
  display: grid;
  margin-bottom: 20px;
`

export const ContentContainer12 = styled.div`
  display: grid;
  margin-bottom: 20px;
`

export const ContentContainer2 = styled.div`
  width: 380px;
`

export const ContentContainerTable = styled.div`
  width: 790px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    width: 410px;
  }
`

export const ContentContainerDown = styled.div`
  display: flex;
  justify-content: center;
  max-width: 790;
  margin: 20px auto 0px;
`

export const ContentContainerButtons = styled.div`
  padding: 0px 0px 20px;
  width: 790px;
  text-align: right;

  button:nth-child(2) {
    /*-pegando o segundo button desta div-*/
    margin-left: 10px;
  }

  button:nth-child(3) {
    margin-left: 10px;
  }
`

export const TitleContainer = styled.div`
  padding: 30px 0px 0px;
  display: flex;
  justify-content: space-between;
`

export const Subtitle = styled.div`
  padding: 0px 0px 0px;
  margin-bottom: 15px;

  label {
    font-size: 18px;
  }
`

export const Line = styled.div`
  margin-top: 5px;
  border-bottom: 1px solid #cccccc;
`

export const SearchContainer = styled.div`
  padding: 0px 0px 40px;
  display: flex;

  //border: 1px solid;
  //border-color: blue;
`

export const InputContainer = styled.div`
  display: grid;
  margin-right: 30px;

  //border: 1px solid;
  //border-color: red;
`

export const InputContainer2 = styled.div`
  margin-right: 30px;

  input[type='checkbox'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: none;
    content: none;
  }

  input[type='checkbox']:before {
    font-family: Ampersand, Helvetica, sans-serif;
    content: '✓';
    font-size: 20px;
    color: transparent !important;

    text-align: center;

    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid #888;
    padding-left: 1px;
    margin-right: 15px;
    border-radius: 25%;
  }

  input[type='checkbox']:checked:before {
    color: black !important;
  }
`

export const SelectContainer = styled.div`
  width: 380px;
  height: 64px;
  display: grid;

  //border: 1px solid;
  //border-color: red;
`

export const SelectPagesContainer = styled.div`
  width: 55px;
  height: 64px;
  display: grid;

  //border: 1px solid;
  //border-color: red;
`

export const SearchButtonsContainer = styled.div`
  padding: 0px 0px 10px;
  text-align: right;

  button:nth-child(2) {
    margin-left: 10px;
  }

  button:nth-child(3) {
    margin-left: 10px;
  }
`

export const PaginationContainer = styled.div`
  margin-top: 20px;
  padding: 0px 30px;

  //border: 1px solid;
  //border-color: red;
`
export const Text = styled.label`
  font-size: 14px;
  font-weight: normal;
  color: #333333;
`
