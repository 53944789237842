import React, { useState, useEffect } from 'react';

import { GlobalContainer,
    ContentContainer,
    ContentContainer1,
    ContentContainer11,
    ContentContainer12,
    ContentContainer2,
    ContentContainer4,
    ContentContainerTable,
    Title,
    Subtitle,
    Text,
    TitleContainer,
    PaginationContainer,
    SelectPagesContainer,
    ContentContainerButtons } from './styles';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import DropLeftIcon from '../../assets/drop_left_icon.png';
import DropRightIcon from '../../assets/drop_right_icon.png';
import ModalConfirm from '../Modal/Confirm/index';
import ModalWarn from '../Modal/Warn/index';
import ModalSuccess from '../Messages/Success/index';
import { cpfMask, formatDate } from '../../utils';
import Select, { components } from 'react-select';

export default function CancelOperator(props) {

    const [rangePagesOptions] = useState([
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
    ]);
    const [ modalAssociatedWarning, setModalAssociatedWarning ] = useState(false)
    const SelecPageStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: 0,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 15,
        }),
    }
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen 
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon"/>
                }
            </components.DropdownIndicator>
        );
    }; 
    const IndicatorSeparator = ({ innerProps }) => {
        return <span style={{}} {...innerProps} />;
    };

    useEffect(() => {
        setModalAssociatedWarning(props.searchResult && props.searchResult.length)
    }, [props.searchResult]);

    return (
        <>
            {!props.loadOperator ?
                <div style={{'marginTop': '75px'}}><h1> Error on the page! </h1></div>
            :
                <>
                    <ModalConfirm 
                        id="modalconfirmalert"
                        appear={props.isModalConfirmVisible}
                        title={"Confirmar Cancelamento"}
                        text={props.confirmationMsg}
                        textButton1={"Não"}
                        textButton2={"Sim"}
                        actionButton1={() => props.setModalConfirmVisible(false)}
                        load={props.loadingCancel}
                        actionButton2={props.onSubmitCancel}
                    />

                    <ModalWarn
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text = {props.messageError}
                        textButton2='Ok'
                        actionButton2={() => props.setModalError(false)}
                    />

                    <ModalWarn
                        id="modalWarningConfirmalert"
                        appear={modalAssociatedWarning}
                        text = {props.messageAssociatedWarning}
                        textButton2='Ok'
                        actionButton2={() => setModalAssociatedWarning(false)}
                    />

                    <GlobalContainer>
                        {props.canceled && <ModalSuccess text={props.successMsg}/>}

                        <TitleContainer>
                            <Title> {props.pageTitle} </Title>
                        </TitleContainer>

                        <ContentContainer>
                            <ContentContainer1>
                                <ContentContainer11>
                                    <label> CPF </label>
                                    <Text> 
                                        { cpfMask(props.operator.cpf) }
                                    </Text>
                                </ContentContainer11>

                                <ContentContainer12>
                                    <label> Nome </label>
                                    <Text> 
                                        {props.operator.name}
                                    </Text>
                                </ContentContainer12>
                            </ContentContainer1>

                            <ContentContainer2>

                            </ContentContainer2>
                        </ContentContainer>

                        <ContentContainer>
                            <ContentContainerTable>
                                <div className="br-table" data-search="data-search" data-selection="data-selection">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col0" style={{'color': '#1351B4'}}></th>
                                                <th scope="col1" style={{'color': '#1351B4'}}> Órgão </th>
                                                {props.operator.unidade &&
                                                <th scope="col2" style={{'color': '#1351B4'}}> Unidade do Órgão </th>
                                                }
                                                <th scope="col2" style={{'color': '#1351B4'}}> Data de Cadastro </th>
                                                <th scope="col3" style={{'color': '#1351B4'}}> Data de Expiração </th>
                                                <th scope="col99" style={{'color': '#1351B4'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> </td>
                                                <td> {props.operator.organ.label} </td>
                                                {props.operator.unidade &&
                                                <td> {props.operator.unidade.label} </td>
                                                }
                                                <td> { formatDate(props.operator.dt_inc) } </td>
                                                <td> {props.operator.dt_canc_exp === null ? '-' : formatDate(props.operator.dt_canc_exp) } </td>
                                                <td> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ContentContainerTable>
                        </ContentContainer>

                        <ContentContainer>
                            {!!(props.searchResult && props.searchResult.length) && <>
                                <ContentContainer4>
                                    <Subtitle>
                                        <label>{props.tableResultListTitle}</label>
                                    </Subtitle>
                                    <div className="br-table" data-search="data-search" data-selection="data-selection">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col0" style={{ 'color': '#1351B4' }}></th>
                                                    <th scope="col1" style={{ 'color': '#1351B4' }}> Nome completo </th>
                                                    <th scope="col2" style={{ 'color': '#1351B4' }}> CPF </th>
                                                    <th scope="col3" style={{ 'color': '#1351B4' }}> Órgão </th>
                                                    <th scope="col3" style={{ 'color': '#1351B4' }}> Unidade do Órgão </th>
                                                    <th scope="col4" style={{ 'color': '#1351B4' }}> Data de Cadastro </th>
                                                    <th scope="col5" style={{ 'color': '#1351B4' }}> Data de Expiração </th>
                                                    <th scope="col99" style={{ 'color': '#1351B4' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.searchResult.map(result => (
                                                    <tr key={result.ID_USUARIO_ORGAO}>
                                                        <td> </td>
                                                        <td> {result.NOME_USUARIO} </td>
                                                        <td> { cpfMask(result.CPF_USUARIO) }</td>
                                                        <td> {result.NOME_ORGAO} </td>
                                                        <td> {result.NOME_UNIDADE} </td>
                                                        <td> { formatDate(result.DATA_INCLUSAO) } </td>
                                                        <td> {result.DATA_CANCELAMENTO === null ? "-" : formatDate(result.DATA_CANCELAMENTO) } </td>
                                                        <td> </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <PaginationContainer>
                                        <nav className="br-pagination" aria-label="Paginação de resultados" data-total="50" data-current="1" data-per-page="20">
                                            <div className="pagination-per-page">
                                                <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Exibir </label>
                                                <SelectPagesContainer>
                                                    <Select
                                                        options={rangePagesOptions}
                                                        defaultValue={{ value: "10", label: "10" }}
                                                        value={props.rangePages}
                                                        onChange={value => {
                                                            props.setRangePages(value)
                                                            props.onChangePaginationConfig(value, props.page, true)
                                                        }}
                                                        styles={SelecPageStyles}
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 6,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#CCCCCC',
                                                                primary: '#1351B4'
                                                            },
                                                        })}
                                                        components={
                                                            {
                                                                DropdownIndicator,
                                                                IndicatorSeparator
                                                            }
                                                        }
                                                    />
                                                </SelectPagesContainer>
                                            </div>

                                            <span className="br-divider d-none d-sm-block mx-3"></span>

                                            <div className="pagination-information d-none d-sm-flex">
                                                <span className="current"> {1 + ((Number(props.page.value) - 1) * Number(props.rangePages.value))} </span>&ndash;<span className="per-page"> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className="total"> {props.totalFilter} </span>&nbsp;itens
                                            </div>
                                            <div className="pagination-go-to-page d-none d-sm-flex ml-auto">
                                                <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Página </label>
                                                <SelectPagesContainer>
                                                    <Select
                                                        options={props.pageOptions}
                                                        defaultValue={{ value: "1", label: "1" }}
                                                        value={props.page}
                                                        onChange={value => {
                                                            props.setPage(value)
                                                            props.onChangePaginationConfig(props.rangePages, value, false)
                                                        }}
                                                        styles={SelecPageStyles}
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 6,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#CCCCCC',
                                                                primary: '#1351B4'
                                                            },
                                                        })}
                                                        components={
                                                            {
                                                                DropdownIndicator,
                                                                IndicatorSeparator
                                                            }
                                                        }
                                                    />
                                                </SelectPagesContainer>
                                            </div>

                                            <span className="br-divider d-none d-sm-block mx-3"></span>

                                            <div className="pagination-arrows ml-auto ml-sm-0">
                                                <button className="br-button circle" type="button" aria-label="Voltar página"
                                                    style={{
                                                        'opacity': Number(props.page.value) > 1 ? '' : '0.4',
                                                        'pointerEvents': Number(props.page.value) > 1 ? '' : 'none'
                                                    }}
                                                    onClick={() => {
                                                        const new_page = {
                                                            value: String(Number(props.page.value) - 1),
                                                            label: String(Number(props.page.value) - 1)
                                                        }
                                                        props.setPage(new_page)
                                                        props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                    }}
                                                >
                                                    <i className="fas fa-angle-left" aria-hidden="true">
                                                        <img
                                                            src={DropLeftIcon}
                                                            alt="leftIcon"
                                                            style={{ "marginBottom": "6px" }}
                                                        />
                                                    </i>
                                                </button>
                                                <button className="br-button circle" type="button" aria-label="Avançar página"
                                                    style={{
                                                        'opacity': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : '0.4',
                                                        'pointerEvents': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : 'none'
                                                    }}
                                                    onClick={() => {
                                                        const new_page = {
                                                            value: String(Number(props.page.value) + 1),
                                                            label: String(Number(props.page.value) + 1)
                                                        }
                                                        props.setPage(new_page)
                                                        props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" aria-hidden="true">
                                                        <img
                                                            src={DropRightIcon}
                                                            alt="rightIcon"
                                                            style={{ "marginBottom": "6px" }}
                                                        />
                                                    </i>
                                                </button>
                                            </div>
                                        </nav>
                                    </PaginationContainer>
                                </ContentContainer4>
                            </>}
                        </ContentContainer>


                        <ContentContainer>
                            <ContentContainer1>

                            </ContentContainer1>

                            <ContentContainer2>
                                <ContentContainerButtons>
                                    {!props.canceled ?
                                        <button className="br-button" type="button"
                                                onClick={() => props.history.push(props.buttonBackRoute, props.location.state)}>
                                            Voltar
                                        </button>
                                    :
                                        <button className="br-button" type="button"
                                                onClick={() => {
                                                    const data = {
                                                        result_search: props.location.state.result_search,
                                                        fields_search: props.location.state.fields_search,
                                                        pagination: {
                                                            range_page: {label: "10", value: "10"},
                                                            page: {label: "1", value: "1"},
                                                            page_options: [],
                                                            total: "0"
                                                        },
                                                        list: []
                                                    }
                                                    props.history.push(props.buttonBackRoute, data)
                                                }}>
                                            Voltar
                                        </button>
                                    }

                                    {!!(props.searchResult && props.searchResult.length) && <>
                                        <button className="br-button primary" type="button"
                                            onClick={() => {
                                                const data = {
                                                    result_search: props.location.state.result_search
                                                }
                                                props.history.push(props.buttonReplaceRoute, data)
                                            }}>
                                            Substituir
                                        </button>
                                    </>
                                    }
                                    {!(props.searchResult && props.searchResult.length) && <>
                                        {!props.canceled ?
                                            <button className="br-button primary" type="button"
                                                    onClick={() => props.setModalConfirmVisible(true)}>
                                                Confirmar
                                            </button>
                                        :
                                            <button className="br-button primary" type="button" disabled="disabled">
                                                Confirmar
                                            </button>
                                        }
                                    </>
                                    }
                                </ContentContainerButtons>
                            </ContentContainer2>
                        </ContentContainer>
                    </GlobalContainer>
                </>
            }
        </>
    );
}