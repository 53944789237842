import React from 'react';

import { useForm } from 'react-hook-form';

import { GlobalContainer,
         ContentContainer,
         ContentContainer1,
         ContentContainer11,
         ContentContainer2,
         Title,
         TitleContainer,
         ContentContainerButtons} from './styles';
import ModalWarn from '../Modal/Warn/index';

import FailIcon from '../../assets/fail_icon.png';
import { cpfMask } from '../../utils';

export default function Search(props) {
    const { register, handleSubmit, errors } = useForm();

    return (
        <>
            <ModalWarn
                id="modalconfirmalert"
                appear={props.modalError}
                text = {props.messageError}
                textButton2='Ok'
                actionButton2={() => props.setModalError(false)}
            />

            <GlobalContainer>
                <TitleContainer>
                    <Title> {props.pageTitle} </Title>
                </TitleContainer>
                
                <form onSubmit={handleSubmit(props.onSubmitCPF)}>
                    <ContentContainer>
                        <ContentContainer1>
                                <ContentContainer11>
                                    <div className="br-input has-icon">
                                        <label htmlFor="cpf"> {props.cpfLabel ? props.cpfLabel : 'CPF'} </label>
                                        <input className="has-icon"
                                            id="cpf" 
                                            name="cpf"
                                            type="text" 
                                            placeholder="Digite somente números"
                                            maxLength="14"
                                            defaultValue=""
                                            ref={register({
                                                required: { value: true, message: "Digite o CPF." },
                                                minLength: { value: 14, message: 'CPF inválido.' },
                                                maxLength: { value: 14, message: 'CPF inválido.' }
                                            })}
                                            onChange={(event) => {
                                                const {value} = event.target
                                                event.target.value = cpfMask(value)
                                            }}
                                        />
                                    </div>
                                    {errors?.cpf && 
                                    <span className="feedback danger" role="alert">
                                        <i className="fas fa-times-circle" aria-hidden="true">
                                            <img
                                                src={FailIcon}
                                                style = {{ paddingBottom: '5px' }}
                                                alt="failicon"
                                            />
                                        </i>
                                        {<script>
                                            {props.setCpfSearchErro(false)}
                                        </script>}
                                        {errors.cpf.message}
                                    </span>}
                                    {props.cpfSearchErro &&
                                        <span className="feedback danger" role="alert"
                                            id = "cpfsearch">
                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                <img
                                                    src={FailIcon}
                                                    style = {{ paddingBottom: '5px' }}
                                                    alt="failicon"
                                                />
                                            </i>
                                            <label id="cpfsearchlabel">{props.cpfSearchErroMessage}</label>
                                        </span>
                                    }
                                </ContentContainer11>
                        </ContentContainer1>

                        <ContentContainer2>
                            
                        </ContentContainer2>
                    </ContentContainer>

                    <ContentContainer>
                        <ContentContainer1>

                        </ContentContainer1>

                        <ContentContainer2>
                            <ContentContainerButtons>
                                <button className="br-button" type="button"
                                        onClick={() => props.history.goBack()}>
                                    Voltar
                                </button>

                                {!props.buttonNextLoading ?
                                    <button className="br-button primary" type="submit">
                                        Avançar
                                    </button> 
                                :
                                    <button className="br-button primary loading" type="button">
                                        Avançar
                                    </button>
                                }
                            </ContentContainerButtons>
                        </ContentContainer2>
                    </ContentContainer>
                </form>
            </GlobalContainer>
        </>
    );
}