import React, { useState, useEffect } from 'react';

import BlockOperator from '../../Components/BlockOperator'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function LockUnlockUser(props) {

    const [ user, setUser ] = useState({
        cpf_usuario: '',
        name: '',
        nome_orgao: '',
        nome_unidade: '',
        data_inclusao: '',
        data_expiracao: '',
        cpf_usuario_cadastrador: '',
        nome_usuario_cadastrador: '',
        data_bloqueio: '',
        motivo_bloqueio: ''
    });
    const [ successMsg, setSuccessMsg ] = useState();
    const [ loadUser, setLoadUser ] = useState(false);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(()=>{
        if (!loadUser && props.location.state !== undefined) {
            setUser({
                id_usuario_orgao: props.location.state.id_usuario_orgao,
                cpf_usuario: props.location.state.cpf,
                name: props.location.state.name,
                nome_orgao: props.location.state.nome_orgao,
                nome_unidade: props.location.state.nome_unidade,
                data_inclusao: props.location.state.data_inclusao,
                data_expiracao: props.location.state.data_expiracao,
                cpf_usuario_cadastrador: props.location.state.cpf_usuario_cadastrador,
                nome_usuario_cadastrador: props.location.state.nome_usuario_cadastrador,
                data_bloqueio: props.location.state.data_bloqueio,
                motivo_bloqueio: props.location.state.motivo_bloqueio,
                data_desbloqueio: props.location.state.data_desbloqueio,
                motivo_desbloqueio: props.location.state.motivo_desbloqueio
            });

            setLoadUser(true);
        }
    }, [props.location.state, loadUser]);

    const [ loadingAdd, setLoadingAdd ] = useState(false);    

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

     // ================================================================================

    const onSubmitUserData = async (data) => {
        setLoadingAdd(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",
                id_usuario_orgao: data.id_usuario_orgao,
                cpf_usuario: data.cpf_usuario,
                operacao: data.data_bloqueio ? 'desbloquear' : 'bloquear',
                motivo_bloqueio: data.motivo_bloqueio,
                motivo_desbloqueio: data.motivo_desbloqueio
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/bloqueioDesbloqueioUsuario`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    setUser({...user, ...data_request});
                    setSuccessMsg(data_request.operacao === 'bloquear' ? 'Usuário bloqueado.' : 'Usuário desbloqueado');
                    setLoadingAdd(false);
                    props.history.push("/bloquearDesbloquearUsuario", user);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/cadastraatendente BackendBalcao');
                    }

                    setLoadingAdd(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingAdd(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingAdd(false);
                console.log('Error request to /gestao/cadastraatendente');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <BlockOperator
                pageTitle= {user.data_bloqueio ? 'Desbloquear usuário' : 'Bloquear usuário'}
                onSubmitData={onSubmitUserData}
                loadOperator={loadUser}
                operator={user}
                setOperator={setUser}
                loadingAdd={loadingAdd}
                setLoadingAdd={setLoadingAdd}
                modalError ={modalError}
                setModalError={setModalError}
                messageError={messageError}
                fromSuccessMsg={successMsg}
                buttonCancelRoute='/bloquearDesbloquear'
                history={props.history}
            />
        </>
    );
}