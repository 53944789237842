import styled from 'styled-components';

export const Title = styled.h1 `
    width: 960px;

    font-size: 24px;
    color: #0c326f;

`;

export const Text = styled.label `
    font-size: 17px;
    color: black;

    @media (max-width: 1000px) {
        font-size: 15px;
    }
`;

export const GlobalContainer = styled.div `

    margin-top: 75px;

    @media (max-width: 1000px) {
        div {
            margin: 0px auto;
        }
    }

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const Container = styled.div `
    display: grid;
    padding: 70px 0px 0px;
    justify-content: center;
    text-align: center;
`;

export const ContainerButton = styled.div `
    display: grid;
    padding: 30px 0px 0px;
    justify-content: center;
    text-align: center;
`;