import React, { useState, useEffect } from 'react'
import AdminEditAgencyScheduleManagement from './AdminEditAgencyScheduleManagement'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function AdminEditAgencySchedule(props) {
  useEffect(() => {}, [])

  const [schedule, setSchedule] = useState({
    diasSemana: '',
    horaInicio: '',
    horaFim: '',
    organCode: '',
  })
  const [modal, setModal] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
  const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
  const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

  const onSubmitEdit = async () => {

    const requestOptions = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
      }),
      body: JSON.stringify({
        cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : '', //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
        codigo_orgao: schedule.organCode,
        dias_semana: schedule.diasSemana,
        hora_inicio: schedule.horaInicio,
        hora_fim: schedule.horaFim,
      }),
    }

    await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/editaHorarioOrgao`, requestOptions)
      .then(function (response) {
        if (response.ok) {
          response.json().then(function () {
            setResponseMessage("Novo horário definido com sucesso")
            setModal(true)
            props.history.push("/horarios");
          })
        } else if (response.status === 422) {
          response.json().then(function (data) {
            if (!!data.errorCode && !!data.message) {
              // inserir a mensagem: data.message no aviso de erro
              setResponseMessage(data.message)
              setModal(true)
            } else {
              // inserir mensagem genérica
              setResponseMessage('Houve algum problema no servidor.')
              setModal(true)
              console.log('Response error in /gestao/editaGestorOrgao BackendBalcao')
            }

          })
        } else if (response.status === 403) {
          response.json().then(function (data) {
            setModalInvalidSessionErrorTitle(data.title ? data.title : null)
            setModalInvalidSessionErrorMsg(data.message ? data.message : null)
            setModalInvalidSessionError(true)
          })
        } else {
            setResponseMessage('Houve algum problema no servidor. Aguarde um momento e tente novamente.')
            setModal(true)
            console.log('Error request to /gestao/editaGestorOrgao')
          }
      })
      .catch(function (err) {
        console.error('Failed retrieving information', err)
      })
  }

  return (
    <>
      <ModalWarn
          id='modalWarnalertInvalidSession'
          appear={modalInvalidSessionError}
          title = { modalInvalidSessionErrorTitle }
          text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
          textButton2='Ir para tela inicial'
          actionButton2={() => { 
              document.getElementById("btnLogout").click();
          }}
      />
      <AdminEditAgencyScheduleManagement
        pageTitle='Editar horário de funcionamento do órgão'
        setSchedule={setSchedule}
        schedule={schedule}
        modal={modal}
        setModal={setModal}
        responseMessage={responseMessage}
        setResponseMessage={setResponseMessage}
        onSubmitEdit={onSubmitEdit}
        history={props.history}
        location={props.location}
        buttonCancelRoute='/horarios'
      />
    </>
  )
}
