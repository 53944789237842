import React, { useState, useEffect } from 'react';

import ManagementUnit from '../../Components/ManagementUnit'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function UnitManagement(props) {
    const [ loading, setLoading] = useState(false);
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [ pageOptions, setPageOptions ] = useState([
        /*
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        */
    ]);
    const [ page, setPage ] = useState({
        label: "1", value: "1"
    });

    const [ modalError, setModalError ] = useState(false);

    const [ regionalManagerList, setRegionalManagerList ] = useState([ ]);

    const [ totalFilter, setTotalFilter ] = useState("0");

    const [ erroMsg, setErroMsg ] = useState('');

    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)


    useEffect(() => {
        
    }, [props.location.state]);

    const onSubmitSearch = async (data) => {
        setLoading(true);
        setRegionalManagerList([]);
        setErroMsg('');
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_orgao: data.organ,
                nome_unidade: data.nome_unidade,
                pagina_atual: "1",
                qtd_registros: rangePages.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaUnidadesPorNomeOrgao`, requestOptions).then(function(response) {
            setLoading(false);
            if (response.ok) {
                response.json().then(function(resp) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    const maxPageOption = Math.ceil(Number(resp.total) / Number(rangePages.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }

                    setPageOptions(list);
                    setPage({label: "1", value: "1"});
                    setRegionalManagerList(resp.unidades);
                    setTotalFilter(resp.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                    //setLoading(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoading(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                //setLoading(false);
                setModalError(true);
                console.log('Request error in gestao/listaUnidadesPorNomeOrgao BackendBalcao');
            }
        }).catch(function(err) {
            setLoading(false);
            console.error('Failed retrieving information', err);
        });
    };

    const onChangePaginationConfig = async (data, ranPag, pag, isRanPag) => {
        setErroMsg('');
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_orgao: data.organ,
                nome_unidade: data.nome_unidade,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaUnidadesPorNomeOrgao`, requestOptions).then(function(response) {
            setLoading(false);
            if (response.ok) {
                response.json().then(function(data) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }

                    setPageOptions(list);
                    if(isRanPag){
                        setPage({label: "1", value: "1"});
                    }
                    setRegionalManagerList(data.unidades);
                    setTotalFilter(data.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                    //setLoading(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoading(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                //setLoading(false);
                setModalError(true);
                console.log('Request error in gestao/listaUnidadesPorNomeOrgao BackendBalcao');
            }
        }).catch(function(err) {
            setLoading(false);
            console.error('Failed retrieving information', err);
        });
    };

    return(
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <ManagementUnit
                pageTitle='Gestão de unidades do órgão'
                buttonRegistrationTitle='+ Cadastrar unidade'
                buttonRegistrationRoute='/adicionarUnidade'
                fieldNamePlaceholder='Digite o nome da unidade'
                tableResultListTitle='Lista de unidades cadastradas'
                buttonDetailRoute='/detalharUnidade'
                buttonEditRoute='/editarUnidade' 
                buttonDeleteRoute='/removerUnidade'
                buttonNextLoading={loading}
                onSubmitSearch={onSubmitSearch}
                onChangePaginationConfig={onChangePaginationConfig}
                searchResult={regionalManagerList}
                setSearchResult={setRegionalManagerList}
                history={props.history}
                totalFilter={totalFilter}
                pageOptions={pageOptions}
                setPage={setPage}
                page={page}
                erroMsg={erroMsg}
                setErroMsg={setErroMsg}
                rangePages={rangePages}
                setRangePages={setRangePages}
                modalError={modalError}
                setModalError={setModalError}
            />
        </>
    );
}