import React, { useState, useEffect } from 'react'
import { GlobalContainer, ContentContainer, TitleContainer, Title, SelectPagesContainer, PaginationContainer, ContentContainerDown, ContentContainerButtons } from './styles'
import Select, { components } from 'react-select'
import ModalCancel from '../../Components/Modal/Cancel/index'
import DropDownIcon from '../../assets/drop_down_icon.png'
import DropUpIcon from '../../assets/drop_up_icon.png'
import DropRightIcon from '../../assets/drop_right_icon.png'
import DropLeftIcon from '../../assets/drop_left_icon.png'
import EditIcon from '../../assets/edit_icon.png'
import AlertIcon from '../../assets/alert_icon2.png'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function AdminAgencyScheduleManagement(props) {
  const [defaultFormValues, setDefaultFormValues] = useState({
    cpf: '',
    name: '',
    organ: '',
  })

  const [organ] = useState({
    value: JSON.parse(localStorage.getItem('user')).codigo_orgao ? JSON.parse(localStorage.getItem('user')).codigo_orgao : '0',
    label: JSON.parse(localStorage.getItem('user')).orgao ? JSON.parse(localStorage.getItem('user')).orgao : 'Todos os órgãos',
  })

  useEffect(() => {
    setDefaultFormValues({ ...defaultFormValues, organ: organ.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [rangePagesOptions] = useState([
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '30', label: '30' },
  ])

  // ESTILIZACAO PARA O SELECT ======================================================

  const SelecPageStyles = {
    control: (defaultStyles, state) => ({
      ...defaultStyles,
      borderWidth: 0,
      borderColor: state.isFocused ? '#E60000' : '#888',
      '&:hover': {
        backgroundColor: '#C5D4EB',
      },
      marginTop: 15,
    }),
  }

  const DropdownIndicator = (props) => {
    return <components.DropdownIndicator {...props}>{props.selectProps.menuIsOpen ? <img src={DropUpIcon} alt='upicon' /> : <img src={DropDownIcon} alt='downicon' />}</components.DropdownIndicator>
  }

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={{}} {...innerProps} />
  }
  // ================================================================================

  const [modalCancel, setModalCancel] = useState(false)

  return (
    <>
      <ModalCancel
        id='modalcancelalert'
        appear={modalCancel}
        text={'Deseja cancelar a pesquisa e retornar para página principal?'}
        actionButton1={() => setModalCancel(false)}
        actionButton2={() => props.history.push('/pesquisarcidadao')}
      />
      <ModalWarn
        id='modalconfirmalert'
        appear={props.modalError}
        text={'Houve algum problema no servidor. Aguarde um momento e tente novamente.'}
        textButton2='Ok'
        actionButton2={() => props.setModalError(false)}
      />

      <GlobalContainer>
        <ContentContainer>
          <TitleContainer>
            <Title>{props.pageTitle}</Title>
          </TitleContainer>


          {!!props.searchResult.length && (
            <>
              <div className='br-table' data-search='data-search' data-selection='data-selection'>
                <table>
                  <thead>
                    <tr>
                      <th scope='col0' style={{ color: '#1351B4' }}></th>

                      <th scope='col3' style={{ color: '#1351B4' }}>
                        Órgão
                      </th>
                      <th scope='col3' style={{ color: '#1351B4' }}>
                        Dias da semana
                      </th>
                      <th scope='col3' style={{ color: '#1351B4' }}>
                        Horário Inicial
                      </th>
                      <th scope='col4' style={{ color: '#1351B4' }}>
                        Horário Final
                      </th>

                      <th scope='col8' style={{ color: '#1351B4' }}>
                        Ações
                      </th>
                      <th scope='col99' style={{ color: '#1351B4' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.searchResult.map((result) => (
                      <tr key={result.ID_USUARIO_ORGAO}>
                        <td> </td>

                        <td> {result.NOME_ORGAO} </td>
                        <td> {result.DIAS_SEMANA} </td>
                        <td> {result.HORA_INICIO} </td>
                        <td> {result.HORA_FIM} </td>

                        <td>
                          <img
                            src={EditIcon}
                            alt='editIcon'
                            title='Editar'
                            style={{
                              marginRight: '15px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              const data = {
                                result_search: result,
                                fields_search: {
                                  cpf: defaultFormValues.cpf,
                                  name: defaultFormValues.name,
                                  organ: organ,
                                },
                                pagination: {
                                  range_page: props.rangePages,
                                  page: props.page,
                                  page_options: props.pageOptions,
                                  total: props.totalFilter,
                                },
                                list: props.searchResult,
                              }
                              props.history.push(props.buttonEditRoute, data)
                            }}
                          />
                        </td>

                        <td> </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <PaginationContainer>
                <nav className='br-pagination' aria-label='Paginação de resultados' data-total='50' data-current='1' data-per-page='20'>
                  <div className='pagination-per-page'>
                    <label style={{ fontWeight: 'normal', marginRight: '15px' }}> Exibir </label>
                    <SelectPagesContainer>
                      <Select
                        options={rangePagesOptions}
                        defaultValue={{ value: '10', label: '10' }}
                        value={props.rangePages}
                        onChange={(value) => {
                          props.setRangePages(value)
                          props.onChangePaginationConfig(defaultFormValues, value, props.page, true)
                        }}
                        styles={SelecPageStyles}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          colors: {
                            ...theme.colors,
                            primary25: '#CCCCCC',
                            primary: '#1351B4',
                          },
                        })}
                        components={{
                          DropdownIndicator,
                          IndicatorSeparator,
                        }}
                      />
                    </SelectPagesContainer>
                  </div>

                  <span className='br-divider d-none d-sm-block mx-3'></span>

                  <div className='pagination-information d-none d-sm-flex'>
                    <span className='current'> {1 + (Number(props.page.value) - 1) * Number(props.rangePages.value)} </span>&ndash;
                    <span className='per-page'> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className='total'> {props.totalFilter} </span>&nbsp;itens
                  </div>
                  <div className='pagination-go-to-page d-none d-sm-flex ml-auto'>
                    <label style={{ fontWeight: 'normal', marginRight: '15px' }}> Página </label>
                    <SelectPagesContainer>
                      <Select
                        options={props.pageOptions}
                        defaultValue={{ value: '1', label: '1' }}
                        value={props.page}
                        onChange={(value) => {
                          props.setPage(value)
                          props.onChangePaginationConfig(defaultFormValues, props.rangePages, value, false)
                        }}
                        styles={SelecPageStyles}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          colors: {
                            ...theme.colors,
                            primary25: '#CCCCCC',
                            primary: '#1351B4',
                          },
                        })}
                        components={{
                          DropdownIndicator,
                          IndicatorSeparator,
                        }}
                      />
                    </SelectPagesContainer>
                  </div>

                  <span className='br-divider d-none d-sm-block mx-3'></span>

                  <div className='pagination-arrows ml-auto ml-sm-0'>
                    <button
                      className='br-button circle'
                      type='button'
                      aria-label='Voltar página'
                      style={{
                        opacity: Number(props.page.value) > 1 ? '' : '0.4',
                        pointerEvents: Number(props.page.value) > 1 ? '' : 'none',
                      }}
                      onClick={() => {
                        const new_page = {
                          value: String(Number(props.page.value) - 1),
                          label: String(Number(props.page.value) - 1),
                        }
                        props.setPage(new_page)
                        props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                      }}>
                      <i className='fas fa-angle-left' aria-hidden='true'>
                        <img src={DropLeftIcon} alt='leftIcon' style={{ marginBottom: '6px' }} />
                      </i>
                    </button>
                    <button
                      className='br-button circle'
                      type='button'
                      aria-label='Avançar página'
                      style={{
                        opacity: Number(props.page.value) < Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value)) ? '' : '0.4',
                        pointerEvents: Number(props.page.value) < Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value)) ? '' : 'none',
                      }}
                      onClick={() => {
                        const new_page = {
                          value: String(Number(props.page.value) + 1),
                          label: String(Number(props.page.value) + 1),
                        }
                        props.setPage(new_page)
                        props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                      }}>
                      <i className='fas fa-angle-right' aria-hidden='true'>
                        <img src={DropRightIcon} alt='rightIcon' style={{ marginBottom: '6px' }} />
                      </i>
                    </button>
                  </div>
                </nav>
              </PaginationContainer>
            </>
          )}

          {props.erroMsg && (
            <span className='feedback warning' role='alert' id='listresult'>
              <i className='fas fa-times-circle' aria-hidden='true'>
                <img src={AlertIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
              </i>
              <label id='listresultlabel'>{props.erroMsg}</label>
            </span>
          )}
        </ContentContainer>
        
        <ContentContainerDown>
        <ContentContainerButtons>
          <button className='br-button secundary' type='button' onClick={() => setModalCancel(true)}>
            Voltar
          </button>
        </ContentContainerButtons>
        </ContentContainerDown>
        
      </GlobalContainer>
    </>
  )
}
