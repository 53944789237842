import React, { useState, useEffect } from 'react';

import EditOperator from '../../Components/EditOperator';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function EditAgencyManager(props) {
    const [ agencyManager, setAgencyManager ] = useState({
        cpf: '',
        name: '',
        dt_canc_exp: '' //"2021-07-08"
    });

    const [ organ, setOrgan ] = useState({ value: "", label: "" });
    const [ organOptions, setOrganOptions ] = useState([]);
    const [ loadAgencyManager, setLoadAgencyManager ] = useState(false);
    const [ hasAssociatedRegionalManagers, setHasAssociatedRegionalManagers] = useState(false);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        async function loadOrganOptions() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_usuario: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : ""
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaOrgaoGestorOrgao`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        var organList = [];
                        var obj = {};

                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO
                            }

                            organList.push(obj);
                        });

                        setOrganOptions(organList);
                    });
                } else {
                    console.log('Error request to /gestao/consultaOrgaoGestorOrgao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }
        async function loadAssociatedRegionalManager(cpf_usuario_cadastrador) {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_usuario_cadastrador: cpf_usuario_cadastrador,
                    pagina_atual: 1,
                    qtd_registros: 10
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaGestoresRegionaisAssociadosAoGestor`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        setHasAssociatedRegionalManagers(data.total !== "0");
                    });
                } else {
                    console.log('Error request to /gestao/listaGestoresRegionaisAssociadosAoGestor');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }
       
        if (!loadAgencyManager && props.location.state !== undefined) {
            setAgencyManager({
                cpf: props.location.state.result_search.CPF_USUARIO,
                name: props.location.state.result_search.NOME_USUARIO,
                dt_canc_exp: !!props.location.state.result_search.DATA_CANCELAMENTO ? props.location.state.result_search.DATA_CANCELAMENTO.split('T')[0] : ''
            });
            setOrgan({
                value: props.location.state.result_search.CODIGO_ORGAO,
                label: props.location.state.result_search.NOME_ORGAO,
            });
            loadOrganOptions();
            loadAssociatedRegionalManager(props.location.state.result_search.CPF_USUARIO);
            setLoadAgencyManager(true);
        }
    }, [props.location.state, loadAgencyManager]);

    const [ loadingEdit, setLoadingEdit ] = useState(false);

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

    const onSubmitEdit = async () => {
        
        setLoadingEdit(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                cpf_gestor_orgao: agencyManager.cpf,
                nome_gestor_orgao: agencyManager.name,
                codigo_orgao: organ.value,
                data_cancelamento: agencyManager.dt_canc_exp
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/editaGestorOrgao`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    const agencyManager_added = {
                        from: "editAgencyManager",
                        id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                        cpf: data_request.objGestorOrgao.cpf_gestor_orgao,
                        name: data_request.objGestorOrgao.nome_gestor_orgao,
                        organ: organ,
                        dt_inc: data_request.objGestorOrgao.data_inclusao,
                        dt_canc_exp: data_request.objGestorOrgao.data_cancelamento
                    }
                    setLoadingEdit(false);
                    
                    //console.log(agencyManager_aEditd);
                    props.history.push('mostrarGestorOrgao', agencyManager_added);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/editaGestorOrgao BackendBalcao');
                    }

                    setLoadingEdit(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingEdit(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingEdit(false);
                console.log('Error request to /gestao/editaGestorOrgao');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <EditOperator
                pageTitle='Editar gestor de órgão'
                operator={agencyManager}
                setOperator={setAgencyManager}
                organOptions={organOptions}
                organ={organ}
                setOrgan={setOrgan}
                loadOperator={loadAgencyManager}
                loadingEdit={loadingEdit}
                setLoadingEdit={setLoadingEdit}
                modalError={modalError}
                setModalError={setModalError}
                messageError={messageError}
                setMessageError={setMessageError}
                onSubmitEdit={onSubmitEdit}
                history={props.history}
                location={props.location}
                buttonCancelRoute='/gerenciarGestoresOrgao'
                hasAssociated={hasAssociatedRegionalManagers}
                msgUnidadeChangeForbiden='Não é possível alterar esta unidade pois já existem gestores regionais vinculados.'
                msgAgencyChangeForbiden='Não é possível alterar este órgão pois já existem gestores regionais vinculados.'
            />
        </>
    );
}