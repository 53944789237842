import React, { useState } from 'react';

import Search from '../../Components/Search'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function SearchAgencyManagerSubstitute(props) {
    const [ loadingAgencyManager, setLoadingAgencyManager ] = useState(false);   

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");
    const [ cpfSearchErro, setCpfSearchErro] = useState(false);
    const [ cpfSearchErroMessage, setCpfSearchErroMessage ] = useState("");
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    const removeInputMask = (cpf) => {
        if (cpf === "") {
            return ""
        } else {
            return cpf.replace(/[^\d]+/g, "");
        }
    };

    const onSubmitCPF = async (data) => {

        data.cpf = removeInputMask(data.cpf);
        setCpfSearchErro(false);

        setLoadingAgencyManager(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",  //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                cpf_gestor_orgao_original: props.location.state.result_search.CPF_USUARIO,
                cpf_gestor_orgao: data.cpf,
                codigo_orgao: props.location.state.result_search.CODIGO_ORGAO              
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaGestorOrgaoSubstituto`, requestOptions).then( 
            function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    const state = {
                        agency_manager: {
                            id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                            cpf: props.location.state.result_search.CPF_USUARIO,
                            name: props.location.state.result_search.NOME_USUARIO,
                            organ: {
                                value: props.location.state.result_search.CODIGO_ORGAO,
                                label: props.location.state.result_search.NOME_ORGAO
                            },
                            dt_inc: props.location.state.result_search.DATA_INCLUSAO,
                            dt_canc_exp: props.location.state.result_search.DATA_CANCELAMENTO
                        },
                        agency_manager_substitute: {
                            id_usuario_orgao: data.id_usuario_orgao,
                            cpf: data.cpf_gestor_orgao,
                            name: data.nome_gestor_orgao,
                            organ: {
                                value: data.codigo_orgao,
                                label: data.nome_orgao
                            },
                            dt_inc: data.data_inclusao,
                            dt_canc_exp: data.data_cancelamento
                        }
                        
                    }
                    setLoadingAgencyManager(false);

                    props.history.push("/substituirGestorOrgao", state);

                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setCpfSearchErro(true);
                        setCpfSearchErroMessage(data.message);
                    } else {
                        // inserir mensagem genérica
                        setCpfSearchErro(true);
                        setCpfSearchErroMessage('Houve algum problema no servidor.');
                    }

                    setLoadingAgencyManager(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingAgencyManager(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingAgencyManager(false);
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return ( 
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <Search
                pageTitle='Substituir gestor de órgão'
                cpfLabel='CPF do gestor de órgão substituto'
                buttonBackRoute='/gerenciarGestoresOrgao'
                messageError={messageError}
                onSubmitCPF={onSubmitCPF}
                modalError={modalError}
                setModalError={setModalError}
                buttonNextLoading={loadingAgencyManager}
                cpfSearchErro={cpfSearchErro}
                setCpfSearchErro={setCpfSearchErro}
                cpfSearchErroMessage={cpfSearchErroMessage}
                history={props.history}
            />
        </>
    );
}