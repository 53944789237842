import React from 'react';

import { ModalCenterFont } from './styles';
//import '../../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

function ModalConfirm(props) {

    return(
        <ModalCenterFont appear={props.appear}>
            <div className="br-modal is-large" id="modal">
                <div className="br-card">
                    <div className="container-fluid p-4">
                        <div className="br-modal-header">
                            <div
                                className="br-modal-title"
                                title="Retornar para página inicial?"
                            >
                                <strong>{props.title}</strong>
                            </div>
                        </div>
                        <div className="br-modal-body">{props.text}</div>
                        <div className="br-modal-footer justify-content-end">
                            <button className="br-button secondary small m-2" type="button" onClick={props.actionButton1}>
                                {props.textButton1}
                            </button>
                            { !props.load ?
                                    <button className="br-button primary small m-2" type="button" onClick={props.actionButton2}>
                                        {props.textButton2}
                                    </button>
                               :
                                    <button className="br-button secundary small m-2" type="button">
                                        <div className="loading">
                                            {props.textButton2}
                                        </div>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ModalCenterFont>
    );
}

export default ModalConfirm;