import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import {
    ReportParameterContainer,
    Subtitle,
    Line,
    SearchContainer,
    InputContainer,
    SearchButtonsContainer
} from './styles';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import FilterIcon from '../../assets/filter_icon.png';
import ModalCancel from '../Modal/Cancel';
import ModalWarn from '../Modal/Warn/index';
import { SelectContainer } from '../../Reports/styles';
import Select, { components } from 'react-select';

export default function ReportInputDataInterval(props) {
    const [modalCancel, setModalCancel] = useState(false);
    const { handleSubmit } = useForm();

    const SelectStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: state.isFocused ? 2.5 : 1,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 4,
        }),
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon" />
                }
            </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => <span style={{}} {...innerProps} />;

    return (
        <>
            <ReportParameterContainer>
                <ModalCancel
                    id="modalcancelalert"
                    appear={modalCancel}
                    text={"Deseja retornar para a página anterior?"}
                    actionButton1={() => setModalCancel(false)}
                    actionButton2={() => props.history.push("/relatorios")}
                />
                <ModalWarn
                    id="modalconfirmalert"
                    appear={props.modalError}
                    text={props.messageError}
                    textButton2='Ok'
                    actionButton2={() => props.setModalError(false)}
                />
                <Subtitle>
                    <img
                        src={FilterIcon}
                        alt="filterIcon"
                    />
                    <label> Período </label>
                    <Line />
                </Subtitle>
                <form onSubmit={handleSubmit(() => props.onSubmitSearch())}>
                    <SearchContainer>
                        <InputContainer>
                            <div className="br-input">
                                <label htmlFor="dt_inicio">Data de início</label>
                                <input
                                    id="dateandtime-input"
                                    name="dt_inicio"
                                    type="date"
                                    data-input="data-input"
                                    placeholder="dd/mm/aaaa"
                                    defaultValue={props.dataInterval.dt_inicio}
                                    onChange={e => { props.setDataInterval({ ...props.dataInterval, dt_inicio: e.target.value }); props.setResult() }}

                                />
                            </div>
                        </InputContainer>
                        <InputContainer>
                            <div className="br-input">
                                <label htmlFor="dt_inicio">Data final</label>
                                <input
                                    id="dateandtime-input"
                                    name="dt_fim"
                                    type="date"
                                    data-input="data-input"
                                    placeholder="dd/mm/aaaa"
                                    defaultValue={props.dataInterval.dt_fim}
                                    onChange={e => { props.setDataInterval({ ...props.dataInterval, dt_fim: e.target.value }); props.setResult() }}
                                />
                            </div>
                        </InputContainer>
                        {props.agrupadores &&
                            <SelectContainer>
                                <label>Agrupador</label>
                                <Select
                                    onChange={a => { props.setAgrupador(a.value); props.setResult() }}
                                    options={props.agrupadores}
                                    values={props.agrupadores}
                                    defaultValue={props.agrupadores[0]}
                                    styles={SelectStyles}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#CCCCCC',
                                            primary: '#1351B4'
                                        },
                                    })}
                                    components={{ DropdownIndicator, IndicatorSeparator }}
                                />
                            </SelectContainer>
                        }
                        <SearchButtonsContainer>
                            <button className="br-button secundary" type="button"
                                onClick={() => setModalCancel(true)}>
                                Voltar
                            </button>
                            <button className="br-button terciary" type="button" disabled={!props.result?.length}
                                onClick={() => window.print()}>
                                Imprimir
                            </button>
                            <button className={props.loadingReport ? "br-button primary loading" : "br-button primary"} type="submit">
                                Gerar relatório
                            </button>
                        </SearchButtonsContainer>
                    </SearchContainer>

                    <Subtitle>
                        <Line />
                    </Subtitle>
                </form>
            </ReportParameterContainer>
        </>
    );
}