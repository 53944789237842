import React, { useEffect, useState } from 'react';

import { GlobalContainer,
    ContentContainer,
    ContentContainer1,
    ContentContainer11,
    ContentContainer12,
    ContentContainer2,
    Title,
    Text,
    TitleContainer,
    ContentContainerButtons } from './styles';
import ModalConfirm from '../../Components/Modal/Confirm/index';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function DeleteUnit(props) {
    const [ unit, setUnit ] = useState({
        organCode: '',
        unitCode: '',
        unitName: '',
        state: '',
        street: '',
        number: ''
    });
    const [ loadUnit, setLoadUnit ] = useState(false);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        if (!loadUnit && props.location.state !== undefined) {
            setUnit({
                organCode: props.location.state.result_search.CODIGO_ORGAO,
                unitCode: props.location.state.result_search.CODIGO_UNIDADE,
                unitName: props.location.state.result_search.NOME_UNIDADE,
                state: props.location.state.result_search.ESTADO,
                street: props.location.state.result_search.LOGRADOURO,
                number: props.location.state.result_search.NUMERO
            });

            setLoadUnit(true);
        }
    }, [props, loadUnit]);

    const [ isModalConfirmVisible, setModalConfirmVisible ] = useState(false);
    const [ loadingDelete, setLoadingDelete ] = useState(false);
    const [ deleted, setDeleted ] = useState(false);
    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

    const onSubmitDelete = async () => {
        setModalConfirmVisible(false)
        setLoadingDelete(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",
                codigo_orgao: unit.organCode,
                codigo_unidade: unit.unitCode,
                nome_unidade: unit.unitName
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/removeUnidadeOrgao`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {   
                    const unit_deleted = {
                        message: data_request.message
                    }                 
                    setDeleted(true);
                    setLoadingDelete(false);
                    setModalConfirmVisible(false)
                    props.history.push('gerenciarUnidades', unit_deleted);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/removeUnidadeOrgao BackendBalcao');
                    }

                    setLoadingDelete(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingDelete(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingDelete(false);
                console.log('Error request to /gestao/removeUnidadeOrgao');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            
            {!loadUnit ?
                <div style={{'marginTop': '75px'}}><h1> Error on the page! </h1></div>
            :
                <>
                    <ModalConfirm 
                        id="modalconfirmalert"
                        appear={isModalConfirmVisible}
                        title={"Confirmar remoção"}
                        text="Remover esta unidade?"
                        textButton1={"Não"}
                        textButton2={"Sim"}
                        actionButton1={() => setModalConfirmVisible(false)}
                        load={loadingDelete}
                        actionButton2={onSubmitDelete}
                    />

                    <ModalWarn
                        id="modalconfirmalert"
                        appear={modalError}
                        text = {messageError}
                        textButton2='Ok'
                        actionButton2={() => setModalError(false)}
                    />

                    <GlobalContainer>
                        <TitleContainer>
                            <Title> Remover unidade </Title>
                        </TitleContainer>

                        <ContentContainer>
                            <ContentContainer1>
                                <ContentContainer11>
                                    <label> Unidade </label>
                                    <Text> 
                                        { unit.unitName }
                                    </Text>
                                </ContentContainer11>

                                <ContentContainer12>
                                    <label> Estado </label>
                                    <Text> 
                                        {unit.state}
                                    </Text>
                                </ContentContainer12>

                                <ContentContainer12>
                                    <label> Logradouro (Rua/Avenida) </label>
                                    <Text> 
                                        {unit.street}
                                    </Text>
                                </ContentContainer12>

                                <ContentContainer12>
                                    <label> Número </label>
                                    <Text> 
                                        {unit.number}
                                    </Text>
                                </ContentContainer12>
                            </ContentContainer1>

                            <ContentContainer2>
                            </ContentContainer2>
                        </ContentContainer>

                        <ContentContainer>
                            <ContentContainer1>
                            </ContentContainer1>

                            <ContentContainer2>
                                <ContentContainerButtons>                                   
                                    <button className="br-button" type="button"
                                            onClick={() => props.history.push('/gerenciarUnidades', props.location.state)}>
                                        Voltar
                                    </button>
                                        {!deleted ?
                                            <button className="br-button primary" type="button"
                                                    onClick={() => setModalConfirmVisible(true)}>
                                                Confirmar
                                            </button>
                                        :
                                            <button className="br-button primary" type="button" disabled="disabled">
                                                Confirmar
                                            </button>
                                        }
                                </ContentContainerButtons>
                            </ContentContainer2>
                        </ContentContainer>
                    </GlobalContainer>
                </>
            }
        </>
    );
}