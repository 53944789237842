import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { GlobalContainer, ContentContainer, TitleContainer, Title, Subtitle, Line, SearchContainer, InputContainer, SearchButtonsContainer } from './styles'
import ModalCancel from '../Modal/Cancel/index'
import FilterIcon from '../../assets/filter_icon.png'
import AlertIcon from '../../assets/alert_icon2.png'
import { cpfMask } from '../../utils'
import ModalWarn from '../Modal/Warn/index'
import './estilo.css' 

export default function SearchCustomerService(props) {

  const [defaultFormValues, setDefaultFormValues] = useState({
    protocolo: '',
  })

  const { handleSubmit } = useForm({ defaultFormValues })

  const [modalCancel, setModalCancel] = useState(false)

  const opcoes = {
    year: 'numeric',   // Ano com quatro dígitos
    month: 'long',     // Nome completo do mês
    day: 'numeric',    // Dia do mês
    hour: '2-digit',   // Horas
    minute: '2-digit', // Minutos
    second: '2-digit', // Segundos
    timeZone: 'UTC',   // Timezone
    hour12: false,     // 12 horas
  }

  return (
    <>
      <ModalCancel
        id='modalcancelalert'
        appear={modalCancel}
        text={'Deseja cancelar a pesquisa e retornar para página principal?'}
        actionButton1={() => setModalCancel(false)}
        actionButton2={() => props.history.push('/pesquisarcidadao')}
      />
      <ModalWarn
        id='modalconfirmalert'
        appear={props.modalError}
        text={'Houve algum problema no servidor. Aguarde um momento e tente novamente.'}
        textButton2='Ok'
        actionButton2={() => props.setModalError(false)}
      />

      <GlobalContainer>
        <ContentContainer>
          <TitleContainer>
            <Title>{props.pageTitle}</Title>
          </TitleContainer>

          <Subtitle>
            <img src={FilterIcon} alt='filterIcon' style={{ marginBottom: '7px', marginRight: '7px' }} />
            <label> Filtro </label>
            <Line />
          </Subtitle>

          <form onSubmit={handleSubmit(() => props.onSubmitSearch(defaultFormValues))}>
            <SearchContainer>
              <InputContainer>
                <div className='br-input'>
                  <label htmlFor='protocolo'> Protocolo do atendimento </label>
                  <input
                    id='protocolo'
                    name='protocolo'
                    placeholder='Digite somente números'
                    type='text'
                    maxLength='15'
                    value={defaultFormValues.protocolo}
                    onChange={(event) => {
                      const { value } = event.target
                      setDefaultFormValues({ ...defaultFormValues, protocolo: value.replace(/\D/g, '') })
                      props.setErroMsg(undefined)
                    }}
                  />
                </div>
              </InputContainer>
            </SearchContainer>

            <SearchButtonsContainer>
              <button className='br-button secundary' type='button' onClick={() => setModalCancel(true)}>
                Cancelar
              </button>
              <button
                className='br-button secondary'
                type='button'
                onClick={() => {
                  setDefaultFormValues({ ...defaultFormValues, protocolo: '' })
                  props.setSearchResult(undefined)
                  document.getElementById('protocolo').value = ''
                  props.setErroMsg(undefined)
                }}>
                Limpar Filtro
              </button>
              {!props.buttonNextLoading ? (
                <button className='br-button primary' type='submit' disabled={!defaultFormValues.protocolo}>
                  Pesquisar
                </button>
              ) : (
                <button className='br-button primary loading' type='button'>
                  Pesquisar
                </button>
              )}
            </SearchButtonsContainer>

            <Subtitle>
              <Line />
            </Subtitle>
          </form>

          {!!props.searchResult?.length && (
            <>
              <Subtitle>
                <label>Dados do Atendimento</label>
              </Subtitle>

              <div id='atendimento'>
                <fieldset>
                  <legend>Atendimento</legend>
                  <ul>
                    <li><b>Data:</b> {new Date(props.searchResult[0].DATA_ATENDIMENTO).toLocaleString('pt-BR', opcoes)} (Horário de Brasília)</li>
                    <li><b>CPF Cidadão:</b> {cpfMask(props.searchResult[0].CPF_CIDADAO)}</li>
                    {props.searchResult[0].CPF_PROCURADOR != null && props.searchResult[0].CPF_PROCURADOR !== '00000000000' &&
                      <li><b>CPF Procurador:</b> {props.searchResult[0].CPF_PROCURADOR}</li>
                    }
                    <li><b>IP:</b> {props.searchResult[0].IP}</li>
                    <li><b>Porta:</b> {props.searchResult[0].PORTA}</li>
                    <li><b>Alterou e-mail:</b> {props.searchResult[0].ALTEROU_EMAIL === 'S' ? 'Sim' : 'Não'}</li>
                    <li><b>Alterou telefone:</b> {props.searchResult[0].ALTEROU_TELEFONE === 'S' ? 'Sim' : 'Não'}</li>
                  </ul>
                </fieldset>

                <fieldset>
                  <legend>Atendente</legend>
                  <ul>
                    <li><b>CPF:</b> {props.searchResult[0].CPF_ATENDENTE && cpfMask(props.searchResult[0].CPF_ATENDENTE)}</li>
                    <li><b>Nome:</b> {props.searchResult[0].NOME_ATENDENTE}</li>
                    <li><b>Unidade:</b> {props.searchResult[0].UNIDADE_ATENDENTE}</li>
                    <li><b>Data de cadastro:</b> {new Date(props.searchResult[0].DATA_INCLUSAO).toLocaleString('pt-BR', opcoes)} (Horário de Brasília)</li>

                    {props.searchResult[0].DATA_BLOQUEIO != null
                      ? <>
                        <li><b>Data do Bloqueio:</b> {new Date(props.searchResult[0].DATA_BLOQUEIO).toLocaleString('pt-BR', opcoes)} (Horário de Brasília)</li>
                        <li><b>Motivo do Bloqueio:</b> {props.searchResult[0].MOTIVO_BLOQUEIO}</li>
                      </>
                      : <li><b>Bloqueado: </b> Não</li>
                    }

                    {props.searchResult[0].DATA_DESBLOQUEIO != null
                      && <>
                        <li><b>Data do Desbloqueio:</b> {new Date(props.searchResult[0].DATA_DESBLOQUEIO).toLocaleString('pt-BR', opcoes)} (Horário de Brasília)</li>
                        <li><b>Motivo do Desbloqueio:</b> {props.searchResult[0].MOTIVO_DESBLOQUEIO}</li>
                      </>
                    }

                    {props.searchResult[0].DATA_CANCELAMENTO != null 
                      ? <li><b>Data do Cancelamento:</b> {new Date(props.searchResult[0].DATA_CANCELAMENTO).toLocaleString('pt-BR', opcoes)} (Horário de Brasília)</li>
                      : <li><b>Cancelado: </b> Não</li>
                    }
                  </ul>
                </fieldset>

                <fieldset>
                  <legend>Cadastrador</legend>
                  <ul>
                    <li><b>CPF:</b> {props.searchResult[0].CPF_CADASTRADOR && cpfMask(props.searchResult[0].CPF_CADASTRADOR)}</li>
                    <li><b>Nome:</b> {props.searchResult[0].NOME_CADASTRADOR}</li>
                    <li><b>Unidade:</b> {props.searchResult[0].UNIDADE_CADASTRADOR}</li>
                  </ul>
                </fieldset>

              </div>
            </>
          )}

          {props.searchResult?.length === 0 && defaultFormValues.protocolo !== '' && (
            <span className='feedback warning' role='alert' id='listresult'>
              <i className='fas fa-times-circle' aria-hidden='true'>
                <img src={AlertIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
              </i>
              <label id='listresultlabel'>Nenhum atendimento encontrado</label>
            </span>
          )}

          {props.erroMsg && (
            <span className='feedback warning' role='alert' id='listresult'>
              <i className='fas fa-times-circle' aria-hidden='true'>
                <img src={AlertIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
              </i>
              <label id='listresultlabel'>{props.erroMsg}</label>
            </span>
          )}
        </ContentContainer>
      </GlobalContainer>
    </>
  )
}
