import React from 'react';

//import '../../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';
import iconAlertImg from '../../../assets/alert_icon.png';
import iconCloseAlertImg from '../../../assets/close_alert_icon.png';
import { cpfMask } from '../../../utils';

function MessageAlert( props ) {
    return (
        <div id="messageAlert" className="br-message info" role="alert">
            <div className="icon" aria-label="mensagem de informação">
                <i className="fas fa-info-circle fa-lg" aria-hidden="true"></i>
                <img 
                    src={iconAlertImg}
                    alt="alertIcon"
                />
            </div>
            <div className="content">
                <span> 
                    O CPF { cpfMask(props.cpf) } <strong>{props.ident_dig ? 'possui' : 'não possui'}</strong> Conta gov.br.
                    {props.ident_dig ? '' : ' Para criá-la, informe os dados abaixo.'}
                </span>
            </div>
            <div className="close">
                <button className="br-button circle small" type="button" aria-label="Fechar"
                        onClick={ () => {document.getElementById("messageAlert").style.display = "none"} }
                >
                    <i className="fas fa-times" aria-hidden="true">
                        <img 
                            src={iconCloseAlertImg}
                            alt="CloseAlertIcon"
                        />
                    </i>
                </button>
            </div>
        </div>
    );
}

export default MessageAlert;