import React, { useState, useEffect } from 'react';

import EditOperator from '../../Components/EditOperator';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function EditAttendant(props) {
    const [ attendant, setAttendant ] = useState({
        cpf: '',
        name: '',
        dt_canc_exp: '' //"2021-07-08"
    });

    const [ organOptions, setOrganOptions ] = useState([
        /*
        { value: "1", label: "INSS" },
        { value: "2", label: "Min. do Trabalho" },
        */
    ]);
    const [ organ, setOrgan ] = useState({ value: "", label: "Selecione o órgão" });
    const [ unidade, setUnidade ] = useState({ value: "", label: "Selecione a unidade" });
    
    const [ loadAttendant, setLoadAttendant ] = useState(false);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        async function loadOrganOptions() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : ""
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaOrgaoGestorRegional`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        var organList = [];
                        var obj = {};

                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO
                            }

                            organList.push(obj);
                        });

                        setOrganOptions(organList);
                    });
                } else {
                    console.log('Error request to /gestao/consultaorgaogestor');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        if (!loadAttendant && props.location.state !== undefined) {
            setAttendant({
                cpf: props.location.state.result_search.CPF_USUARIO,
                name: props.location.state.result_search.NOME_USUARIO,
                dt_canc_exp: !!props.location.state.result_search.DATA_CANCELAMENTO ? props.location.state.result_search.DATA_CANCELAMENTO.split('T')[0] : ''
            });
            setOrgan({
                value: props.location.state.result_search.CODIGO_ORGAO,
                label: props.location.state.result_search.NOME_ORGAO,
            });
            setUnidade({
                value: props.location.state.result_search.CODIGO_UNIDADE,
                label: props.location.state.result_search.NOME_UNIDADE,
            });

            loadOrganOptions();
            setLoadAttendant(true);
        }
    }, [props.location.state, loadAttendant]);

    const [ loadingEdit, setLoadingEdit ] = useState(false);

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

    const onSubmitEdit = async () => {
        
        setLoadingEdit(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",
                id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                cpf_usuario_atendente: attendant.cpf,
                nome_usuario: attendant.name,
                codigo_orgao: organ.value,
                data_cancelamento: attendant.dt_canc_exp
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/editaatendente`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    const attendant_added = {
                        from: "editAttendant",
                        id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                        cpf: data_request.objAtendente.cpf_usuario_atendente,
                        name: data_request.objAtendente.nome_usuario,
                        organ: organ,
                        unidade: unidade,
                        dt_inc: data_request.objAtendente.data_inclusao,
                        dt_canc_exp: data_request.objAtendente.data_cancelamento
                    }
                    setLoadingEdit(false);
                    
                    //console.log(attendant_aEditd);
                    props.history.push('mostraratendente', attendant_added);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/editaatendente BackendBalcao');
                    }

                    setLoadingEdit(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingEdit(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingEdit(false);
                console.log('Error request to /gestao/editaatendente');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <EditOperator
                pageTitle='Editar atendente'
                operator={attendant}
                setOperator={setAttendant}
                organOptions={organOptions}
                setOrganOptions={setOrganOptions}
                organ={organ}
                setOrgan={setOrgan}
                unidade={unidade}
                loadOperator={loadAttendant}
                loadingEdit={loadingEdit}
                setLoadingEdit={setLoadingEdit}
                modalError={modalError}
                setModalError={setModalError}
                messageError={messageError}
                setMessageError={setMessageError}
                onSubmitEdit={onSubmitEdit}
                history={props.history}
                location={props.location}
                buttonCancelRoute='/gerenciaratendentes'
            />
        </>
    );
}