import React, { useEffect, useState } from 'react';

import ReplaceOperator from '../../Components/ReplaceOperator';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function ReplaceAndCancelRegionalManager(props) {
    const [ regionalManager, setRegionalManager ] = useState({
        id_usuario_orgao: '',
        cpf: '',
        name: '',
        organ: '',
        dt_inc: '',
        dt_canc_exp: ''
    });
    const [ regionalManagerSubstitute, setRegionalManagerSubstitute ] = useState({
        id_usuario_orgao: '',
        cpf: '',
        name: '',
        organ: '',
        dt_inc: '',
        dt_canc_exp: ''
    });
    const [ loadRegionalManager, setLoadRegionalManager ] = useState(false);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        if (!loadRegionalManager && props.location.state !== undefined) {
            setRegionalManager({
                id_usuario_orgao: props.location.state.regional_manager.id_usuario_orgao,
                cpf: props.location.state.regional_manager.cpf,
                name: props.location.state.regional_manager.name,
                organ: props.location.state.regional_manager.organ,
                unidade: props.location.state.regional_manager.unidade,
                dt_inc: props.location.state.regional_manager.dt_inc,
                dt_canc_exp: props.location.state.regional_manager.dt_canc_exp
            });
            setRegionalManagerSubstitute({
                id_usuario_orgao: props.location.state.regional_manager_substitute.id_usuario_orgao,
                cpf: props.location.state.regional_manager_substitute.cpf,
                name: props.location.state.regional_manager_substitute.name,
                organ: props.location.state.regional_manager_substitute.organ,
                unidade: props.location.state.regional_manager_substitute.unidade,
                dt_inc: props.location.state.regional_manager_substitute.dt_inc,
                dt_canc_exp: props.location.state.regional_manager_substitute.dt_canc_exp
            })

            setLoadRegionalManager(true);
        }
    }, [props, loadRegionalManager]);

    const [ successMsg, setSuccessMsg ] = useState("");
    const [ replaced, setReplaced ] = useState(false);
    const [ loadingCancel, setLoadingCancel ] = useState(false);
    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

    const onSubmitReplace = async () => {

        setLoadingCancel(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",
                cpf_gestor_regional: regionalManager.cpf,
                codigo_orgao: regionalManager.organ.value,
                codigo_unidade: regionalManager.unidade.value,
                id_usuario_orgao: regionalManager.id_usuario_orgao,
                cpf_gestor_regional_substituto: regionalManagerSubstitute.cpf
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/substituiCancelaGestorRegional`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function() {                    
                    setReplaced(true);
                    setLoadingCancel(false);
                    setSuccessMsg("Gestor regional substituído com sucesso.")
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/cancelaGestorRegional BackendBalcao');
                    }

                    setLoadingCancel(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingCancel(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingCancel(false);
                console.log('Error request to /gestao/cancelaGestorRegional');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <ReplaceOperator
                pageTitle='Substituir gestor regional'
                titleReplaced='Gestor regional a ser substituído'
                replacedOperator={regionalManager}
                titleReplacer='Gestor Regional substituto'
                replacerOperator={regionalManagerSubstitute}
                loadOperator={loadRegionalManager}
                onSubmitReplace={onSubmitReplace}
                history={props.history}
                buttonBackRoute='/consultaGestorRegionalSubstituto'
                buttonStartRoute='/pesquisarcidadao'
                successMsg={successMsg}
                replaced={replaced}
                loadingCancel={loadingCancel}
                modalError={modalError}
                setModalError={setModalError}
                messageError={messageError}
            />
        </>
    );
}