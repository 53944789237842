import React, { useState, useEffect } from 'react';
import AdminAgencyScheduleManagement from './AdminAgencyScheduleManagement';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function AdminAgencySchedule(props) {

    const [ loading, setLoading] = useState(false);

    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [ pageOptions, setPageOptions ] = useState([]);
    const [ page, setPage ] = useState({
        label: "1", value: "1"
    });

    const [ modalError, setModalError ] = useState(false);

    const [ agencyScheduleManagementList, setAgencyScheduleManagementList ] = useState([ ]);

    const [ totalFilter, setTotalFilter ] = useState("0");

    const [ erroMsg, setErroMsg ] = useState('');

    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        async function loadAgencies() {
            setAgencyScheduleManagementList([]);
            setErroMsg('');
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_gestor: JSON.parse(localStorage.getItem('user')).cpf_atendente,
                    pagina_atual: "1",
                    qtd_registros: 10
                }),
            };
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/listaHorarioOrgao`, requestOptions).then(function(response) {
                if (response.ok) {
                    response.json().then(function(resp) {
    
                        const maxPageOption = Math.ceil(Number(resp.total) / Number(rangePages.value))
                        let list = []
                        let obj = {}
                        for(var i = 1; i <= maxPageOption; i++) {
                            obj = {
                                value: String(i),
                                label: String(i)
                            }
    
                            list.push(obj);
                        }
                        setPageOptions(list);
                        setPage({label: "1", value: "1"});
                        setAgencyScheduleManagementList(resp.horariosOrgao);
                        setTotalFilter(resp.total);
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            setErroMsg(data.message);
                        } else {
                            setErroMsg('Houve algum problema no servidor.');
                        }
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setLoading(false)
                      setModalInvalidSessionError(true)
                    })
                } else {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    setModalError(true);
                    console.log('Request error in gestao/listaHorariosOrgao BackendBalcao');
                }
                setLoading(false);
            }).catch(function(err) {
                setLoading(false);
                console.error('Failed retrieving information', err);
            });
        };

        loadAgencies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.state]);

    const onChangePaginationConfig = async (data, ranPag, pag, isRanPag) => {
        setErroMsg('');
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')).cpf_atendente,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/listaHorarioOrgao`, requestOptions
        ).then(function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }

                    setPageOptions(list);
                    if(isRanPag){
                        setPage({label: "1", value: "1"});
                    }
                    setAgencyScheduleManagementList(data.horariosOrgao);
                    setTotalFilter(data.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoading(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                setModalError(true);
                console.log('Request error in gestao/listaHorariosOrgao BackendBalcao');
            }
            setLoading(false);
        }).catch(function(err) {
            setLoading(false);
            console.error('Failed retrieving information', err);
        });
    };

    return(
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <AdminAgencyScheduleManagement
                pageTitle='Gestão de horários de órgãos'
                buttonDetailRoute='/detalharHorarioOrgao'
                buttonEditRoute='/editarHorarioOrgao'
                buttonNextLoading={loading}
                onChangePaginationConfig={onChangePaginationConfig}
                searchResult={agencyScheduleManagementList}
                setSearchResult={setAgencyScheduleManagementList}
                history={props.history}
                totalFilter={totalFilter}
                pageOptions={pageOptions}
                setPage={setPage}
                page={page}
                erroMsg={erroMsg}
                setErroMsg={setErroMsg}
                rangePages={rangePages}
                setRangePages={setRangePages}
                modalError={modalError}
                setModalError={setModalError}
            />
        </>
    );
}