import React from 'react';

import { GlobalContainer,
         Container, 
         ContainerButton,
         Title, 
         Text } from './styles';

export default function LoginError(props) {
    return (
        <GlobalContainer>
            <Container>
                <Title> Acesso Negado! </Title>
                <Text> { props.location.state.message } </Text>
            </Container>
            <ContainerButton>
                <button className="br-button primary" type="submit"
                       onClick={() => {
                        fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                            method: 'GET',
                            headers: {
                              authorization: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
                              Accept: 'application/json',
                            },
                          });
                        const { REACT_APP_LOGIN_UNICO_URL,REACT_APP_REDIRECT_URI_LOGOUT} = process.env;//, REACT_APP_RESPONSE_TYPE, REACT_APP_CLIENT_ID, REACT_APP_SCOPE, REACT_APP_REDIRECT_URI, REACT_APP_NONCE, REACT_APP_STATE } 
                       // const urlLogin = `${REACT_APP_LOGIN_UNICO_URL}/authorize?response_type=${REACT_APP_RESPONSE_TYPE}&client_id=${REACT_APP_CLIENT_ID}&scope=${REACT_APP_SCOPE}&redirect_uri=${REACT_APP_REDIRECT_URI}&nonce=${REACT_APP_NONCE}&state=${REACT_APP_STATE}`;
                        localStorage.clear();
                        const possibleURLs = ["https://sso.staging.acesso.gov.br","https://sso.acesso.gov.br"];
                        if (possibleURLs.includes(REACT_APP_LOGIN_UNICO_URL)) {
                            window.location.href = `${REACT_APP_LOGIN_UNICO_URL}/logout?post_logout_redirect_uri=${REACT_APP_REDIRECT_URI_LOGOUT}`}
                            else { alert("Error: URL não está contida na lista de possívels URLs do sistema. Contacte o suporte")}                                                      
                        }}>
                    Sair
                </button>
            </ContainerButton>
        </GlobalContainer>
    );
}