import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import ModalCancel from '../../Components/Modal/Cancel'
import { cpfMask } from '../../utils'
import { Subtitle } from './styles'
import AlertIcon from '../../assets/alert_icon2.png'
import './styles.css'

export default function Profile(props) {
  const [modalCancel, setModalCancel] = useState(false)
  const { CPF, NOME, PERFIL } = props.searchResult?.func || {}
  
  const [defaultFormValues, setDefaultFormValues] = useState({
    profile: '',
    cpf: CPF,
  })
  const { handleSubmit } = useForm({ defaultFormValues })
  const perfilDominio = {
    'S':	'Admin',
    'O':	'Gestor de Órgão',
    'R':	'Gestor de Regional',
    'A':	'Atendente',
  }

  return (
    <>
      <ModalCancel
        id='modalcancelalert'
        appear={modalCancel}
        text={'Deseja cancelar a pesquisa e retornar para página principal?'}
        actionButton1={() => setModalCancel(false)}
        actionButton2={() => props.history.push('/pesquisarcidadao')}
      />

      <form onSubmit={handleSubmit(() => props.onSubmitProfileChange(defaultFormValues))}>
        {!!props.searchResult?.func && (
          <>
            <Subtitle>
              <label>Dados do funcionário</label>
            </Subtitle>

            <fieldset>
              <ul>
                <li>
                  <b>CPF:</b> {CPF && cpfMask(CPF)}
                </li>
                <li>
                  <b>Nome:</b> {NOME}
                </li>
                <li>
                  <b>Perfil atual:</b> {perfilDominio[PERFIL]}
                </li>
                <li>
                  <b>Novo perfil:</b>
                  <select onChange={(e) => setDefaultFormValues({ ...defaultFormValues, cpf: CPF, profile: e.target.value })}>
                    <option value=''>Selecione o perfil</option>
                    {PERFIL !== 'S' && <option value='S'>Admin</option>}
                    {PERFIL !== 'O' && <option value='O'>Gestor de Órgão</option>}
                    {PERFIL !== 'R' && <option value='R'>Gestor Regional</option>}
                    {PERFIL !== 'A' && <option value='A'>Atendente</option>}
                  </select>
                </li>

                {!props.buttonNextLoading ? (
                  <button className='br-button primary' type='submit' disabled={!defaultFormValues.profile}>
                    Salvar novo perfil
                  </button>
                ) : (
                  <button className='br-button primary loading' type='button'>
                    Salvar novo perfil
                  </button>
                )}
              </ul>
            </fieldset>
          </>
        )}

        {props.searchResult?.func === null && (
          <span className='feedback warning' role='alert' id='listresult'>
            <i className='fas fa-times-circle' aria-hidden='true'>
              <img src={AlertIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
            </i>
            <label id='listresultlabel'>Nenhum funcionário encontrado</label>
          </span>
        )}
      </form>
    </>
  )
}
