import styled from 'styled-components';

export const Title = styled.h1 `
    width: 960px;

    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
    }
`;

export const Text = styled.label `
    font-size: 17px;
    color: #1351b4;
`;

export const GlobalContainer = styled.div `

    margin-top: 80px;

    @media (max-width: 1000px) {
        div {
            display: grid;
            margin: 0px auto;
        }
    }

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const TitleContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
    justify-content: center;
`;

export const ContentContainer = styled.div `
    display: flex;
    justify-content: center;
    max-width: 960;
    margin: 0px auto;
`;

export const ContentContainer1 = styled.div `
    width: 380px;
    text-align: center;
    margin-right: 200px;
    padding: 0px 0px 15px;

    //border: 2px solid;
    //border-color: yellow;
`;

export const ContentContainer2 = styled.div `
    width: 380px;

    //border: 2px solid;
    //border-color: yellow;
`;

export const ContentContainer21 = styled.div `
    padding: 0px 0px 15px;
`;

export const ContentContainer22 = styled.div `
    display: flex;
    justify-content: center;
    padding: 0px 0px 30px;

    //border: 2px solid;
    //border-color: red;
`;

export const ContentContainer221 = styled.div `
    text-align: center;
    width: 180px;
    margin-right: 30px;

    //border: 2px solid;
    //border-color: black;
`;

export const ContentContainer222 = styled.div `
    text-align: center;
    width: 180px;

    //border: 2px solid;
    //border-color: black;
`;

export const SelectButton = styled.label `
    display: flex;
    width: 170px;
    height: 170px;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    background: ${props => props.select ? 'rgba(0, 0, 0, 0.03)': '#FFFFFF'};
    box-sizing: border-box;
    box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    border: ${props => props.select ? '1px solid #2670E8': ''};

    cursor: pointer;
    label {
        cursor: pointer;
    }
`;

export const ContentContainer23 = styled.div `
    
`;

export const ContentContainer24 = styled.div `
    padding: 20px 0px 20px;
    text-align: right;
`;