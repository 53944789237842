import React from 'react';

// import cidadaniaImg from "../../assets/ministerio_da_cidadania.png";
import acessoInfoImg from "../../assets/acesso_info.png";

import { FooterDown, FooterDiv } from './styles';
//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

function Footer(props) {
    return (
        <FooterDown scroll={props.scroll} >
            {/*
            <footer className="br-footer [inverted]">
                <div className="container-lg">
                    <div className="d-none d-sm-block">
                        <div className="row align-items-end justify-content-between py-5">
                            <div className="col assigns text-right">
                                <a href="https://www.gov.br/acessoainformacao/pt-br" rel="noreferrer" target="_blank">
                                    <img
                                        className="ml-4"
                                        src={acessoInfoImg}
                                        alt="acessoinfoimg"
                                    />
                                </a>
                                <a href="https://www.gov.br/pt-br" rel="noreferrer" target="_blank">
                                    <img
                                        className="ml-4"
                                        src={cidadaniaImg}
                                        alt="cidadanialogo"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            */}
            <FooterDiv>
                <div id='divInt'>
                    <div id='img1'>
                        <a href="https://www.gov.br/acessoainformacao/pt-br" rel="noreferrer" target="_blank">
                            <img
                                className="ml-4"
                                src={acessoInfoImg}
                                alt="acessoinfoimg"
                            />
                        </a>
                    </div>
                    {/* <div id='img2'>
                        <a href="https://www.gov.br/pt-br" rel="noreferrer" target="_blank">
                            <img
                                className="ml-4"
                                src={cidadaniaImg}
                                alt="cidadanialogo"
                            />
                        </a>
                    </div> */}
                </div>
            </FooterDiv>
        </FooterDown>
    );
}

export default Footer;