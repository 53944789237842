import styled from 'styled-components';

export const ContainerLoad = styled.div `
    
    position: absolute;
    z-index: 9999;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    #load {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -44px;
        margin-left: -22px;
    }

`;