import React, { useEffect } from 'react';

import {
    Title,
    Text,
    GlobalContainer,
    ContentContainer,
    ContentContainer1,
    ContentContainer2,
    ContentContainer21,
    ContentContainer22,
    ContentContainer23,
} from './styles';

import InitialImg from '../../assets/marca_balcao_govbr.png';

export default function Initial(props) { 

    useEffect(() => {
        props.handleShowInfo(false);
    }, [props]);

    return(
        <GlobalContainer>
            <ContentContainer>
                <ContentContainer1>
                    <img
                        src={InitialImg}
                        alt='Descrition' 
                    />
                </ContentContainer1>

                <ContentContainer2>
                    <ContentContainer21>
                        <Title>
                            Balcão de Atendimento GovBr
                        </Title>
                    </ContentContainer21>

                    <ContentContainer22>
                        <Text>
                            Você poderá criar ou recuperar uma <strong>Conta gov.br</strong> para um cidadão e garantir a ele o acesso aos serviços públicos digitais.
                        </Text>
                    </ContentContainer22>

                    <ContentContainer23>
                        <div className="br-magic-button">
                            <button className="br-button" type="button"
                                    onClick={() => props.history.push("/login")}
                            >
                                Entrar com o&nbsp;<strong>gov.br</strong>
                            </button>
                        </div>
                    </ContentContainer23>
                </ContentContainer2>
            </ContentContainer>
        </GlobalContainer>
    );
};