import React, { useState, useEffect } from 'react';

import ModalWarn from '../../Components/Modal/Warn/index'
import ShowUnitElement from '../../Components/ShowUnitElement'
import { estados } from '../../constants'

export default function UnitDetails(props) {
    const [ unit, setUnit ] = useState({
        organCode: '',
        unitCode: '',
        unitName: '',
        state: '',
        street: '',
        number: '',
        city: '',
        neighborhood: '',
        addressComplement: ''
    });
    const [ userList, setUserList ] = useState([]);

    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");
    const [ erroMsg, setErroMsg ] = useState([]);
    const [ pageOptions, setPageOptions ] = useState([]);
    const [ page, setPage ] = useState({
        label: "1", value: "1"
    });
    const [ totalFilter, setTotalFilter ] = useState("0");
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    
    useEffect(() => {   
        if (props.location.state !== undefined) {
            setUnit({
                organCode: props.location.state.result_search.CODIGO_ORGAO,
                unitCode: props.location.state.result_search.CODIGO_UNIDADE,
                unitName: props.location.state.result_search.NOME_UNIDADE,
                state: estados.filter(option => option.value === props.location.state.result_search.ESTADO)[0],
                street: props.location.state.result_search.LOGRADOURO,
                number: props.location.state.result_search.NUMERO,
                city: props.location.state.result_search.CIDADE,
                neighborhood: props.location.state.result_search.BAIRRO,
                addressComplement: props.location.state.result_search.COMPLEMENTO,
                zipcode: props.location.state.result_search.CEP

            });

           onChangePaginationConfig(rangePages, null, true)

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.state]);

    const onChangePaginationConfig = async (ranPag, pag, isRanPag) => {
        setErroMsg(undefined);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_unidade: props.location.state.result_search.CODIGO_UNIDADE,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaUsuariosAssociadosAUnidade`,
                    requestOptions
            ).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                        let list = []
                        let obj = {}
                        for(var i = 1; i <= maxPageOption; i++) {
                            obj = {
                                value: String(i),
                                label: String(i)
                            }

                            list.push(obj);
                        }

                        setPageOptions(list);
                        if(isRanPag){
                            setPage({label: "1", value: "1"});
                        }
                        setUserList(data.usuarios);
                        setTotalFilter(data.total);
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            setErroMsg(data.message)
                            setMessageError(data.message)
                        } else {
                            setErroMsg('Houve algum problema no servidor.');
                        }
                        //setLoading(false);
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                    setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                    setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                    setModalInvalidSessionError(true)
                    })
                } else {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    //setLoading(false);
                    console.log('Request error in gestao/listaUsuariosAssociadosAUnidade BackendBalcao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
    };

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <ShowUnitElement
                pageTitle='Detalhamento da unidade'
                unit={unit}
                setUnit={setUnit}
                tableResultListTitle='Lista de gestores e atendentes vinculados a esta unidade'
                onChangePaginationConfig={onChangePaginationConfig}
                totalFilter={totalFilter}
                searchResult={userList}
                pageOptions={pageOptions}
                setPage={setPage}
                page={page}
                erroMsg={erroMsg}
                rangePages={rangePages}
                setRangePages={setRangePages}
                modalError ={modalError}
                setModalError={setModalError}
                messageError={messageError}
                buttonCancelRoute='/gerenciarUnidades'
                history={props.history}
            />
        </>
    );
}